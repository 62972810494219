import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Pagination} from '../models/pagination';

@Injectable({
	providedIn: 'root',
})
export class EventService extends HttpService {

	public getAllEvents() {
		return this.get('/event');
	}

	public getEventDetails(eventId: string) {
		return this.get('/event/details', {eventId});
	}

	public getCountryEvents( countryId: string = undefined) {
		const url = `/event/country`;
		return this.get(url, {countryId});
	}

	public joinEvent(eventId: string, communityId: string) {
		return this.post('/event/join', {eventId, communityId});
	}

	public getTypeEvents( categoryType: string) {
		const url = `/event/type`;
		return this.get(url, {categoryType});
	}

	public appendNewAgreement(eventId: string, submitDetails: any, sendCopy: boolean = false, communityName = '') {
		return this.post('/event/agreement', {eventId, submitDetails, sendCopy, communityName});
	}

	public futureForHome() {
		return this.getEvents('/event/future', {
			skip: 0,
			limit: 20,
			isPopular: true,
		});
	}

	public future(
		pagination: Pagination,
		countryId: string,
		communityId: string
	) {
		return this.getEventParamsAndSend(
			'/event/future',
			pagination,
			countryId,
			communityId
		);
	}

	public past(
		pagination: Pagination,
		countryId: string,
		communityId: string
	) {
		return this.getEventParamsAndSend(
			'/event/past',
			pagination,
			countryId,
			communityId
		);
	}

	private getEventParamsAndSend(
		url: string,
		pagination: Pagination,
		countryId: string,
		communityId: string
	) {
		const params = pagination.getPaginationAsParams();
		if (countryId) {
			params.countryId = countryId;
		}

		if (communityId) {
			params.communityId = communityId;
		}
		return this.getEvents(url, params);
	}

	private getEvents(url: string, params: {}) {
		return this.get(url, params);
	}

	public getMemberJoinEventStatus(eventId: string, memberId: string) {
		return this.get('/event/memberstatus', {eventId, memberId});
	}
}
