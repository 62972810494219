import {Component, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {PressService} from 'src/app/services/press.service';
import {PressModel} from 'src/app/models/press';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
	selector: 'app-home-press',
	templateUrl: './press-section.component.html',
	styleUrls: ['./press-section.component.scss']
})
export class PressSectionComponent implements OnInit {

	pressItems: PressModel[];

	constructor(
		public globals: GlobalsService,
		private pressService: PressService,
		public analyticsService: AnalyticsService
	) {
	}

	ngOnInit() {
		this.getPopularPressItems();
	}

	getPopularPressItems() {
		this.pressService.getPopularPressItems().subscribe((response) => {
			this.pressItems = response.items;
			this.pressItems.splice(10, this.pressItems.length - 1);
			// console.log(`%c data: ${JSON.stringify(response)}`, 'background-color: deepskyblue; color: blue;');
		}, (error) => {
			console.error(error);
		});
	}

	navigateToExternalUrl(url: string) {
		window.open(url, '_blank');
	}
}
