import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {GlobalsService} from '../../../services/globals';
import * as moment from 'moment';

@Component({
	selector: 'app-comment-item',
	templateUrl: './comment-item.component.html',
	styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit, OnChanges {

	@Input() comment: any;
	time: string;
	date: string;

	constructor(public globals: GlobalsService) {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		this.date = moment(this.comment.createdAt).format('MMMM Do YYYY, h:mm a');
		this.comment = this.comment;
	}
}
