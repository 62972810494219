'use strict';

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../models/user';
import {Router} from '@angular/router';
import {UserService} from './user.service';
import {AuthService} from './auth.service';
import {ProjectTypes} from '../models/enums';

export const version = '1.0.0';

@Injectable({
	providedIn: 'root'
})
export class DataService {
	public resultPageType: ProjectTypes;
	public resultSearchText: string;
	public isUserLoggedIn: boolean = false;
	public routeAfterLogin: string = '';
	private user: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
	private communities: BehaviorSubject<any> = new BehaviorSubject<any>({});
	private projects: BehaviorSubject<any> = new BehaviorSubject<any>({});
	public globalUser = this.user.asObservable();

	currentCatagoriesSearch: any;

	constructor(private router: Router, private authService: AuthService, private userService: UserService) {
		this.getUser();
	}

	get userValue() {
		return this.user.value;
	}

	public saveUser(user: User, token: string) {
		this.authService.setToken(token);
		this.isUserLoggedIn = true;
		this.updateUser(user);
	}

	public updateUser(user: User) {
		this.user.next(user);
	}

	public updateCommunities(communities: any) {
		this.communities.next(communities);
	}

	public updateProjects(projects: any) {
		this.projects.next(projects);
	}

	public userLogout(): void {
		this.user.next(new User());
		this.isUserLoggedIn = false;
		this.authService.logout();
		this.router.navigateByUrl('/login');
	}

	private getUser(): void {
		const userToken = this.authService.getToken();
		if (userToken && userToken !== 'undefined') {
			this.userService.getUserData().subscribe(resp => {
				this.isUserLoggedIn = true;
				this.updateUser(resp.data);
				this.updateCommunities(resp.communities);
				this.updateProjects(resp.projects);
			}, (error) => {
				// delete token from local storage.
				this.authService.logout();
				this.isUserLoggedIn = false;
				// redirect to login page.
				console.error(error);
			});
		}
	}
}
