import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Project} from 'src/app/models/project';
import {ProjectTypes} from 'src/app/models/enums';

@Component({
	selector: 'app-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
	@Input() project: Project;
	projectName: string;
	@Input() showType: boolean;
	@Output() selectProjectAction = new EventEmitter();
	// @ViewChild('projectImg', { static: true }) projectImg: ElementRef;

	assetPath: string = './assets/images/prototype.svg';
	backColor: string = '#88c54f';
	typeString: string = '';
	cardPhoto: string = './assets/images/rectangle-default.svg';
	details: string = '';
	defaultImage: string = './assets/images/rectangle-default.svg';
	projectImage: string;

	constructor(public globals: GlobalsService) {
	}

	ngOnInit() {
		this.projectName = this.project?.projectName;
		const path = './assets/images/';
		this.backColor = '#FFFFFF';

		this.cardPhoto = this.project?.imagesUrls?.[0] ?
			this.project?.imagesUrls?.[0] : this.cardPhoto;

		switch (this.project?.type) {
			case ProjectTypes.challenge:
				this.projectName = this.project?.challengeName;
				this.assetPath = path + 'challenge.svg';
				this.typeString = 'Challenge';
				break;
			case ProjectTypes.concept:
				this.assetPath = path + 'concept.svg';
				this.typeString = 'Concept';
				break;
			case ProjectTypes.work:
				this.assetPath = path + 'work-model.svg';
				this.typeString = 'Working Model';
				break;
			case ProjectTypes.prototype:
				this.assetPath = path + 'prototype.svg';
				this.typeString = 'Prototype';
				break;
			case ProjectTypes.product:
				this.assetPath = path + 'product.svg';
				this.typeString = 'Product';
				break;
			default:
				break;
		}
		this.projectImage = this.cardPhoto;

		// tslint:disable-next-line:max-line-length
		const text =
			this.project?.type === 1
				? this.project?.additionalInformation?.challengeDetails?.trim()
				: this.project?.description?.trim();
		this.details = text?.length > 55 ? text?.substring(0, 55) + ' ...' : text;
	}

	public projectClick() {
		this.selectProjectAction?.emit(this.project);
	}


}
