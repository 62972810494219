import {Community} from './community';
import {User} from './user';
import {Country} from './country';
import {Project} from './project';

export class EventModel {
	_id: string;
	communityId: Community & string;
	countryId: string | Country;
	description: string;
	thumbnailText: string;
	endingDate: Date;
	imageUrl: string;
	coverUrl: string;
	leadershipTeam: string[] | User[];
	location: any;
	name: string;
	projects: string[] | Project;
	startingDate: Date;
	digitalAgreement: any;
}
