import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Observable} from 'rxjs';
import {GeneralService} from 'src/app/services/general.service';
import {DataService} from 'src/app/services/data.service';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
	selector: 'app-home-partners',
	templateUrl: './partners-section.component.html',
	styleUrls: ['./partners-section.component.scss']
})
export class PartnersSectionComponent implements OnInit {
	private animationItem_1: any;
	private animationItem_2: any;
	private animationSpeed: number = 1;
	private position: any;
	public showAll: boolean = false;

	@ViewChild('svgAnimationItem_1', {read: ElementRef}) svgAnimationItem_1: ElementRef;
	@ViewChild('svgAnimationItem_2', {read: ElementRef}) svgAnimationItem_2: ElementRef;
	@ViewChild('partner_container', {read: ElementRef, static: true}) partner_container: ElementRef;
	public partners: Observable<any>;

	constructor(
		public globals: GlobalsService,
		private generalService: GeneralService,
		public dataService: DataService,
		public analyticsService: AnalyticsService
	) {

	}

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		if (this.globals.isDesktop) {
			this.position = window.pageYOffset;
			if (this.position > this.partner_container.nativeElement.offsetTop - this.partner_container.nativeElement.offsetHeight) {
				this.playAnimation(1);
			}
			if (this.position > this.partner_container.nativeElement.offsetTop - this.partner_container.nativeElement.offsetHeight + 100) {
				this.playAnimation(2);
			}
		}
	}

	ngOnInit() {
		this.partners = this.generalService.getPopularPartners();
		if (this.globals.isDesktop) {
			this.showAll = true;
		}
	}

	// private scrollToTop() {
	// 		let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
	// 		// let currentScroll = window.pageYOffset;
	// 		console.log(currentScroll);
	// 		if (currentScroll > 0) {
	// 			window.requestAnimationFrame(this.scrollToTop);
	// 			window.scrollTo(0, currentScroll - (currentScroll / 5));
	// 		}
	// }

	handleAnimation(animationContext: any, animationIndex: number) {
		if (animationIndex === 1) {
			this.animationItem_1 = animationContext;
		} else {
			this.animationItem_2 = animationContext;
		}
	}

	stop(animationIndex: number) {
		if (animationIndex === 1) {
			this.animationItem_1.stop();
		} else {
			this.animationItem_2.stop();
		}
	}

	playAnimation(animationIndex: number) {
		if (animationIndex === 1) {
			this.animationItem_1?.play();
		} else {
			this.animationItem_2?.play();
		}
	}

	// pause() {
	// 	this.animationItem_1.pause();
	// }

	setSpeed(speed: number) {
		this.animationSpeed = speed;
		this.animationItem_1?.setSpeed(speed);
		this.animationItem_2?.setSpeed(speed);
	}


	navigateToExternalUrl(url: string) {
		if (url) {
			window.open(url, '_blank');
		}
	}

}
