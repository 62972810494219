import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from './shared.module';
import {ProjectPageComponent} from '../pages/project-page/project-page.component';
import {RelatedProjectsComponent} from '../components/related-projects/related-projects.component';
import {ProjectProgressComponent} from '../components/project-progress/project-progress.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {CommentsComponent} from '../components/comments/comments.component';
import {NewCommentComponent} from '../components/comments/new-comment/new-comment.component';
import {CommentItemComponent} from '../components/comments/comment-item/comment-item.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {PhotoSliderComponent} from '../components/photo-slider/photo-slider.component';
import {ShareComponent} from '../components/share/share.component';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';



@NgModule({
	declarations: [
		ProjectPageComponent,
		RelatedProjectsComponent,
		ProjectProgressComponent, CommentsComponent,
		NewCommentComponent, CommentItemComponent, PhotoSliderComponent,
		ShareComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		LazyLoadImageModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		ShareButtonsModule,
		RouterModule
	],
	exports: [
		ProjectPageComponent,
		RelatedProjectsComponent,
		ProjectProgressComponent, CommentsComponent,
		NewCommentComponent, CommentItemComponent, PhotoSliderComponent,
		ShareComponent],
})
export class ProjectComponentsModule {
}
