import {Component, Input, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';

interface ImageSlider {
	src: string;
	selected: boolean;
}

@Component({
	selector: 'app-photo-slider',
	templateUrl: './photo-slider.component.html',
	styleUrls: ['./photo-slider.component.scss']
})
export class PhotoSliderComponent implements OnInit {
	_imagesUrls: any = [];
	@Input() info: any = {};

	@Input() set imagesUrls(value) {
		if (value) {
			this._imagesUrls = value;
			this.imagesInit();
		}
	}

	photos: ImageSlider[] = [];
	mainPhoto = './assets/images/rectangle-default.svg';

	constructor(public globals: GlobalsService) {
	}

	ngOnInit() {
	}

	private imagesInit() {
		this.photos = [];
		for (let i = 0; i < this._imagesUrls.length; i += 1) {
			this.photos.push({
				src: this._imagesUrls[i],
				selected: false
			});
		}

		if (!this.photos[0]) {
			this.photos.push({src: './assets/images/rectangle-default.svg', selected: true});

		}

		this.photos[0].selected = true;
		this.mainPhoto = this.photos[0].src;
	}

	public slideImage(index: number) {
		for (const photo of this.photos) {
			photo.selected = false;
		}
		this.mainPhoto = this.photos[index].src;
		this.photos[index].selected = true;
	}

	public selectImage(src: string) {
		this.mainPhoto = src;
	}

	public checkFileType(imgSrc) {
		// 1 === img file;
		// 2 === video file;
		if (typeof imgSrc === 'string' && imgSrc?.includes('mp4')) {
			return 2;
		} else {
			return 1;
		}
	}
}
