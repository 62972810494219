<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'">
    <div class="popup">
        <div class="close-x pointer">
            <img src="../../../assets/images/close-x.svg" (click)="close()"/>
        </div>
        <div class="wrapper-title-icons">
            <div class="popup-title">
                {{ data.title }}&nbsp;{{data.params.makerName}}
            </div>
            <div *ngIf="globals.isDesktop" class="icons-wrapper">
                <img class="triangular-line icon" src="../../../assets/images/tom-triangular.png"/>
                <img class="circle-line icon" src="../../../assets/images/tom-Circle.png"/>
                <img class="square-line icon" src="../../../assets/images/tom-Square.png"/>
            </div>
        </div>

        <form class="form-content" [formGroup]="mailPopUpForm">
            <!--            <div class="row1">-->
            <!--                <div class="input-span-mail">-->
            <!--                    <span>From</span>&nbsp;&nbsp;-->
            <!--                    <input disabled placeholder="{{data.main[0]}}">-->
            <!--                </div>-->
            <!--                <div class="input-span-mail">-->
            <!--                    <span>To</span>&nbsp;&nbsp;-->
            <!--                    <input disabled placeholder="{{data.main[1]}}">-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div class="row2">-->
            <!--                <div class="input-span-mail">-->
            <!--                    <span class="span-sub">Product Name</span>&nbsp;&nbsp;-->
            <!--                    <input class="input-sub" disabled placeholder="{{data.params.productName}}">-->
            <!--                </div>-->
            <!--                <div class="input-span-mail">-->
            <!--                    <span class="span-sub">Link To Project</span>&nbsp;&nbsp;-->
            <!--                    <input class="input-sub" disabled placeholder="{{data.params.linkToProject}}">-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="row3">
                <div class="input-span-mail">
                    <!--                    <span class="span-sub">Request Content</span>&nbsp;&nbsp;-->
                    <!--                    <input matInput class="input-sub" [formControl]="mailPopUpContentFormControl" required>-->
                    <textarea class="input-sub" [formControl]="mailPopUpContentFormControl"></textarea>
                </div>
            </div>
            <mat-dialog-actions class="send-button-mat">
                <button mat-button class="send-button" [mat-dialog-close]="mailPopUpContentFormControl.value">GET
                    QUOTE
                </button>
            </mat-dialog-actions>
        </form>

    </div>
</div>
