<!-- Mobile View -->
<div *ngIf="globals.isMobile" class="mobile" (click)="openArticle()">
    <img class="press" [src]="press.imageUrl" *ngIf="!video_id"/>
    <iframe
            width="335"
            height="200"
            *ngIf="video_id"
            style="margin-top: 20px"
            [src]="video_id | safe"
    >
    </iframe>
    <div class="press-body">
        <div class="press-publisher">
            {{ press.publisher }}
        </div>
        <div class="press-title bold">
            {{ press.name }}
        </div>
        <!-- <a
            style="text-decoration: none"
            target="_blank"
            (click)="openArticle()"
        >
            <button class="btn-text-blue">Read Article</button>
        </a> -->
    </div>
</div>
<!-- END Mobile View -->

<!-- Desktop View -->
<div *ngIf="globals.isDesktop" class="desktop">
    <div class="press" (click)="openArticle()">
        <div
                class="press-image"
                [ngStyle]="{
				background: 'url(' + press.imageUrl + ')',
				'background-size': 'cover'
			}"
                *ngIf="!video_id"
        ></div>
        <iframe
                width="291"
                height="190"
                *ngIf="video_id"
                [src]="video_id | safe"
        >
        </iframe>
        <div class="flex-center">
            <div class="press-body">
                <div class="press-publisher">
                    {{ press.publisher }}
                    <span class="publish-date"
                    >({{ press.publishDate | date: "shortDate" }})</span
                    >
                </div>
                <div class="press-title bold">
                    {{ press.name }}
                </div>
                <div class="press-description">
                    {{ shortDescription || "..." }}
                </div>
                <!-- <button (click)="openArticle()" class="view-press-button text-center" [ngClass]="{'view-press-button-ie': isPC}">Read Article</button> -->
            </div>
        </div>
    </div>
</div>
<!-- END Desktop View -->
