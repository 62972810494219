import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {EventModel} from 'src/app/models/event';

import * as moment from 'moment';
import {Router} from '@angular/router';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
	selector: 'app-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
	@Input() event: EventModel;
	@Output() selectEventAction = new EventEmitter();

	month: string;
	datesString: string;
	fromDayString: string;
	eventDatesRange: string;
	eventDatesRangeDesktop: string;
	shortDescription: string;

	//details for event box
	eventStartMonth: string;
	eventEndMonth: string;
	isSameDay: boolean = false;
	datesRangeNumbers: string;

	constructor(
		public globals: GlobalsService,
		private router: Router,
		private analyticsService: AnalyticsService
	) {
	}

	ngOnInit() {
		this.generateEventDatesRange(
			this.event.startingDate,
			this.event.endingDate
		);
		const startMomentObj = moment(this.event.startingDate).utcOffset(
			'+000'
		);
		const endMomentObj = moment(this.event.endingDate).utcOffset('+000');
		this.shortDescription =
			this.event.thumbnailText && this.event.thumbnailText.length > 200
				? this.event.thumbnailText.substring(0, 60) + '...'
				: this.event.thumbnailText;
		this.month = startMomentObj.format('MMM');
		this.datesString = `${endMomentObj.format(
			'DD'
		)} - ${startMomentObj.format('DD')}`;
		this.fromDayString = `${startMomentObj.format(
			'ddd h a'
		)} - ${endMomentObj.format('ddd h a')}`;
		// if (!this.event.imageUrl || this.event.imageUrl == 'http://www.stevensegallery.com/300/210' || this.event.imageUrl.indexOf('drive.google.com') != -1) {
		// 	this.event.imageUrl = './assets/images/temp-event.jpg';
		// }
	}

	private generateEventDatesRange(startDate: Date, endDate: Date) {
		const momentStart = moment(startDate).utcOffset('+000');
		const momentEnd = moment(endDate).utcOffset('+000');
		let shortDateString: string;
		let dateString: string;
		if (
			momentStart.day() === momentEnd.day() &&
			momentStart.month() === momentEnd.month() &&
			momentStart.year() === momentEnd.year()
		) {
			this.isSameDay = true;
			shortDateString = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('MMM')}`;
			this.datesRangeNumbers = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('D')}`;
			// dateString = `${moment(this.event.startingDate).utcOffset('+000').format('ddd, MMM Do YYYY')}`;
			dateString = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('ddd, h:mm a')}`;
			this.eventStartMonth = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('MMM')}`;
			this.eventEndMonth = `${moment(this.event.endingDate)
				.utcOffset('+000')
				.format('MMM')}`;
			this.datesRangeNumbers = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('D')}`;
		} else {
			shortDateString = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('MMM D')} - ${moment(this.event.endingDate)
				.utcOffset('+000')
				.format('MMM D')}`;
			// dateString = `${moment(this.event.startingDate).utcOffset('+000').format('ddd, MMM Do YYYY')} - ${moment(this.event.endingDate).utcOffset('+000').format('ddd, MMM Do YYYY')}`;
			dateString = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('ddd, h:mm a')} - ${moment(this.event.endingDate)
				.utcOffset('+000')
				.format('ddd, h:mm a')}`;
			this.eventStartMonth = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('MMM')}`;
			this.eventEndMonth = `${moment(this.event.endingDate)
				.utcOffset('+000')
				.format('MMM')}`;
			this.datesRangeNumbers = `${moment(this.event.startingDate)
				.utcOffset('+000')
				.format('D')}-${moment(this.event.endingDate)
				.utcOffset('+000')
				.format('D')}`;
		}
		this.eventDatesRange = shortDateString;
		this.eventDatesRangeDesktop = dateString;
	}

	public eventClick() {
		this.analyticsService.sendEvent('Event Click', this.event.name);
		this.router.navigateByUrl(`/event?id=${this.event._id}`);
	}
}
