<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'">
    <div class="join">
        <div class="join-title bold text-center">Join Our Community</div>
        <div class="join-now-container">
            <div class="join-now bold text-center" [routerLink]="['/login']">
                Join Tom
            </div>
        </div>
    </div>
</div>
