import {Component, OnDestroy, Input, OnInit, SecurityContext} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {DataService} from '../../services/data.service';
import {PopupComponent} from '../../components/popup/popup.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from 'src/app/services/project.service';
import {Comment, Project} from 'src/app/models/project';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {UserService} from 'src/app/services/user.service';
import {EventService} from '../../services/event.service';
import {AuthService} from 'src/app/services/auth.service';
import {ProjectTypes} from '../../models/enums';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {MakersPopupComponent} from 'src/app/components/makers-popup/makers-popup.component';
import {DomSanitizer, Meta, SafeHtml} from '@angular/platform-browser';
import {forkJoin, Subscription} from 'rxjs';
import {Sponsor} from '../../models/sponsor';
import {DocumentationSystemService} from '../../services/documentation-system.service';
import {CommunityService} from '../../services/community.service';
import {Pagination} from '../../models/pagination';
import {defaultIfEmpty} from 'rxjs/operators';
import {Community} from '../../models/community';


@Component({
	selector: 'app-project-page',
	templateUrl: './project-page.component.html',
	styleUrls: ['./project-page.component.scss'],
})
export class ProjectPageComponent implements OnInit, OnDestroy {
	public projectId: string;
	@Input() isPreviewOfDocumentation?: boolean = false;
	project?: Project = null;

	isChallenge: boolean;
	isProduct: boolean;
	queryParamSubscription: Subscription;
	items: any[] = [];
	joinedEvent: boolean;
	pendingRequest: boolean;
	mainSponsor: Sponsor = null;
	relatedProjects: any[] = [];
	public flagIcon: string = '';
	public projectTypes = ProjectTypes;
	public relatedMembers: any[] = [];
	date: string;
	projectSubscription: Subscription;
	public membersPagination: Pagination = new Pagination(15);


	renderedHtmlResource: SafeHtml = '';

	constructor(
		public globals: GlobalsService,
		private activateRoute: ActivatedRoute,
		private router: Router,
		private projectService: ProjectService,
		private eventService: EventService,
		private userService: UserService,
		private dialog: MatDialog,
		private dataService: DataService,
		private authService: AuthService,
		public analyticsService: AnalyticsService,
		private documentationSystemService: DocumentationSystemService,
		private communityService: CommunityService,
		private metaService: Meta,
		private sanitizer: DomSanitizer
	) {
	}

	ngOnInit() {
		if (this.isPreviewOfDocumentation === false) {
			this.queryParamSubscription = this.activateRoute.queryParams.subscribe(param => {
				if (this.projectId !== param?.id) {
					this.projectId = param?.id;
					this.getProjectIdFromQueryParam();
				}
			});
		} else {
			this.initProjectFromDocumentation();
		}
	}


	initProjectFromDocumentation() {
		this.projectSubscription = this.documentationSystemService.projectAsObservable.subscribe(project => {
			const currentCommunity = this.project?.communityId;
			const currentEvent = this.project?.relatedEventId;
			const currentTeamMembers = this?.project?.members;
			this.project = Object.assign(new Project(), project);
			if (this.project?.communityId && typeof this.project?.communityId !== 'object' && this.project?.communityId !== currentCommunity) {
				this.communityService.getCommunityById(this.project.communityId, this.membersPagination)
					.subscribe(resp => {
						if (resp) {
							this.project.communityId = resp?.community;
							this.setFlagIcon();
						}
					}, (err) => {
						console.error(err);
					});
			}
			if (this.project?.relatedEventId && typeof this.project?.relatedEventId !== 'object' && this.project?.relatedEventId !== currentEvent) {
				this.eventService.getEventDetails(this.project.relatedEventId).subscribe(resp => {
					if (resp) {
						this.project.relatedEventId = resp;
						this.setEventStartingDate();
					}
				}, (err) => {
					console.error(err);
				});
			}
			if (JSON.stringify(project?.members) !== JSON.stringify(currentTeamMembers)) {
				this.setRelatedTeamWorkersForDocumentation();
			}
			this.initProjectDocumentationValues();
		});

	}

	initProjectDocumentationValues() {
		this.project.name = this.project.projectName;
		this.isChallenge = this.project.type === 1;
		this.isProduct = this.project.type === 5;
		this.mainSponsor = null;
		this.relatedProjects = [];
		if (!this.isChallenge) {
			this.renderedHtmlResource = this.sanitizeHtmlContent(this?.project?.resources ?? '');
		}

		if (this.isChallenge) {
			this.project.name = this.project?.challengeName ?? '';
			this.getMemberJoinEventStatus();
		}
		if (this.project && this.project.additionalInformation
			&& this.project.additionalInformation.teamName) {
			this.project.additionalInformation.teamName =
				this.project.additionalInformation.teamName
					.replace('Team', '')
					.replace('team', '');
		}
		if (this.items.length === 0) {
			this.setItems();
		} else {
			this.setCommentsCount();
		}

	}

	ngOnDestroy() {
		this.queryParamSubscription?.unsubscribe();
	}

	getProjectIdFromQueryParam() {
		// this.projectId = this.activateRoute.snapshot.queryParams.id;
		if (!this.projectId || this.projectId === 'undefined') {
			this.router.navigateByUrl('/projects');
		} else {
			this.getProjectById();
		}
	}


	private setRelatedTeamWorkersForDocumentation() {
		const promises = [];
		this.project?.members?.forEach(memberId => {
			promises.push(new Promise((resolve, reject) => {
				if (typeof memberId === 'string') {
					return this.userService.getPublicUserData(memberId).toPromise().then((response) => {
						resolve({data: response});
					}).catch((error) => {
						reject(error);
					});
				}
			}));
		});

		forkJoin(promises).pipe(defaultIfEmpty(null)).subscribe((values) => {
			const communities = [];
			if (this.project?.communityId && typeof this?.project?.communityId !== 'string') {
				communities.push(this.project?.communityId);
			}
			if (Array.isArray(values)) {
				console.log(communities);
				console.log(values);
				values?.forEach(user => {
					let userAdded = false;
					user?.data?.communities?.forEach(communityData => {
						const existingCommunityIndex = communities?.findIndex(community => community?._id === communityData?.communityId?._id);
						if (existingCommunityIndex > -1 && !userAdded) {
							communities[existingCommunityIndex]?.members?.push(user?.data?.data);
							userAdded = true;
						} else if (!userAdded) {
							const community = communityData?.communityId;
							community.members = [user?.data?.data];
							communities?.push(community);
							userAdded = true;
						}
					});
				});
			}
			this.relatedMembers = communities;
		});

	}


	getType() {
		switch (this.project.type) {
			case 1:
				return '../../../assets/images/nav-challenge.svg';
			case 2:
				return '../../../assets/images/nav-concept.svg';
			case 3:
				return '../../../assets/images/nav-work.svg';
			case 4:
				return '../../../assets/images/nav-prototype.svg';
			case 5:
				return '../../../assets/images/nav-product.svg';
		}
	}


	public selectItem(selectedItem: any) {
		for (const item of this.items) {
			item.selected = false;
		}
		selectedItem.selected = true;
	}

	public gotoEvent() {
		this.router.navigateByUrl(
			`/event?id=${this.project.relatedEventId._id}`
		);
	}

	public commentAdded(newComment: Comment) {
		this.getProjectById();
	}

	public downloadClicked() {
		this.analyticsService.sendEvent(
			'Project Downloaded',
			this.project.projectName
		);
		this.downloadFile();

	}

	public openMakersPopup() {
		this.analyticsService.sendEvent(
			'Click on button',
			'CHOOSE MAKERSPACE BUTTON'
		);
		this.analyticsService.sendEvent(
			'Window open',
			'OPEN MAKERSPACE WINDOW'
		);
		this.dialog.open(MakersPopupComponent, {
			data: {project: this.project},
			width: this.globals.isDesktop ? '25%' : '100%',
			height: '100vh',
			panelClass: 'makers-popup',
		});
	}

	private downloadFile() {
		this.projectService.getDownloadLink(this.projectId).subscribe(
			(resp) => {
				window.open(resp.link, '_blank');
				this.project.downloadCount++;
			},
			(err) => {
				console.error(err);
			}
		);
	}

	private getProjectById() {
		this.projectService.getProjectById(this.projectId).subscribe(
			(resp) => {
				if (!resp) {
					this.router.navigateByUrl('/search');
					return;
				}
				this.date = moment(resp.createdAt).format(
					'MMMM Do YYYY, h:mm a'
				);
				this.project = Object.assign(new Project(), resp);
				this.project.name = this.project.projectName;
				this.isChallenge = this.project.type === 1;
				this.isProduct = this.project.type === 5;
				this.mainSponsor = this.project?.sponsors && this.project?.sponsors.length > 0 ? this.project?.sponsors[0] : null;
				this.relatedProjects = this.project?.relatedProjects?.projects.filter(project => project?._id !== this.project?._id);
				this.renderedHtmlResource = this.sanitizeHtmlContent(this?.project?.resources ?? '');
				if (this.isChallenge) {
					this.project.name = this.project.challengeName;
					this.project.imagesUrls = [
						this.project.additionalInformation.challengeImage,
					];
					this.getMemberJoinEventStatus();
				} else {
					// this.setResources();
					this.setRelatedTeamWorkers();
				}
				if (this.project && this.project.additionalInformation
					&& this.project.additionalInformation.teamName) {
					this.project.additionalInformation.teamName =
						this.project.additionalInformation.teamName
							.replace('Team', '')
							.replace('team', '');
				}
				if (this.items.length === 0) {
					this.setItems();
				} else {
					this.setCommentsCount();
				}
				this.setEventStartingDate();
				this.setFlagIcon();
			},
			(err) => {
			}
		);
	}

	private setRelatedTeamWorkers() {
		const data = this.project?.relatedCommunityMembers;
		this.relatedMembers = [];

		const ownComm: any = this.project?.communityId;
		if (!!ownComm) {
			ownComm.members = this.project?.members ?? [];
		}

		if (this.project?.members?.length > 0) {
			this.relatedMembers.push(ownComm);
		}
		Object.keys(data)?.forEach((key) => {
			const commu = data[key][0]?.communityId;
			commu.members = data[key]?.map((item) => item?.memberId);
			this.relatedMembers?.push(commu);
		});
	}

	private setItems() {
		this.items.push({text: 'About', selected: true});
		this.items.push({text: 'The Challenge', selected: false});
		this.items.push({
			text: `Comments • ${this.project.comments.length || 0}`,
			selected: false,
		});
	}

	private setCommentsCount() {
		this.items[2].text = `Comments • ${this.project.comments?.length || 0}`;
	}

	private setEventStartingDate() {
		if (
			this.project.relatedEventId &&
			this.project.relatedEventId.startingDate
		) {
			// tslint:disable-next-line:max-line-length
			this.project.relatedEventId.startingDate = `${moment(
				this.project.relatedEventId.startingDate
			)
				.utcOffset('+000')
				.format('MMM D')}, ${moment(
				this.project.relatedEventId.startingDate
			)
				.utcOffset('+000')
				.format('YYYY')}`;
			// tslint:disable-next-line:max-line-length
			// this.eventDateRange = `${moment(this.project.relatedEventId.startingDate).utcOffset('+000').format('MMM D')}-${moment(this.project.relatedEventId.endingDate).utcOffset('+000').format('D')}, ${moment(this.project.relatedEventId.startingDate).utcOffset('+000').format('YYYY')}`;
		}

	}


	public askToJoinEvent() {
		if (!this.dataService.isUserLoggedIn) {
			// tslint:disable-next-line:max-line-length
			const dialogRef = this.dialog.open(PopupComponent, {
				data: {action: 'login'},
				panelClass: 'custom-dialog-container',
			});

			dialogRef.afterClosed().subscribe((hasConfirmed: boolean) => {
				if (hasConfirmed) {
					this.dataService.routeAfterLogin = `event?id=${this.project.relatedEventId._id}`;
					this.router.navigateByUrl('/login');
				}
			});
		} else {
			this.eventService
				.joinEvent(
					this.project.relatedEventId._id,
					(this.project.communityId as Community)._id
				)
				.subscribe(
					(resp) => {
						this.getMemberJoinEventStatus();
					},
					(err) => {
						if (err.error.error === 'join community') {
							const dialogRef = this.dialog.open(PopupComponent, {
								data: {action: 'joinCommunity'},
								panelClass: 'custom-dialog-container',
							});

							dialogRef
								.afterClosed()
								.subscribe((hasConfirmed: boolean) => {
									if (hasConfirmed) {
										this.router.navigateByUrl(
											'/community?id=' +
											(this.project.communityId as Community)._id
										);
									}
								});
						}
					}
				);
		}
	}

	private getMemberJoinEventStatus() {
		const userToken = this.authService.getToken();
		if (userToken && userToken !== 'undefined') {
			this.userService.getUserData().subscribe((user) => {
				if (user.data._id && this.project.relatedEventId) {
					// tslint:disable-next-line:max-line-length
					this.eventService
						.getMemberJoinEventStatus(
							this.project.relatedEventId._id,
							user.data._id
						)
						.subscribe(
							(userEvent) => {
								if (
									userEvent &&
									userEvent.status &&
									userEvent.status === 'approved'
								) {
									this.joinedEvent = true;
									this.pendingRequest = false;
								} else if (
									userEvent &&
									userEvent.status &&
									userEvent.status === 'pending'
								) {
									this.pendingRequest = true;
									this.joinedEvent = false;
								} else {
									this.pendingRequest = false;
									this.joinedEvent = false;
								}
							},
							(error) => {
								console.error(error);
							}
						);
				}
			});
		} else {
			this.pendingRequest = false;
			this.joinedEvent = false;
		}
	}

	private setFlagIcon() {
		if (!!this.project.communityId) {
			this.flagIcon =
				'flag-icon-' +
				this.project?.communityId['country']?.alpha2Code?.toLowerCase();
		}
	}


	// 1 - Challenge (Each project starts as a challenge by default.old).
	// 2 - Concept.
	// 3 - Work Model
	// 4 - Prototype.
	// 5 - Product.
	public headerName(): string {
		switch (this.project.type) {
			case 1:
				return 'challenge.svg';
			case 2:
				return 'concept.svg';
			case 3:
				return 'work-model.svg';
			case 4:
				return 'prototype.svg';
			case 5:
				return 'product.svg';
			default:
				return 'challenge.svg';
		}
	}

	public sanitizeHtmlContent(htmlstring): SafeHtml {
		htmlstring = htmlstring
			.split('<p></p>').join('</br>').replace(/<a\s+([^>]*href\s*=\s*['"])(?!https?:\/\/)([^'"]+)['"]/gi, '<a $1http://$2" target="_blank"');
		return this.sanitizer.sanitize(SecurityContext.HTML, htmlstring);
	}
}
