<div class="materials">
    <label class="B2-dark-text">Material used:</label>
    <form [formGroup]="materialsGuideForm" class="materials-form">
        <ng-container formArrayName="materials">
            <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
                <mat-expansion-panel cdkDrag *ngFor="let rowGroup of materialsForm; let i = index;" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="add-material">
                                <img class="icon" src="./assets/images/material-icon.png"
                                    (click)="onIconClick($event, i)" />
                                <span class="title">
                                    {{ rowGroup.controls['itemDescription'].value ?
                                    rowGroup.controls['itemDescription'].value : 'Add material ' + (i + 1) }}
                                </span>
                            </div>
                            <img src="./assets/images/delete-icon.svg" (click)="onIconClick($event, i)" />
                        </mat-panel-title>


                    </mat-expansion-panel-header>
                    <div [formGroup]="rowGroup">
                        <mat-form-field appearance="fill">
                            <mat-label>Item Description</mat-label>
                            <textarea #message maxlength="25" matInput cdkTextareaAutosize
                                [formControl]="rowGroup.controls['itemDescription']"></textarea>
                            <mat-hint align="end">{{message.value.length}} / 25</mat-hint>
                            <mat-error *ngIf="rowGroup.controls['itemDescription']?.invalid">{{'Mandatory
                                Field'}}</mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="fill">
                            <mat-label>Material</mat-label>
                            <input matInput [formControl]="rowGroup.controls['material']">
                            <mat-error *ngIf="rowGroup.controls['material']?.invalid">{{'Mandatory
                                Field'}}</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Manufacturing instructions</mat-label>
                            <input matInput [formControl]="rowGroup.controls['manufacturing']">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Dimensions</mat-label>
                            <input matInput [formControl]="rowGroup.controls['dimensions']">
                            <mat-error *ngIf="rowGroup.controls['dimensions']?.invalid">{{'Mandatory
                                Field'}}</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Unit</mat-label>
                            <mat-select [formControl]="rowGroup.controls['unit']" disableOptionCentering
                                [compareWith]="compareUnits">
                                <mat-option *ngFor="let type of dimensionTypes" [value]="type" class="form-text">
                                    {{type?.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="rowGroup.controls['unit']?.invalid">{{'Mandatory
                                Field'}}</mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="fill">
                            <mat-label>Quantity</mat-label>
                            <input type="number" matInput [formControl]="rowGroup.controls['qty']">
                            <mat-error *ngIf="rowGroup.controls['qty']?.invalid">{{'Mandatory
                                Field'}}</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Purchase Link</mat-label>
                            <input matInput [formControl]="rowGroup.controls['purchaseLink']">
                        </mat-form-field>

                        <app-upload-file-field (FilesToUpload)="onFileUploaded($event, i)"
                            [existingFiles]="getExistingFileFromInstruction(i)" [options]="optionsForUpload">
                        </app-upload-file-field>

                        <br><br>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
        <button type="button" class="pressed-button" (click)="addMaterial()">{{'+ New material'}}</button>
    </form>
</div>