import {Component, Input, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {SocialMediaInformation} from '../../models/community';

@Component({
	selector: 'app-share',
	templateUrl: './share.component.html',
	styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

	@Input() socialMediaInformation: SocialMediaInformation;

	constructor(public globals: GlobalsService) {
	}

	// TODO: create eventDataModel.

	ngOnInit() {
	}

}
