import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Router} from '@angular/router';

interface MenuItem {
	title: string;
	link: string;
	items: { title: string; link: string; fragment: string }[];
	isOpen: boolean;
}

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
	@Output() closeMenu = new EventEmitter<boolean>();
	menuItems: MenuItem[] = [];

	constructor(public globals: GlobalsService, private router: Router) {
	}

	ngOnInit() {
		this.menuItems.push({
			title: 'Projects',
			link: '/search',
			items: undefined,
			isOpen: false,
		});

		this.menuItems.push({
			title: 'Communities',
			link: '/communities',
			items: undefined,
			isOpen: false,
		});

		this.menuItems.push({
			title: 'Events',
			link: '/events',
			items: undefined,
			isOpen: false,
		});

		this.menuItems.push({
			title: 'About',
			link: '/about',
			items: [
				{title: 'Vision', link: '/about', fragment: 'vision'},
				{
					title: 'Mission & Objective',
					link: '/about',
					fragment: 'mission',
				},
				{title: 'TOM\'s story', link: '/about', fragment: 'story'},
				{
					title: 'The TOM Process',
					link: '/about',
					fragment: 'process',
				},
				{title: 'Global Team', link: '/about', fragment: 'team'},
				{title: 'About Reut', link: '/about', fragment: 'reut'},
				{title: 'Our Partners', link: '/about', fragment: 'our'},
				{title: 'Press Kit', link: '/about', fragment: 'kit'},
				// {
				// 	title: 'Communities',
				// 	link: '/about',
				// 	fragment: 'communities',
				// },
			],
			isOpen: false,
		});

		this.menuItems.push({
			title: 'Press',
			link: '/press',
			items: undefined,
			isOpen: false,
		});

		this.menuItems.push({
			title: 'Contact',
			link: '/contact',
			items: undefined,
			isOpen: false,
		});
	}

	navigateTo(url: string, fragment?: string) {
		if (url && fragment !== 'projects') {
			this.router.navigate([url], {fragment: fragment});
			this.close();
		}
		if (url && fragment === 'projects') {
			this.router.navigateByUrl(url, /* Removed unsupported properties by Angular migration: fragment. */ {});
			this.close();
		}
	}

	toggleItem(item: MenuItem) {
		item.isOpen = !item.isOpen;
	}

	close() {
		this.closeMenu.emit();
	}
}
