import { Subject } from 'rxjs';
import {Component, Injectable, OnDestroy} from '@angular/core';

@Injectable()
export abstract class Destroyable implements OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
