'use strict';

/*
Working with Desktop and Mobile, There are 2 ways to know if we are in desktop.
1. isDesktop=  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
2. isDesktop =  window.screen.width>1024 ;
If true then we are on desktop.

If we want to separate to Desktop , Tablet and mobile we do
1. Desktop =>  isDesktop == true == window.screen.width>1024
2. Tablet =>   isDesktop == false
3. Mobile => isMobile == true == window.screen.width<= 450;

(1024 is the size of the larger ipad)
*/

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Event, NavigationEnd, Router} from '@angular/router';

export const version = '1.0.0';

@Injectable({
	providedIn: 'root',
})
export class GlobalsService {
	innerHeight: number;
	isDesktop: boolean;
	isTablet: boolean;
	isMobile: boolean;
	whiteHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	clientGeolocationData: BehaviorSubject<object> =
		new BehaviorSubject<object>({});
	loadedImages: HTMLImageElement[] = [];
	isPreloadFinished: boolean = false;

	constructor(private router: Router) {
		this.innerHeight = window.innerHeight;
		this.resizeWidth(window.innerWidth);

		this.router.events.subscribe((routerEvent: Event) => {
			if (routerEvent instanceof NavigationEnd) {
				this.setHeaderColor(routerEvent.url);
			}
		});

		this.preloadImages();
	}

	public resizeWidth(width: number) {
		this.isDesktop = width > 450;
		this.isMobile = width <= 450;
	}

	public resizeHeight(height: number) {
		this.innerHeight = height;
	}

	public setWhiteHeader(state: boolean) {
		this.whiteHeader.next(state);
	}

	public setClientGeolocationData(state: object) {
		this.clientGeolocationData.next(state);
	}

	private setHeaderColor(url: string) {
		url = url.split('/')[1];
		url = url.split('#')[0];
		url = url.split('?')[0];
		switch (url) {
			case '':
				this.whiteHeader.next(true);
				break;
			case 'communities':
				this.whiteHeader.next(false);
				break;
			case 'results':
				this.whiteHeader.next(false);
				break;
			case 'home':
				this.whiteHeader.next(true);
				break;
			case 'about':
				this.whiteHeader.next(true);
				break;
			case 'supportTOM':
				this.whiteHeader.next(true);
				break;
			case 'search':
				this.whiteHeader.next(false);
				break;
			case 'event':
				this.whiteHeader.next(false);
				break;
			default:
				this.whiteHeader.next(false);
		}
	}

	private preloadImages() {
		let loaded = 0;
		const imagesArray = [];
		imagesArray.push(
			this.isDesktop
				? './assets/images/welcome-1.png'
				: './assets/images/welcome-m-1.png'
		);
		imagesArray.push(
			this.isDesktop
				? './assets/images/welcome-2.png'
				: './assets/images/welcome-m-2.png'
		);
		imagesArray.push(
			this.isDesktop
				? './assets/images/welcome-3.png'
				: './assets/images/welcome-m-3.png'
		);
		imagesArray.push(
			this.isDesktop
				? './assets/images/about-background.png'
				: './assets/images/about-background-m.png'
		);
		imagesArray.push(
			this.isDesktop
				? './assets/images/support-tom-bg@desktop.jpg'
				: './assets/images/support-tom-bg@mobile.jpg'
		);
		imagesArray.push(
			this.isDesktop
				? '/assets/images/search-page-bg@desktop.jpg'
				: '/assets/images/search-page.png'
		);

		imagesArray.push(
			this.isDesktop
				? '/assets/images/community-6.png'
				: '/assets/images/community-7.jpg'
		);

		for (const image of imagesArray) {
			const img = new Image();
			img.src = image;
			this.loadedImages.push(img);
			img.addEventListener('load', () => {
				loaded++;
				if (loaded === imagesArray.length) {
					this.isPreloadFinished = true;
				}
			});
		}
	}
}
