import {User} from './user';

export class Country {
	alpha2Code: string = '';
	name: string = '';
	_id: string = '';
	countryManager?: string | User = null;
	countryManagerShortBiography?: string;
	imageUrl?: string;
	basicInfo?: string;
}
