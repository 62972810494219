import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Pagination} from '../models/pagination';
import {Observable} from 'rxjs';
import {Community} from '../models/community';

@Injectable({
	providedIn: 'root'
})
export class CommunityService extends HttpService {
	public getCommunities(pagination: Pagination, countryId?: string) {
		const url = '/community';
		const params = pagination.getPaginationAsParams();
		if (countryId) {
			params.countryId = countryId;
		}
		return this.get(url, params);
	}

	public getAllCommunitiesByType(categoryType): Observable<any> {
		const url = '/community/type';

		return this.get(url, {categoryType});
	}

	public getAllCommunitiesByCountry(countryId): Observable<any> {
		const url = '/community/country';
		return this.get(url, {countryId});
	}

	public requestCommunity(fullName: string, email: string, location: string, message: string) {
		const url = '/community/request';
		const body = {
			fullName,
			email,
			location,
			message
		};
		return this.post(url, body);
	}

	public getCommunityById(communityId: string, pagination: Pagination) {
		const url = '/community/details';
		const params = pagination.getPaginationAsParams();
		params.communityId = communityId;
		return this.get(url, params);
	}

	public getCommunitySponsors(communityId: string) {
		const url = '/community/sponsors';
		return this.get(url, {communityId});
	}


	public getCommunityMembers(communityId: string, pagination: Pagination, showInCommunityPage?: boolean) {
		const url = '/community/members';
		const params = pagination.getPaginationAsParams();
		params.communityId = communityId;
		if (showInCommunityPage) {
			params.showInCommunityPage = showInCommunityPage;
		}
		return this.get(url, params);
	}

	public joinCommunity(communityId: string, userId: any) {
		const url = '/community/join';
		const body = {communityId: communityId, memberId: userId};

		return this.post(url, body);
	}

	public getMemberCommunityStatus(communityId: string, memberId: string) {
		return this.get('/community/memberstatus', {communityId, memberId});
	}

	public getCommunityForFilters() {
		return this.get('/community/filter');
	}

	public getCommunitiesTypeAndCountryFilter(categoryType, countryId?) {
		const url = '/community/type-country';
		if (!countryId) {
			return this.get(url, {categoryType});
		}
		return this.get(url, {categoryType, countryId});
	}
}
