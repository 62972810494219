import {Injectable} from '@angular/core';
import {FacebookService, InitParams} from 'ngx-facebook';


@Injectable({
	providedIn: 'root'
})
export class AuthService {
	constructor(private fb: FacebookService) {
	}

	facebookInit() {
		const initParams: InitParams = {
			appId: '1924294171010771',
			xfbml: true,
			version: 'v3.2'
		};

		this.fb.init(initParams);
		// this.fb.logout().then(resp => {
		// 	console.log(resp);
		// });
	}

	getFacebookStatus() {
		this.fb.getLoginStatus().then(resp => {
			return resp;
		});
	}

	getFacebookInfo() {
		this.fb.api(`me`).then(res => {
			return res;
		});
	}

	logout() {
		localStorage.removeItem('TOM-token');
	}

	getToken() {
		return localStorage.getItem('TOM-token');
	}

	setToken(token: string) {
		localStorage.setItem('TOM-token', token);
	}
}
