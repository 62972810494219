export enum ProjectTypes {
	// challenge = 1,
	// prototype = 2,
	// product = 3,

	challenge = 1,
	concept = 2,
	work = 3,
	prototype = 4,
	product = 5,
}

export enum SponsorTypes {
	regular = 1,
	gold,
	platinium,
}
