import {Component, Input, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Router} from '@angular/router';
import {User} from '../../models/user';

@Component({
	selector: 'app-team-members',
	templateUrl: './team-members.component.html',
	styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit {
	@Input() communityWithUsers: any;


	constructor(public globals: GlobalsService, private router: Router) {
	}

	ngOnInit() {
		if (this.communityWithUsers?.socialMediaInformation) {
			this.communityWithUsers.socialMediaInformation.logoUrl = this.communityWithUsers?.socialMediaInformation?.logoUrl ? this.communityWithUsers.socialMediaInformation.logoUrl : './assets/images/rectangle-default.svg';
		}
		this.communityWithUsers?.members?.forEach((member: User) => {
			member.imageUrl = member?.imageUrl ? member.imageUrl : './assets/images/circle-default.svg';
		});
	}

	navigateToUserProfile(memberId) {
		this.router.navigateByUrl(`/public/profile?id=${memberId}`);
	}
}
