import {Component, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Project} from 'src/app/models/project';
import {ProjectService} from 'src/app/services/project.service';
import {Router} from '@angular/router';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
	selector: 'app-projects-section',
	templateUrl: './projects-section.component.html',
	styleUrls: ['./projects-section.component.scss']
})
export class ProjectsSectionComponent implements OnInit {

	projects: Project[] = [];

	constructor(
		public globals: GlobalsService,
		private projectService: ProjectService,
		private router: Router,
		public analyticsService: AnalyticsService
	) {
	}

	ngOnInit() {
		if (this.router.url.includes('/profile')) {
			this.getUserProjects();
		} else {
			this.getPopularProjects();
		}
	}

	private getUserProjects() {
		this.projectService.getUserProjects().subscribe(resp => {
			resp.forEach(item => {
				this.projects.push(Object.assign(new Project(), item));
			});
		}, err => {
			console.log('getting popular error');
		});
	}

	private getPopularProjects() {
		this.projectService.popular().subscribe(resp => {
			resp.forEach(item => {
				this.projects.push(Object.assign(new Project(), item));
			});
		}, err => {
			console.log('getting popular error');
		});
	}

	projectClicked(projectObj: Project) {
		this.analyticsService.sendEvent('Project Click', projectObj.projectName);
		this.router.navigateByUrl(`/project?id=${projectObj._id}`);
	}
}
