import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {SlickCarouselComponent} from 'ngx-slick-carousel';


@Component({
	selector: 'app-items-slider',
	templateUrl: './items-slider.component.html',
	styleUrls: ['./items-slider.component.scss'],
})
export class ItemsSliderComponent implements OnInit {

	constructor(
		private router: Router,
	) {
	}

	@Input() items: any[] = [];
	@Input() type: string;
	@Input() title: string;
	@Input() slideRows: number;
	@Input() amount: number;
	@ViewChild('slickModal', {static: true}) slickModal: SlickCarouselComponent;


	slideConfig = {};

	ngOnInit() {
		this.initSlideConfig();
	}

	initSlideConfig() {
		// this.slideConfig = {
		// 		speed: 500,
		// 		slidesToShow: 4,
		// 		padding: 10,
		// 		slidesPerRow: 4,
		// 		slidesToScroll: 4,
		// 		prevArrow: false,
		// 		nextArrow: false,
		// 		useCSS: true,
		// 		rows: this.slideRows,
		// 		variableWidth: true,
		// 		responsive: [
		// 			{
		// 				breakpoint: 1250,
		// 				settings: {
		// 					slidesToShow: 3,
		// 					slidesToScroll: 3,
		// 					// centerMode: true,
		// 				},
		// 			},
		// 			{
		// 				breakpoint: 800,
		// 				settings: {
		// 					slidesToShow: 2,
		// 					slidesToScroll: 2,
		// 					dots: true,
		// 					infinite: true,
		// 				},
		// 			},
		// 			{
		// 				breakpoint: 480,
		// 				settings: {
		// 					slidesToShow: 1,
		// 					slidesToScroll: 1,
		// 					dots: true,
		// 					infinite: true,
		// 					autoplay: true,
		// 					autoplaySpeed: 2000,
		// 				},
		// 			},
		// 		],
		// 	};
		if (this.slideRows === 2) {
			this.slideConfig = {
				slidesPerRow: 4,
				slidesToShow: 1,
				slidesToScroll: 1,
				rows: this.slideRows,
				prevArrow: false,
				nextArrow: false,
				useCss: true,
				centerMode: false
			};
		} else if (this.slideRows === 1) {
			this.slideConfig = {
				slidesToShow: 4,
				slidesToScroll: 4,
				rows: this.slideRows,
				prevArrow: false,
				nextArrow: false,
				useCss: true,
				centerMode: false
			};
		}
	}

	public itemClicked(item: any) {
		this.router.navigateByUrl(`/${this.type}?id=${item?._id}`);
	}

	next() {
		this.slickModal.slickNext();
	}

	prev() {
		this.slickModal.slickPrev();
	}
}
