import {Component, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';

@Component({
	selector: 'app-home-join',
	templateUrl: './join-section.component.html',
	styleUrls: ['./join-section.component.scss']
})
export class JoinSectionComponent implements OnInit {

	constructor(public globals: GlobalsService) {
	}

	ngOnInit() {
	}

}
