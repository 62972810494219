import {Component, Input, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Router} from '@angular/router';

@Component({
	selector: 'app-member-info',
	templateUrl: './member-info.component.html',
	styleUrls: ['./member-info.component.scss']
})
export class MemberInfoComponent implements OnInit {

	@Input() member: any;
	@Input() mail: boolean = true;
	@Input() bottom: boolean = false; // whether the info text is under the photo
	@Input() emailHover: boolean = true; // whether the email will be shown only on hover
	@Input() showMailOnMobile: boolean = false;
	public image: string;
	flag = '';

	// showMailOnMobile: boolean = false;

	constructor(public globals: GlobalsService, private router: Router) {
	}

	ngOnInit() {
		if (this.globals.isMobile) {
			this.emailHover = false;
			// if(this.member.permissionsTypes === "communityManager" ) {
			// 	this.showMailOnMobile = true;
			// }
		}
		const country = this.member.basicUserInformation.countryId;
		if ((country as any).alpha2Code) {
			this.flag = (country as any).alpha2Code.toLowerCase();
		}
		this.image = this.member.imageUrl ? this.changeFBImgUrl(this.member.imageUrl) : './assets/images/circle-default.svg';
	}

	private changeFBImgUrl(url) {
		if (url.indexOf('?asid=') != -1) {
			var id = url.substring(url.indexOf('?asid=') + 6, url.indexOf('&'));
			return `https://graph.facebook.com/${id}/picture?type=large`;
		}
		return url;
	}

	public navigateToUserProfile() {
		this.router.navigateByUrl(`/public/profile?id=${this.member._id}`);
	}

}
