<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'">
    <div class="project" (click)="projectClick()">
        <div class="project-image">
            <img [defaultImage]="defaultImage"
                 [lazyLoad]="projectImage"
                 class="project-image__img">
            <!-- <div
                class="project-type bold"
                [ngStyle]="{ background: backColor }"
            >
                <img class="type-icon" [src]="assetPath" />
                <span class="type-name">{{ typeString }}</span>
            </div> -->
            <img *ngIf="showType" class="type-icon" [src]="assetPath"/>
        </div>
        <div class="project-details">
            <div class="project-title bold">
                <!-- {{ project.names[project.names.length - 1] | titlecase }} -->
                {{ projectName | titlecase }}
            </div>
            <div class="project-description">
                {{ details | titlecase }}
            </div>
        </div>
    </div>
</div>
