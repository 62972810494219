export class Pagination {
	currentPage: number = 1;
	itemsPerPage: number = 15;
	totalNumberOfPages: number = 1;

	public getPaginationAsParams(): any {
		const skip = (this.currentPage - 1) * this.itemsPerPage;
		return {skip: skip, limit: this.itemsPerPage};
	}

	constructor(itemsPerPage?: number) {
		if (itemsPerPage) {
			this.itemsPerPage = itemsPerPage;
		}
	}

	public resetPagination() {
		this.currentPage = 1;
		this.itemsPerPage = 15;
	}

	public checkAndUpdateCurrentPage() {
		if (this.currentPage < this.totalNumberOfPages) {
			++this.currentPage;
			return true;
		}
		return false;
	}

}
