<!-- Desktop Section -->
<div *ngIf="globals.isDesktop" class="desktop">
    <!-- <div class="limit-width" style="position: relative"></div> -->
    <div class="projects">
        <div class="top-container">
            <div class="top-container-title bold">Popular Projects</div>
            <div
                    class="show-all"
                    (click)="
					analyticsService.sendEvent(
						'View all projects',
						'ALL PROJECTS'
					)
				"
                    [routerLink]="['/search']"
            >
                Show All
            </div>
        </div>
        <div class="wrap-container">
            <div
                    class="project-container"
                    *ngFor="let project of projects | slice: 0:12"
            >
                <app-project
                        [showType]="true"
                        [project]="project"
                        (selectProjectAction)="projectClicked($event)"
                ></app-project>
            </div>
        </div>
        <!-- <div class="button-container">
            <button
                class="all-projects heavy text-center"
                (click)="
                    analyticsService.sendEvent(
                        'View all projects',
                        'ALL PROJECTS'
                    )
                "
                [routerLink]="['/search']"
            >
                ALL PROJECTS
                <img
                    *ngIf="globals.isDesktop"
                    src="./assets/images/arrow-right-blue.svg"
                />
            </button>
        </div> -->
    </div>
    <!-- </div> -->
</div>

<!-- Mobile Section -->
<div *ngIf="globals.isMobile" class="mobile">
    <div class="limit-width" style="position: relative">
        <div class="tom-logo"></div>
    </div>
    <div class="limit-width">
        <div class="projects">
            <div class="projects-title bold">Popular Projects</div>
            <div class="wrap-container">
                <div
                        class="project-container"
                        *ngFor="let project of projects | slice: 0:8"
                >
                    <app-project
                            [showType]="true"
                            [project]="project"
                            (selectProjectAction)="projectClicked($event)"
                    ></app-project>
                </div>
                <app-new-challenge></app-new-challenge>
            </div>
            <div class="button-container">
                <button
                        class="all-projects heavy text-center"
                        (click)="
						analyticsService.sendEvent(
							'View all projects',
							'ALL PROJECTS'
						)
					"
                        [routerLink]="['/search']"
                >
                    All Projects
                    <img
                            *ngIf="globals.isDesktop"
                            src="./assets/images/arrow-right-blue.svg"
                    />
                </button>
            </div>
        </div>
    </div>
</div>
