import {Component} from '@angular/core';


@Component({
	selector: 'app-welcome-popup',
	templateUrl: './welcome-popup.component.html',
	styleUrls: ['./welcome-popup.component.scss']
})
export class WelcomePopupComponent {
	closePressed = false;

	index = 0;
	welcomeSection = [
		{
			title: 'Welcome',
			text: 'Welcome to TOM’s Project Editor. Here, you can document, edit, and publish projects onto <a href= \'https://tomglobal.org/search\' target="_blank" > TOM portfolio of projects </a>. By filling out this form, you will create a detailed guide for building your project.',
			flag: false
		},
		{
			title: 'Documentation is critical',
			text: 'By capturing your hard work, we can share this knowledge with the world and help many others! The more information you share, the higher chance that others can use and enjoy your work.',
			flag: true
		},
		{
			title: 'A tip from us',
			text: 'The simpler the better! Use simple and commonly available tools and materials to make your project more accessible to makers around the world.',
			flag: true,
			button: 'Create project'
		}

	];


	constructor() {
	}

	next() {
		if (this.index + 1 > this.welcomeSection?.length) {
			this.index = 0;
		} else {
			this.index++;
		}
	}

	back() {
		this.index--;
	}

	swipe(swipeIndex: number) {
		this.index = swipeIndex;
	}

	closeDialog() {
		this.closePressed = true;
	}


}
