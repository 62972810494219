<div class="container" *ngIf="!closePressed">
    <p class="title" mat-dialog-title> {{welcomeSection[index]?.title}} </p>
    <p class="text" [innerHTML]="welcomeSection[index]?.text"></p>
    <div class="buttons-row">
        <button *ngIf="index !== 0" mat-flat-button class="button back-button" (click)="back()">Back</button>
        <button *ngIf="index !== welcomeSection?.length - 1" mat-flat-button class="button next-button" (click)="next()">Next
        </button>
        <button *ngIf="index == welcomeSection?.length - 1" mat-flat-button class="button next-button" (click)="closeDialog()">Create Project</button>
    </div>

    <div class="dots-row">
        <button class="dot" (click)="swipe(0)" [ngStyle]="{'background': index === 0 ? '#00A3D3' : '#8C8C8C'}"> </button>
        <button class="dot" (click)="swipe(1)" [ngStyle]="{'background': index === 1 ? '#00A3D3' : '#8C8C8C'}"> </button>
        <button class="dot" (click)="swipe(2)" [ngStyle]="{'background': index === 2 ? '#00A3D3' : '#8C8C8C'}"> </button>
    </div>
    <button (click)="closeDialog()" class="close">
        <mat-icon>close</mat-icon>
    </button>
</div>


