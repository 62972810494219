<div [ngClass]="globals.isDesktop ? 'desktop flex-box-center' : 'mobile'">
    <div class="limit-width">
        <div class="press flex-box-center">
            <!-- <div *ngIf="globals.isMobile" class="tom-logo"></div> -->
            <div class="press-title bold text-center">In the press</div>
            <!-- <div *ngIf="globals.isMobile" class="press-line"></div> -->
            <div class="press-logos">
                <!--<div class="logo-container pointer" (click)="navigateToExternalUrl(pressItem?.linkUrl)">-->
                <div
                        *ngFor="let pressItem of pressItems"
                        class="logo-container"
                >
                    <a
                            class="pointer"
                            target="_blank"
                            [href]="pressItem.linkUrl"
                    ><img [src]="pressItem.logoUrl"
                    /></a>
                </div>
            </div>
            <button
                    *ngIf="globals.isMobile"
                    class="btn-text-blue"
                    (click)="
					analyticsService.sendEvent('View all press', 'SHOW ALL')
				"
                    [routerLink]="['/press']"
            >
                Show All
            </button>
        </div>
    </div>
</div>
