import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalsService } from '../../services/globals';
import { DocumentationSystemService } from '../../services/documentation-system.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssemblyStep } from '../../models/step';
import { ProjectMaterial } from '../../models/projectMaterial';
import { Documentation, SpecialInstruction, unique_pdf_preview_fields, unique_project_preview_fields } from '../../models/documentation';
import { ProjectService } from '../../services/project.service';
import { take, takeUntil } from 'rxjs/operators';
import { Project } from '../../models/project';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { DataService } from '../../services/data.service';
import { Destroyable } from '../../shared/destroyable';
import { ChallengeSectionComponent } from 'src/app/components/documentions-components/challenge-section/challenge-section.component';
import { ConceptSectionComponent } from 'src/app/components/documentions-components/concept-section/concept-section.component';
import { WorkingModelSectionComponent } from 'src/app/components/documentions-components/working-model-section/working-model-section.component';
import { PrototypeSectionComponent } from 'src/app/components/documentions-components/prototype-section/prototype-section.component';


enum DocumentationSteps {
	challenge,
	concept,
	workingModel,
	prototype,
	product
}

enum PreviewType {
	pdf,
	project
}


enum SaveButtonsState {
	save = 'Save',
	saving = 'Saving',

	saved = 'Saved!'
}

@Component({
	selector: 'app-documentation-page',
	templateUrl: './documentation-page.component.html',
	styleUrls: ['./documentation-page.component.scss'],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false },
		},
	],
})
export class DocumentationPageComponent extends Destroyable implements OnInit {
	documentationCurrentValue = this.documentationService?.valueOfDocumentation;
	PreviewTypeEnum = PreviewType;

	// component data
	public documentationId: string = '';
	isUpdate = false;
	public previewType: PreviewType = PreviewType.pdf;
	project: Project = null;
	userProfileImage: string = 'assets/images/person-color.svg';

	assemblySteps: AssemblyStep[] = [];
	projectMaterials: ProjectMaterial[] = [];
	specialInstructions: SpecialInstruction[] = [];
	documentationLoaded = false;
	currentStepIndex: number = 0;
	isPreviousStep: boolean = false;
	currentStepElement: HTMLElement;

	saveButtonState = SaveButtonsState.save;

	challengeFormGroup;
	conceptFormGroup;

	@ViewChild('stepper') stepper: MatStepper;
	@ViewChild('challengeSection') challengeSection: ChallengeSectionComponent;
	@ViewChild('conceptSection') conceptSection: ConceptSectionComponent;
	@ViewChild('workingModelSection') workingModelSection: WorkingModelSectionComponent;
	@ViewChild('prototypeSection') prototypeSection: PrototypeSectionComponent;

	constructor(
		public globals: GlobalsService,
		private documentationService: DocumentationSystemService,
		private activateRoute: ActivatedRoute,
		private router: Router,
		public dataService: DataService,
		private projectService: ProjectService,
	) {
		super();
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
		this.subscribeToDocumentationChanges();
		this.subscribeToProjectChanges();
		this.subscribeToUploadingChanges();
		this.subscribeToUser();
		this.subscribeToForm();
	}

	subscribeToQueryParams() {
		this.activateRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe(param => {
			if (this.documentationId !== param?.id) {
				this.documentationId = param?.id;
				this.getDocumentationIdFromQueryParam();
			} else {
				this.resetFormFields();
			}
		});
	}

	subscribeToUser() {
		this.dataService.globalUser?.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			if (user && user.imageUrl) {
				this.userProfileImage = user.imageUrl ?? 'assets/images/person-color.svg';
			}
		});
	}

	subscribeToUploadingChanges() {
		this.documentationService.uploadingDocumentationAsObservable.subscribe(savingDocumentation => {
			if (savingDocumentation) {
				this.saveButtonState = SaveButtonsState.saving;
			} else if (!savingDocumentation && this.saveButtonState === SaveButtonsState.saving) {
				this.saveButtonState = SaveButtonsState.saved;
			}
		});
	}


	getDocumentationIdFromQueryParam() {
		// this.projectId = this.activateRoute.snapshot.queryParams.id;
		if (!this.documentationId || this.documentationId === 'undefined') {
			this.router.navigateByUrl('/documentation');
			this.resetFormFields();
			// this.showPopupWelcomeSlide();
		} else {
			this.getDocumentationById();
		}
	}

	get isChallengeSectionValid(): boolean {
		return this.challengeSection?.isValid;
	}

	get isConceptSectionValid(): boolean {
		return this.conceptSection?.isValid;
	}

	get isWorkingModelValid(): boolean {
		return this.workingModelSection?.isValid;
	}

	get isProtoTypeValid(): boolean {
		return this.prototypeSection?.isValid;
	}

	private getDocumentationById() {
		this.documentationService.getDocumentationDetails(this.documentationId).subscribe(
			(resp: Documentation) => {
				if (!resp) {
					this.resetFormFields();
					return;
				}
				this.isUpdate = true;
				this.documentationService.valueOfDocumentation = resp;
				this.documentationCurrentValue = this.documentationService?.valueOfDocumentation;
				this.assemblySteps = this.documentationCurrentValue?.assemblySteps ?? [];
				this.projectMaterials = this.documentationCurrentValue?.materials ?? [];
				this.specialInstructions = this.documentationCurrentValue?.specialInstructions ?? [];
				if (!!resp?.project) {
					this.fetchRelatedProject(resp?.project);
				}
			},
			(err) => {
				this.resetFormFields();
			}
		);
	}

	fetchRelatedProject(projectId) {
		this.projectService.getProjectById(projectId).pipe(take(1)).subscribe(
			(response: Project) => {
				this.documentationService.valueOfProject = response;
				this.documentationLoaded = true;
			});
	}


	// Resets the fields to empty values
	private resetFormFields() {
		this.isUpdate = false;
		this.documentationService.initNewDocumentation();
		this.documentationLoaded = true;
	}


	//
	subscribeToDocumentationChanges() {
		this.documentationService.documentationAsObservable?.pipe(takeUntil(this.destroy$)).subscribe(value => {
			// in case user making changes after pressing 'Save'
			if (this.saveButtonState === SaveButtonsState.saved) {
				this.saveButtonState = SaveButtonsState.save;
			}
			this.documentationCurrentValue = value;
		});
	}

	subscribeToProjectChanges() {
		this.documentationService.projectAsObservable.subscribe(value => {
			this.project = value;
		});
	}

	onUploadingDocumentation(event: any) {
		if (this.isUpdate) {
			this.updateDocumentation(event);
		} else {
			this.createNewDocumentation(event);
		}
	}

	initUploadingDocumentation() {
		this.previewType = PreviewType.pdf;
		this.documentationService.valueOfUploadingDocumentation = true;
	}


	createNewDocumentation(documentationPdfFile: File) {
		this.documentationService.generateZipFileAsPromise(documentationPdfFile).then(response => {
			this.documentationCurrentValue = this.documentationService?.valueOfDocumentation;
			this.documentationService.createNewDocumentation(
				this.documentationCurrentValue
			).subscribe(result => {
				this.documentationService.valueOfUploadingDocumentation = false;
				this.router.navigateByUrl(`/documentation?id=${result?._id}`).then(r => {
					if (this.documentationService.askedForProduct) {
						this.documentationService.askToAcceptAsProduct(result?._id).subscribe(res => console.log(res));
						this.documentationService.askedForProduct = false;
					}
				});
			}, error => {
				console.log(error);
			});
		});
	}

	updateDocumentation(documentationPdfFile: File) {
		this.documentationService.generateZipFileAsPromise(documentationPdfFile).then(response => {
			this.documentationCurrentValue = this.documentationService?.valueOfDocumentation;
			this.documentationService.updateDocumentation(
				this.documentationCurrentValue
			).subscribe(result => {
				this.documentationService.valueOfUploadingDocumentation = false;
				if (this.documentationService.askedForProduct) {
					this.documentationService.askToAcceptAsProduct(this.documentationId).subscribe(res => console.log(res));
					this.documentationService.askedForProduct = false;
				}
			}, error => {
				console.log(error);
			});
		});
	}

	selectPreviewType(previewType: PreviewType) {
		this.previewType = previewType;
	}


	// change project status to active and then save documentation
	initPublishDocumentation() {
		this.previewType = PreviewType.pdf;
		this.documentationService.valueOfProject = { ...this.documentationService.valueOfProject, status: 'active' };
		this.documentationService.valueOfUploadingDocumentation = true;
	}

	selectionChange(event: StepperSelectionEvent) {
		this.currentStepIndex = event?.selectedIndex;
		this.currentStepElement = document.getElementById('0');
		this.currentStepElement.scrollIntoView({ behavior: 'smooth' });
	}


	moveToDifferentStep($event: any) {
		if (this.isPreviousStep) {
			this.moveStepper($event);
		}
	}

	moveStepper(index: number) {
		this.stepper.selectedIndex = index;
		const elem = document.getElementById('0');
		elem.scrollIntoView({ behavior: 'smooth' });
	}

	isStepSelected(index: number) {
		return index === this.currentStepIndex;
	}

	// get step icon by index
	getStepIconSrcByIndex(index: number) {
		// return the step icon
		switch (index) {
			case DocumentationSteps.challenge:
				return `assets/images/steps-icons/challenge-grey.svg`;
			case DocumentationSteps.concept:
				return `assets/images/steps-icons/concept-grey.svg`;
			case DocumentationSteps.workingModel:
				return `assets/images/steps-icons/working-model-grey.svg`;
			case DocumentationSteps.prototype:
				return `assets/images/steps-icons/prototype-grey.svg`;
			case DocumentationSteps.product:
				return `assets/images/steps-icons/product-grey.svg`;
		}
		return null;
	}

	isStepEnabled(step: DocumentationSteps) {
		if (this.isUploading()) {
			return false;
		}
		switch (step) {
			case DocumentationSteps.challenge:
				return true;
			case DocumentationSteps.concept:
				return this.documentationCurrentValue.type > 0; // 0 - before challenge!
			case DocumentationSteps.workingModel:
				return this.documentationCurrentValue.type > 1;
			case DocumentationSteps.prototype:
				return this.documentationCurrentValue.type > 2 || (this.documentationCurrentValue.usingFinalMaterials && this.documentationCurrentValue.usingFinalTools);
			case DocumentationSteps.product:
				return this.documentationCurrentValue.type > 3;
		}
		return false;
	}

	isPublishButtonDisabled() {
		return this.documentationCurrentValue?.type < 2 || this.project?.status === 'active';
	}

	isUploading() {
		return this.saveButtonState === SaveButtonsState.saving;
	}

	subscribeToForm() {
		this.documentationService.currentEditFieldAsObservable.pipe(takeUntil(this.destroy$)).subscribe(element => {
			if (unique_project_preview_fields.some(field => element?.includes(field))) {
				this.previewType = PreviewType.project;
			} else if (unique_pdf_preview_fields.some(field => element?.includes(field))) {
				this.previewType = PreviewType.pdf;
			}

			const elem = document.getElementById(element);
			if (!!elem) {
				if (element.includes('material')) {
					elem?.scrollIntoView({ behavior: 'auto', block: 'center' });
				} else {
					setTimeout(() => {
						elem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}, 50);
				}

			}

		});
	}
}
