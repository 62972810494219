import {Component, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
	selector: 'app-home-communities',
	templateUrl: './communities-section.component.html',
	styleUrls: ['./communities-section.component.scss']
})
export class CommunitiesSectionComponent implements OnInit {

	constructor(public globals: GlobalsService, public analyticsService: AnalyticsService) {
	}

	ngOnInit() {
	}

}
