<div class="image-cropper">
  <div class="close-x pointer">
    <img src="./assets/images/close-x.svg" (click)="closeDialog()">
  </div>

  <div class="image-section">
		<img class="image-cropped" [src]="croppedImage"
			 [height]="previewHeight"
			 [width]="previewWidth"
			 [style.border]="croppedImage ? '1px solid gray' : 'none'"/>

		<hr class="image-divider"/>

		<div class="image-cropper-section">
			<div class="edit-options">
				<button mat-button class="btn btn-primary option-btn"
						(click)="zoomIn()">
					<mat-icon  class="icon" matSuffix>zoom_in</mat-icon>
				</button>
				<button mat-button class="btn btn-primary option-btn"
						(click)="zoomOut()">
					<mat-icon>zoom_out</mat-icon>
				</button>
				<button mat-button class="btn btn-primary option-btn"
						(click)="rotateRight()">
					<mat-icon>crop_rotate</mat-icon>
				</button>
        <button mat-button class="btn btn-primary option-btn"
						(click)="flipHorizontal()">
					<mat-icon>flip</mat-icon>
				</button>
        <button mat-button class="btn btn-primary option-btn"
						(click)="flipVertical()">
					<mat-icon [style.transform]="'rotate(90deg)'">flip</mat-icon>
				</button>
			</div>

			<image-cropper
				[(imageChangedEvent)]="imageChangedEvent"
				[aspectRatio]="previewWidth / previewHeight"
				format="png"
				class="image-cropper"
				(imageCropped)="handleImageCropped($event)"
				[maintainAspectRatio]="false"
				[containWithinAspectRatio]="true"
				[canvasRotation]="canvasRotation"
				[(transform)]="transform"
			></image-cropper>
		</div>
	</div>

  <div class="image-cropper-actions">
    <button type="submit" class="btn bold"
      (click)="closeDialog()">
      CANCEL
    </button>

    <button type="submit" class="btn btn-blue bold"
      (click)="save()">
      SAVE
  </button>
</div>
</div>
