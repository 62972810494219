<div class="form-row" *ngIf="form">
    <mat-form-field class="full-width">
        <mat-chip-list #chipList>
            <mat-chip
                    *ngFor="let user of users; let indx=index"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(user,indx)"
                    class = "blue" >
                   <!-- [ngClass]="{'blue' : user?.isExisting, 'warn': !user?.isExisting}"-->

                {{ user?.fullName ?  user?.fullName : user}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
                    #userInput
                    [placeholder]="placeHolder"
                    aria-label="user"
                    (focus)="fun($event)"
                    matInput
                    [formControl]="form?.controls['userListName']"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)"
            />
        </mat-chip-list>
        <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selected($event)"
        >
            <mat-option
                    *ngFor="let user of (userOptions | async)?.items"
                    [value]="user"
            >
                <img class="small-image" [src]="user?.imageUrl"/>
                {{ user?.basicUserInformation?.firstName }}
                {{ user?.basicUserInformation?.lastName }}
            </mat-option>
        </mat-autocomplete>
        <mat-hint>{{hint ?? ''}}</mat-hint>

        <mat-error *ngIf="!form?.get('userListName')?.valid && form?.get('userListName')?.touched">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <div class="email-section" [formGroup]="invitationForm">
        <ng-container formArrayName="users">
            <ng-container *ngFor="let userForm of usersToInvite; let index = index">
                <div [formGroup]="userForm" class="email-form-control">
                    <mat-form-field appearance="fill">
                        <mat-label>Add {{userForm?.get('userName')?.value ?? 'Team Member'}} email</mat-label>
                        <input matInput formControlName="email" [value]="userForm.get('email')?.value">
                    </mat-form-field>
                    <button class="email-button"
                            [ngClass]="{'blue' : !emailButtonsStateArray[index], 'green': emailButtonsStateArray[index]}"
                            (click)="sendEmail(userForm, index)" type="button"
                            *ngIf="userForm?.get('email')?.valid && userForm?.get('email')?.touched">{{emailButtonsStateArray[index] ? 'Sent!' : 'Send'}}
                    </button>

                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
