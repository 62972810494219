<!-- Desktop Section -->
<div
        [ngClass]="globals.isDesktop ? 'desktop flex-box-center' : 'mobile'"
        #partner_container
>
    <div class="limit-width" style="position: relative">
        <div class="partners flex-box-center">
            <!-- <div *ngIf="globals.isMobile" class="tom-logo"></div> -->
            <div class="partners-title bold text-center">
                <span *ngIf="globals.isMobile">Our </span>Partners
            </div>

            <!-- <div *ngIf="globals.isMobile" class="partners-line"></div> -->

            <div class="body-text text-center">
                Visionary global partners have helped make TOM’s vision a
                reality.
            </div>

            <div
                    class="partners-logos"
                    [ngClass]="showAll ? 'container-open' : 'container-close'"
            >
                <div
                        class="logo-container"
                        *ngFor="let partner of partners | async"
                >
                    <img
                            class="logo pointer"
                            [src]="partner?.companyLogoUrl"
                            (click)="
							navigateToExternalUrl(partner?.companyWebsiteUrl)
						"
                    />
                </div>
            </div>

            <button
                    *ngIf="
					((partners | async)?.length > 5 && globals.isMobile) ||
					((partners | async)?.length > 5 && globals.isMobile)
				"
                    class="show-more"
                    (click)="
					showAll = !showAll;
					analyticsService.sendEvent('View all partners', 'SHOW MORE')
				"
            >
                <span *ngIf="showAll">Show Less</span>
                <span *ngIf="!showAll">All Partners</span>
            </button>
        </div>

    </div>
</div>
