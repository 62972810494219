import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Destroyable } from '../../../shared/destroyable';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';


@Component({
	selector: 'app-assembly-guide',
	templateUrl: './assembly-guide.component.html',
	styleUrls: ['./assembly-guide.component.scss']
})
export class AssemblyGuideComponent extends Destroyable implements OnInit {


	// steps form group
	assemblyStepsFormArray: FormArray = this.fb.array([]);
	assemblyGuideForm: FormGroup = this.fb.group({ 'assemblySteps': this.assemblyStepsFormArray });

	@Output() fieldChange = new EventEmitter<string>();

	chosenTools: string[] = [];
	existingFiles = [];


	constructor(private fb: FormBuilder, private documentationService: DocumentationSystemService) {
		super();
	}

	get steps() {
		const formArray = this.assemblyGuideForm?.controls['assemblySteps'] as FormArray;
		return formArray.controls as FormGroup[];
	}

	onIconClick($event, index) {
		$event.stopPropagation();
		this.removeStep(index);
	}

	get stepsArray() {
		return this.assemblyGuideForm?.controls['assemblySteps'] as FormArray;
	}

	ngOnInit(): void {
		this.initStepsArray();
		this.subscribeToFormGroup();
		this.subscribeToDocumentation();
		if (this.documentationService?.valueOfDocumentation?.assemblySteps.length === 0) {
			this.addNewStep();
		}
	}

	subscribeToDocumentation() {
		this.documentationService?.documentationAsObservable?.subscribe(documentation => {
			this.chosenTools = [...documentation?.tools];
		});

	}


	initStepsArray() {
		if (!!this.documentationService?.valueOfDocumentation?.assemblySteps) {
			this.documentationService?.valueOfDocumentation?.assemblySteps?.forEach((step, index) => {
				const stepId = uuidv4();
				const stepGroup = this.fb.group({
					'description': [step.description ?? ''],
					'tip': [step.tip ?? ''],
					'imageUrl': [step.imageUrl ?? null],
					'tools': [step.tools ?? []],
					'index': stepId
				});
				stepGroup.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(value => {
					const id = this.stepsArray.controls.findIndex(control => control.get('index').value === stepId);
					this.fieldChange.emit('step' + id?.toString());
				});
				this.stepsArray?.push(stepGroup);
				this.existingFiles.push(step?.imageUrl ?? null);
			});
		}
	}

	addNewStep() {
		const stepId = uuidv4();
		const newStep = this.fb.group({
			description: [''],
			tip: [''],
			imageUrl: [null],
			tools: [null],
			index: stepId
		});
		newStep.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(value => {
			const id = this.stepsArray.controls.findIndex(control => control.get('index').value === stepId);
			this.fieldChange.emit('step' + id?.toString());
		});

		this.stepsArray?.push(newStep);
	}

	removeStep(stepIndex: number) {
		this.stepsArray.removeAt(stepIndex);
	}

	subscribeToFormGroup() {
		this.assemblyGuideForm?.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.documentationService.valueOfDocumentation = {
				...this.documentationService.valueOfDocumentation,
				assemblySteps: value?.assemblySteps ?? []
			};
		});
	}


	onFileUploaded(filesUrlsArray: string[], i: number) {
		if (Array.isArray(filesUrlsArray) && filesUrlsArray?.length > 0) {
			this.stepsArray.controls[i].get('imageUrl').setValue(filesUrlsArray[0]);
		} else {
			this.stepsArray.controls[i].get('imageUrl').setValue(null);
		}
	}


	getExistingFileFromStep(index: number) {
		if (index > this.existingFiles?.length + 1 || !this.existingFiles[index]) {
			return [];
		} else {
			return Array.isArray(this.existingFiles[index]) ? this.existingFiles[index] : [this.existingFiles[index]];
		}
	}

	drop(event: CdkDragDrop<AbstractControl[]>): void {
		const formArray = this.stepsArray;
		moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex);
		moveItemInArray(formArray.value, event.previousIndex, event.currentIndex);
		this.stepsArray.setValue(formArray.value);
	}
}
