import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { basicToolsGroup, otherToolsGroup } from '../../../models/documentation';
import { AssemblyStep } from '../../../models/step';
import { ProjectMaterial } from '../../../models/projectMaterial';
import { SpecificationsSectionComponent } from '../specifications-section/specifications-section.component';

enum QuestionStatus {
	YES = 'yes',
	NO = 'no',
	UNKNOWN = 'unknown'
}

@Component({
	selector: 'app-prototype-section',
	templateUrl: './prototype-section.component.html',
	styleUrls: ['./prototype-section.component.scss']
})
export class PrototypeSectionComponent implements OnInit, OnDestroy {

	// subscriptions
	valuesChangeSubscription: Subscription;
	documentation$ = this.documentationService?.documentationAsObservable;

	assemblySteps: AssemblyStep[] = [];
	materials: ProjectMaterial[] = [];

	@ViewChild('specificationsSectionComponent') specificationsSectionComponent: SpecificationsSectionComponent;

	// forms
	basicToolsFormGroup = new FormGroup({});
	otherToolsFormGroup = new FormGroup({});
	basicToolsGroup = basicToolsGroup;
	otherToolsGroup = otherToolsGroup;
	toolsFormGroup = new FormGroup({ basicTools: this.basicToolsFormGroup, otherTools: this.otherToolsFormGroup });

	QuestionStatus = QuestionStatus;

	// component variables
	isToolsChange: QuestionStatus = this.QuestionStatus.UNKNOWN;
	isMaterialsChange: QuestionStatus = this.QuestionStatus.UNKNOWN;
	isAssemblyGuideChange: QuestionStatus = this.QuestionStatus.UNKNOWN;
	isInstructionsChange: QuestionStatus = this.QuestionStatus.UNKNOWN;
	isQuestionsAnswered: boolean = false;


	constructor(private documentationService: DocumentationSystemService) {
	}


	ngOnInit() {
		this.materials = this.documentationService?.valueOfDocumentation?.materials;
		this.assemblySteps = this.documentationService?.valueOfDocumentation?.assemblySteps;
		this.initFormGroup();
		this.subscribeToFormGroups();

		// init questions visibility
		this.initQuestionsVisibility();
	}


	get isValid(): boolean {
		console.log("prototype section validation is:" + this.specificationsSectionComponent?.isFormValid);
		return this.specificationsSectionComponent?.isFormValid;
	}

	initFormGroup() {
		// init forms
		this.basicToolsGroup.forEach((toolGroup, index) => {
			this.basicToolsFormGroup.addControl(index.toString(), new FormControl([]));
		});
		this.otherToolsGroup.forEach((toolGroup, index) => {
			this.otherToolsFormGroup.addControl(index.toString(), new FormControl([]));
		});

		// fill forms with initial data
		this.documentationService?.valueOfDocumentation?.tools?.forEach(tool => {
			this.basicToolsGroup.forEach((basicToolGroup, index) => {
				if (basicToolGroup?.name === tool) {
					this.basicToolsFormGroup.controls[index?.toString()].setValue([...this.basicToolsFormGroup?.controls[index.toString()].value, tool]);
				}
			});
			this.otherToolsGroup.forEach((otherToolGroup, index) => {
				if (otherToolGroup?.name === tool) {
					this.otherToolsFormGroup.controls[index?.toString()].setValue([...this.otherToolsFormGroup?.controls[index.toString()].value, tool]);
				}
			});
		});
	}

	subscribeToFormGroups() {
		this.valuesChangeSubscription = this.toolsFormGroup?.valueChanges?.subscribe(value => {
			let tools = [];
			Object.keys(value?.basicTools).forEach(key => {
				tools = [...tools, ...value.basicTools[key]];
			});
			Object.keys(value?.otherTools).forEach(key => {
				tools = [...tools, ...value.otherTools[key]];
			});
			this.documentationService.valueOfDocumentation = {
				...this.documentationService.valueOfDocumentation,
				tools: tools
			};
		});
	}

	initQuestionsVisibility() {
		this.isToolsChange = QuestionStatus.UNKNOWN;
		this.isMaterialsChange = QuestionStatus.UNKNOWN;
		this.isAssemblyGuideChange = QuestionStatus.UNKNOWN;
		this.isInstructionsChange = QuestionStatus.UNKNOWN;
		if (!this.checkMaterialsValidityForPrototype()) {
			this.isMaterialsChange = QuestionStatus.YES;
		}
	}

	ngOnDestroy(): void {
		this.valuesChangeSubscription.unsubscribe();
	}

	onChangeAssociated(value: QuestionStatus, item: string) {
		if (item === 'tools') {
			this.isToolsChange = value;
		}
		if (item === 'materials') {
			this.isMaterialsChange = value;
		}
		if (item === 'assembly') {
			this.isAssemblyGuideChange = value;
		}
		if (item === 'instruction') {
			this.isInstructionsChange = value;
		}
		this.isQuestionsAnswered = (
			this.isToolsChange !== QuestionStatus.UNKNOWN &&
			this.isMaterialsChange !== QuestionStatus.UNKNOWN &&
			this.isAssemblyGuideChange !== QuestionStatus.UNKNOWN &&
			this.isInstructionsChange !== QuestionStatus.UNKNOWN);
	}

	fieldChange(field: string) {
		this.documentationService.valueOfEditField = field;
	}

	// check materials validity
	checkMaterialsValidityForPrototype() {
		if (!!this.documentationService.valueOfDocumentation.materials?.length) {
			for (let i = 0; i < this.documentationService.valueOfDocumentation.materials?.length; i++) {
				if (!this.documentationService.valueOfDocumentation.materials[i].material
					|| !this.documentationService.valueOfDocumentation.materials[i].qty
					|| !this.documentationService.valueOfDocumentation.materials[i].dimensions
					|| !this.documentationService.valueOfDocumentation.materials[i].unit
					|| !this.documentationService.valueOfDocumentation.materials[i].itemDescription) {
					return false;
				}
			}
		}
		return true;
	}
}
