import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {EventService} from 'src/app/services/event.service';
import {EventModel} from '../../models/event';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {SlickCarouselComponent} from 'ngx-slick-carousel';

@Component({
	selector: 'app-events-section',
	templateUrl: './events-section.component.html',
	styleUrls: ['./events-section.component.scss'],
})
export class EventsSectionComponent implements OnInit {
	events: EventModel[] = [];
	@Input() inHomePage: boolean = false;
	eventsArray = [1, 1, 1, 1];
	@ViewChild('slickModal') slickModal: SlickCarouselComponent;

	slideConfig = {
		speed: 500,
		slidesToShow: 4,
		padding: 10,
		slidesToScroll: 4,
		prevArrow: false,
		nextArrow: false,
		useCSS: true,
		variableWidth: true,
		responsive: [
			{
				breakpoint: 1250,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					// centerMode: true,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					dots: true,
					infinite: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					infinite: true,
					autoplay: true,
					autoplaySpeed: 2000,
				},
			},
		],
	};

	next() {
		this.slickModal.slickNext();
	}

	prev() {
		this.slickModal.slickPrev();
	}

	constructor(
		public globals: GlobalsService,
		private eventService: EventService,
		public analyticsService: AnalyticsService
	) {
	}

	ngOnInit() {
		this.getFutureEvents();
	}

	public getFutureEvents(): any {
		this.eventService.futureForHome().subscribe(
			(resp) => {
				resp.items.forEach((item) => {
					this.events.push(Object.assign(new EventModel(), item));
				});
			},
			(err) => {
			}
		);
	}
}
