import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class HttpService {
	private baseUrl = environment['url'];
	public headers = new HttpHeaders();

	constructor(public http: HttpClient) {
	}

	public get(url: string, params: {} = undefined): Observable<any> {
		return this.http.get(this.baseUrl + url, {params: params, headers: this.headers});
	}

	public delete(url: string, params: {} = undefined): Observable<any> {
		return this.http.delete(this.baseUrl + url, {params: params, headers: this.headers});
	}

	public put(url: string, body: any): Observable<any> {
		return this.http.put(this.baseUrl + url, body, {headers: this.headers});
	}

	public putWithBuffer(url: string, body: any): Observable<any> {
		return this.http.put(this.baseUrl + url, body, {headers: this.headers, responseType: 'arraybuffer'});
	}

	public post(url: string, body: any): Observable<any> {
		return this.http.post(this.baseUrl + url, body, {headers: this.headers});
	}
}
