import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Comment} from '../../models/project';

@Component({
	selector: 'app-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
	array = [1, 1, 1];
	@Input() comments: Comment[];
	@Input() projectId: string;
	@Output() commentSucceed = new EventEmitter();


	constructor(public globals: GlobalsService) {
	}

	ngOnInit() {
	}

	public commentAdded(newComment: Comment) {
		this.commentSucceed.emit(newComment);
	}
}
