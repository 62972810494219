<!-- Mobile View -->
<div *ngIf="globals.isMobile" class="mobile">
    <div
            class="event"
            (click)="eventClick()"
            [ngStyle]="{
			background: event.imageUrl
				? 'url(' + event.imageUrl + ')'
				: 'url(./assets/images/rectangle-default.svg)',
			'background-size': 'cover'
		}"
    >
        <div class="gradiant"></div>
        <div class="event-date bold">
			<span class="date-head" *ngIf="eventStartMonth !== eventEndMonth"
            >{{ eventStartMonth }} - {{ eventEndMonth + " " }}</span
            >
            <span
                    class="date-head"
                    *ngIf="eventStartMonth === eventEndMonth && !isSameDay"
            >{{ eventStartMonth + " " }}</span
            >
            <span class="date-head" *ngIf="isSameDay">{{
                eventDatesRange + " "
                }}</span>
            <span class="date-dates">{{ datesRangeNumbers }}</span>
        </div>
        <div class="event-details">
            <div class="event-location bold">
                <!-- <span *ngIf="event&&event.address&&event.address.length>=34; else fullText">
                    {{event?.address|slice:0:34}}...

                </span> -->
                <span>
					{{
                    event.countryId.name === "usa"
                        ? (event.countryId.name | uppercase)
                        : (event.countryId.name | titlecase)
                    }}
				</span>
                <!-- <ng-template #fullText>
                    {{event?.address}}
                </ng-template> -->
            </div>
            <div class="event-title bold">
                {{ event.name }}
            </div>
        </div>
    </div>
</div>
<!-- END Mobile View -->

<!-- Desktop View -->
<div *ngIf="globals.isDesktop" class="desktop">
    <div (click)="eventClick()" class="event">
        <ng-container
                class="container"
                *ngIf="event.coverUrl; else defaultCover"
        >
            <div
                    class="event-image"
                    [ngStyle]="{
					background: 'url(' + event.imageUrl + ')',
					'background-size': 'cover'
				}"
            >
                <!-- <div *ngIf="isMobile" class="gradiant"> -->
                <div class="date-box">
                    <div class="date" *ngIf="eventStartMonth !== eventEndMonth">
                        {{ eventStartMonth }}-{{ eventEndMonth }}
                        {{ datesRangeNumbers }}
                    </div>
                    <div
                            class="date-head"
                            *ngIf="eventStartMonth === eventEndMonth && !isSameDay"
                    >
                        {{ eventStartMonth }} {{ datesRangeNumbers }}
                    </div>
                    <div class="date-head" *ngIf="isSameDay">
                        {{ eventDatesRange }} {{ datesRangeNumbers }}
                    </div>
                </div>
                <!-- </div> -->
            </div>
        </ng-container>
        <ng-template #defaultCover>
            <div
                    class="event-image"
                    [ngStyle]="{
					background: 'url(./assets/images/rectangle-default.svg)',
					'background-size': 'cover'
				}"
            ></div>
        </ng-template>
        <div class="event-body">
            <!-- <div class="event-time">
                    {{eventDatesRangeDesktop}}
                </div> -->
            <div class="event-location">
                {{
                event.countryId.name === "usa"
                    ? (event.countryId.name | uppercase)
                    : (event.countryId.name | titlecase)
                }}
            </div>
            <div class="event-title bold">
                {{ event.name }}
            </div>
            <div class="event-description">
                {{ shortDescription }}
            </div>
            <!-- <button class="view-event-button text-center" (click)="eventClick()">View Event</button> -->
        </div>
    </div>
</div>
<!-- END Desktop View -->
