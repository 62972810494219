import {User} from './user';
import {Country} from './country';

class Partner {
	title: string = '';
	companyLogoUrl: string = '';
	companyWebsiteUrl: string = '';
	isPopular: boolean = false;
}



export class SocialMediaInformation {
	status: string = '';
	logoUrl: string = '';
	coverUrl: string = '';
	facebookUrl: string = '';
	instagramUrl: string = '';
	twitterUrl: string = '';
	linkedInUrl: string = '';
	donateUrl: string = '';
	email: string = '';
	pinterest: string = '';
	communityEmail: string = '';
	aboutTheCommunity: string = '';
}

export class Community {
	_id: string;
	socialMediaInformation: SocialMediaInformation = new SocialMediaInformation();
	communityType: any = '';
	partners: Partner[] = [];
	name: string = '';
	yearEstablished: string = '';
	leadershipTeam?: User[];
	countryId: string | Country = '';
	country: Country;
	city: string = '';
	membersCount?: number = 0;
	communityManager: User[] = [new User()];
	regionalManager?: User;
	location?: { coordinates: number[] };
	pinToTop?: Date;
}

export class CategoryType {
	_id?: string;
	typeName?: string;
	description?: string;
	imageUrl?: string;
	typeManager?: string | User;
	typeManagerShortBiography?: string;
}
