<div class="item-section">
    <div class="top-container">
        <div class="top-container-title">
            {{title}}
        </div>
        <div class="top-container-right">
            <div class="show-all">
                ({{ amount }})
            </div>
            <div class="button-container">
                <button class="btn-prev" (click)="prev()">
                    <svg
                            width="9"
                            height="12"
                            viewBox="0 0 9 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                                d="M1.16598 6.11682C1.16493 6.2985 1.19976 6.47861 1.26846 6.6468C1.33716 6.815 1.43838 6.96799 1.56633 7.09698L5.70789 11.2385C5.96784 11.4985 6.32042 11.6445 6.68805 11.6445C7.05569 11.6445 7.40826 11.4985 7.66822 11.2385C7.92818 10.9786 8.07422 10.626 8.07422 10.2584C8.07422 9.89073 7.92818 9.53816 7.66822 9.2782L4.49303 6.11682L7.66822 2.95543C7.92818 2.69548 8.07422 2.3429 8.07422 1.97527C8.07422 1.60763 7.92818 1.25505 7.66822 0.995098C7.40826 0.735142 7.05569 0.589101 6.68805 0.589101C6.32042 0.589101 5.96784 0.735142 5.70789 0.995098L1.56633 5.13665C1.43838 5.26564 1.33716 5.41863 1.26846 5.58683C1.19976 5.75502 1.16493 5.93513 1.16598 6.11682Z"
                                fill="#4D4D4D"
                                stroke="#F7F7F7"
                                stroke-width="0.523144"
                        />
                    </svg>
                </button>
                <button class="btn-next" (click)="next()">
                    <svg
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                                d="M7.68875 6.11669C7.6898 6.29838 7.65498 6.47848 7.58628 6.64668C7.51758 6.81488 7.41635 6.96786 7.2884 7.09686L3.14685 11.2384C2.88689 11.4984 2.53432 11.6444 2.16668 11.6444C1.79905 11.6444 1.44647 11.4984 1.18652 11.2384C0.926559 10.9785 0.780518 10.6259 0.780518 10.2582C0.780518 9.89061 0.926559 9.53803 1.18652 9.27808L4.36171 6.11669L1.18652 2.95531C0.926559 2.69535 0.780517 2.34278 0.780517 1.97514C0.780517 1.60751 0.926559 1.25493 1.18652 0.994976C1.44647 0.73502 1.79905 0.588979 2.16668 0.588979C2.53432 0.588979 2.88689 0.73502 3.14685 0.994976L7.2884 5.13653C7.41635 5.26552 7.51758 5.41851 7.58628 5.5867C7.65498 5.7549 7.6898 5.93501 7.68875 6.11669Z"
                                fill="#4D4D4D"
                                stroke="#F7F7F7"
                                stroke-width="0.523144"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <div class="carousel-container ">
        <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfig"
        >
            <div
                    style="{'margin-left': 0}"
                    ngxSlickItem
                    class="item-container"
                    *ngFor="let item of items"
            >
                <app-item *ngIf="!item?.type"
                          [showType]="false"
                          [item]="item"
                          (selectItemAction)="itemClicked($event)"
                >
                </app-item>
                <app-project *ngIf="item?.type"
                             [showType]="true"
                             [project]="item"
                             (selectProjectAction)="itemClicked($event)">
                </app-project>

            </div>
        </ngx-slick-carousel>
    </div>
</div>
