export class User {
	_id?: string = null;
	facebookId?: string;
	basicUserInformation: BasicUserInformation;
	address: string = '';
	location?: { coordinates: number[] };
	zipCode: string = '';
	state: string = '';
	dateOfBirth: Date;
	skills: string = '';
	imageUrl: string = '';
	howDidYouHearAboutTom: string = '';
	tellUsAboutYourself: string = '';
	memberTypes: string;
	otherMemberTypeUserInput: string;
	permissionsTypes: string;

	constructor() {
		this.basicUserInformation = new BasicUserInformation();
	}
}

export class BasicUserInformation {
	firstName: string = '';
	lastName: string = '';
	email: string;
	countryId: string = '';
	city: string = '';
	profession: string = '';
	community: string = '';
	phone?: string = '';
	areaCode?: string = '';
	password: string;
	passwordConfirmation: string;
}
