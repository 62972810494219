import {Injectable} from '@angular/core';
import {HttpService} from './http.service';

@Injectable({
	providedIn: 'root'
})
export class GeneralService extends HttpService {


	public getCountries() {
		const url = '/country';

		return this.get(url);
	}

	public getPopularPartners() {
		return this.get('/partner/popular');
	}
}
