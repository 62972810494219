import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {fadeAnimation} from 'src/app/animations/fade.animation';
import {MatDialog} from '@angular/material/dialog';
import {VideoPopupComponent} from '../video-popup/video-popup.component';
import {DataService} from 'src/app/services/data.service';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
	selector: 'app-welcome-section',
	animations: [fadeAnimation],
	templateUrl: './welcome-section.component.html',
	styleUrls: ['./welcome-section.component.scss'],
	encapsulation: ViewEncapsulation.None

})
export class WelcomeSectionComponent implements OnInit {
	state = 'out';
	imageSourcesDesktop = ['./assets/images/welcome-1.png', './assets/images/welcome-2.png', './assets/images/welcome-3.png'];
	imageSourcesMobile = ['./assets/images/welcome-m-1.png', './assets/images/welcome-m-2.png', './assets/images/welcome-m-3.png'];
	imageSources = [];
	currentImageIndex = 0;
	currentImage = '';

	constructor(public globals: GlobalsService, private dialog: MatDialog, public dataService: DataService, public analyticsService: AnalyticsService) {
	}

	ngOnInit() {
		this.imageSources = this.globals.isDesktop ? this.imageSourcesDesktop : this.imageSourcesMobile;
	}

	dissolveNextImage() {
		if (this.state === 'out') {
			this.state = 'in';
		} else {
			this.state = 'out';
		}
		this.currentImage = this.imageSources[this.currentImageIndex];
		if (this.currentImageIndex + 1 === this.imageSources.length) {
			this.currentImageIndex = 0;
		} else {
			++this.currentImageIndex;
		}
	}

	onVideoClicked() {
		this.analyticsService.sendEvent('Watch Video', 'Watch Video');
		const width = this.globals.isDesktop ? '900px' : '90%';
		const height = this.globals.isDesktop ? '500px' : '';
		const dialogRef = this.dialog.open(VideoPopupComponent, {
			data: {},
			width,
			height,
			hasBackdrop: true,
			panelClass: 'remove-video-padding'
		});
	}
}
