import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { Subscription } from 'rxjs';
import { FileUploadOptions } from '../../upload-image-field/upload-file-field.component';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../shared/destroyable';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'app-special-instructions',
	templateUrl: './special-instructions.component.html',
	styleUrls: ['./special-instructions.component.scss']
})
export class SpecialInstructionsComponent extends Destroyable implements OnInit {
	specialInstructionsFormArray: FormArray = this.fb.array([]);
	specialInstructionsForm: FormGroup = this.fb.group({ 'specialInstructions': this.specialInstructionsFormArray });
	@Output() fieldChange = new EventEmitter<string>();
	valuesChangeSubscription: Subscription;
	existingFiles = [];


	optionsForUpload: FileUploadOptions = {
		formats: ['image/*'],
		isMultiple: false,
		title: 'Add Instruction image'
	};


	constructor(private fb: FormBuilder, private documentationService: DocumentationSystemService) {
		super();
	}

	get instructions() {
		const formArray = this.specialInstructionsForm?.controls['specialInstructions'] as FormArray;
		return formArray.controls as FormGroup[];
	}

	get specialInstructionsArray() {
		return this.specialInstructionsForm?.controls['specialInstructions'] as FormArray;
	}

	ngOnInit(): void {
		this.InitForm();
		this.subscribeToFormGroup();
		if (this.documentationService?.valueOfDocumentation?.specialInstructions.length === 0) {
			this.addNewStep();
		}
	}


	InitForm() {
		if (!!this.documentationService?.valueOfDocumentation?.specialInstructions) {
			this.documentationService?.valueOfDocumentation?.specialInstructions?.forEach((instruction, index) => {
				const instructionId = uuidv4();
				const stepGroup = this.fb.group({
					'description': [instruction.description ?? ''],
					'imageUrl': [instruction.imageUrl ?? null],
					'index': instructionId
				});
				stepGroup.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(value => {
					const stepId = this.specialInstructionsArray.controls.findIndex(control => control.get('index').value === instructionId);
					this.fieldChange.emit('instruction' + stepId.toString());
				});
				this.specialInstructionsArray?.push(stepGroup);
				this.existingFiles.push(instruction?.imageUrl ?? null);
			});
		}
	}


	onIconClick($event, index) {
		$event.stopPropagation();
		this.removeStep(index);
	}


	addNewStep() {
		const instructionId = uuidv4();
		const newInstruction = this.fb.group({
			description: [''],
			imageUrl: [null],
			'index': instructionId
		});
		newInstruction.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(value => {
			const stepId = this.specialInstructionsArray.controls.findIndex(control => control.get('index').value === instructionId);
			this.fieldChange.emit('instruction' + stepId.toString());
		});
		this.specialInstructionsArray?.push(newInstruction);
	}

	removeStep(stepIndex: number) {
		this.specialInstructionsArray.removeAt(stepIndex);
	}


	subscribeToFormGroup() {
		this.valuesChangeSubscription = this.specialInstructionsForm?.valueChanges?.subscribe(value => {
			this.documentationService.valueOfDocumentation = {
				...this.documentationService.valueOfDocumentation,
				specialInstructions: value?.specialInstructions
			};
		});
	}


	onFileUploaded(filesUrlsArray: string[], i: number) {
		// delete
		if (Array.isArray(filesUrlsArray) && filesUrlsArray?.length > 0) {
			this.specialInstructionsArray.controls[i].get('imageUrl').setValue(filesUrlsArray[0]);
		} else {
			this.specialInstructionsArray.controls[i].get('imageUrl').setValue(null);
		}
	}

	getExistingFileFromInstruction(index: number) {
		if (index > this.existingFiles?.length + 1 || !this.existingFiles[index]) {
			return [];
		} else {
			return Array.isArray(this.existingFiles[index]) ? this.existingFiles[index] : [this.existingFiles[index]];
		}
	}

	drop(event: CdkDragDrop<AbstractControl[]>): void {
		const formArray = this.specialInstructionsArray;
		moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex);
		moveItemInArray(formArray.value, event.previousIndex, event.currentIndex);
		this.specialInstructionsArray.setValue(formArray.value);
		console.log(formArray.value);
	}

}
