<div class="photos-videos-container">
    <span class="B2-dark-text" *ngIf="!fileInput">Images: </span>
    <div *ngFor=" let mediaInput of (fileInput? fileInput: mediaInputs); let i = index" class="upload-wrapper"
         [ngStyle]="{'padding-bottom': ((fileInput? fileInput: mediaInputs)?.length > 1 && i !== (fileInput? fileInput: mediaInputs)?.length -1) ? '40px' : '0px'}">
        <span class="B2-dark-text">{{mediaInput?.title}}
            <img *ngIf="mediaInput?.tooltip" src="./assets/images/info-icon/info-icon-grey.png" class="info-icon"
                 tippy={{mediaInput?.tooltip}} variation="documentation" />
        </span>
        <app-upload-file-field
                (FilesToUpload)="handleFilesToUpload($event, i ,mediaInput?.options?.fieldName)"
                [existingFiles]="getExistingFiles(mediaInput)"
                [options]="mediaInput?.options">
        </app-upload-file-field>
    </div>
</div>
