import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {ProjectTypes} from '../../models/enums';

@Component({
	selector: 'app-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {
	@Input() item: any;
	@Input() showType: boolean;
	@Output() selectItemAction = new EventEmitter();
	// @ViewChild('projectImg', { static: true }) projectImg: ElementRef;

	assetPath: string = './assets/images/prototype.svg';
	backColor: string = '#88c54f';
	typeString: string = '';
	cardPhoto: string = './assets/images/rectangle-default.svg';
	details: string = '';
	defaultImage: string = './assets/images/rectangle-default.svg';

	constructor(public globals: GlobalsService) {
	}

	ngOnInit() {
		this.details = this.item?.details?.length > 55 ? this.item?.details?.substring(0, 55) + ' ...' : this?.item?.details;
	}

	public itemClick() {
		this.selectItemAction?.emit(this.item);
	}
}
