import {Community} from './community';
import {User} from './user';
import {Sponsor} from './sponsor';

export class AdditionalInformation {
	constructor() {
	}
	disabledPersonDetails: string = '';
	challengeDetails: string = '';
	teamRequirements: string = '';
	challengeImage: string = '';
	teamName: string = '';
}

export class Project {
	_id?: string;
	additionalInformation: AdditionalInformation = new AdditionalInformation();
	categories: any[] = [];
	comments: Comment[] = [];
	communityId: string | Community = null;
	relatedCommunityMembers: any[] = [];
	imagesUrls: string[];
	members: (string | User)[];
	name: string;
	names: string[] = [];
	tags: any[] = [];
	type: number = 1;
	isPopular: boolean = false;
	relatedEventId: any = null;
	resources: string = '';
	downloadLink?: string = null;
	downloadCount: number = 0;
	description: string = '';
	projectName: string = '';
	challengeName: string = '';
	relatedProjects: RelatedProjects = null;
	thumbnailImageUrl?: string = '';
	sponsors?: Sponsor[] = [];
	pinToTop?: Date = null;
	pinToType?: Date = null;
	pinToCountry?: Date = null;
	status?: string = 'hidden';
	eligible?: boolean = false;
	editable?: boolean = false;
	documentationId?: string = null;
	requestedToBeProduct?: boolean;
}

export class RelatedProjects {
	projects?: any[];
}

export class Comment {
	user?: User & string;
	text?: string;
	image?: string;
}
