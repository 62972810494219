<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'">
    <div class="no-user-comment" *ngIf="!user; else show">
        <div class="button-container">
            <button class="button-add-comment bold" (click)="logintToComment()">
                Please Login to Comment
            </button>
        </div>
    </div>
    <ng-template #show>
        <div class="new-comment">
            <div class="user">
                <div
                        class="user-photo round"
                        [ngStyle]="{
						'background-image': user.imageUrl
							? 'url(' + user.imageUrl + ')'
							: 'url(./assets/images/square-default.svg)'
					}"
                >
					<span
                            class="round flag-icon + flag-icon-{{
							user.basicUserInformation.countryId.alpha2Code
								| lowercase
						}} flag-icon-squared"
                    ></span>
                </div>
            </div>
            <div class="form">
                <mat-form-field>
					<textarea
                            maxlength="400"
                            matInput
                            placeholder="Add a comment…"
                            [formControl]="commentFormControl"
                    ></textarea>
                    <mat-error
                            *ngIf="
							commentFormControl.invalid &&
							!this.img &&
							addClicked
						"
                    >Comment or Image is required
                    </mat-error>
                </mat-form-field>
                <div class="row">
                    <div
                            *ngIf="globals.isDesktop || (!globals.isDesktop && img)"
                            class="c-img"
                    >
                        <img *ngIf="img" [src]="img"/>
                        <img
                                *ngIf="img"
                                class="close-img"
                                src="../../../../assets/images/close.svg"
                                (click)="removeImage()"
                        />
                    </div>
                    <div class="buttons-container">
                        <div class="button-photo bold" (click)="upload.click()">
                            <img src="./assets/images/camera.svg"/>
                            <div style="padding-top: 5px; user-select: none">
                                Photo
                            </div>
                        </div>
                        <input
                                type="file"
                                accept="image/*"
                                name="upload"
                                (change)="uploadPhoto($event)"
                                id="upload"
                                style="display: none"
                                #upload
                        />
                        <button
                                class="button-add-comment bold"
                                (click)="validateComment()"
                        >
                            Add Comment
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
