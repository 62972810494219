<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'" id="countries">
    <div class="country-page">
        <ng-container *ngIf="country?.imageUrl; else defaultCover">
            <div
                    class="country-photo"
                    [ngStyle]="{
					'background-image': 'url(' + country?.imageUrl + ')'
				}"

            >
                <div class="gradiant"></div>
            </div>
        </ng-container>
        <ng-template #defaultCover>
            <div
                    class="country-photo"
                    [ngStyle]="{
					'background-image':
						'url(./assets/images/rectangle-default.svg)'
				}"
            >
                <div class="gradiant"></div>
            </div>
        </ng-template>
        <div class="limit-width">
            <div class="country-header">
                <div class="header-row">
                    <div class="country-photo-container">
<!--                        <img-->
<!--                                class="country-photo round"-->
<!--                                [src]=" countryFlag ||-->
<!--								'./assets/images/square-default.svg'-->
<!--							"-->
<!--                        />-->
                        <span *ngIf="country?.alpha2Code.toLowerCase()"
                                class="country-photo round flag-icon-{{
							country?.alpha2Code.toLowerCase()
						}} "
                        ></span>
                        <span *ngIf="country?.name?.toLowerCase() === 'tom global'"
                              class="country-photo round "
                              style="
                              background-image: url('../../../assets/images/TOM-Global.svg');
                              background-position: center;
                              background-size: contain;">
                        </span>
                    </div>
                    <div class="country-details">
                        <div class="country-name bold">
                            {{
                            country?.name === "usa"
                                ? (country?.name | uppercase)
                                : (country?.name | titlecase)
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-limit" *ngIf="globals.isMobile"
             style="background-color: rgb(247, 247, 247)">
            <div id="page-body" class="page-body">
                <div class="about-section">
                    <!-- About the community -->
                    <div class="page-section">
                        <div class="page-section-title bold">About TOM:{{
                            country?.name === "usa"
                            ? (country?.name | uppercase)
                            : (country?.name | titlecase)
                            }}
                        </div>
                        <div id="aboutTheCommunity" class="about-the-country"></div>

                        <p class="text-body light">
                            {{
                            country?.basicInfo || "..."
                            }}
                        </p>
                    </div>
                    <div class="border"></div>

                    <!--country Manager -->
                    <ng-container *ngIf="country?.countryManager">
                        <div class="page-section">
                            <div class="page-section-title bold">
                                {{country?.name?.charAt(0)?.toUpperCase() + country?.name?.slice(1)}} Manager
                            </div>
                            <div class="mb-15">
                                <app-member-info
                                        [showMailOnMobile]="true"
                                        [member]="country?.countryManager"
                                ></app-member-info>
                            </div>
                            <div class="about text-body-bold">About</div>
                            <p class="text-body light wrap-content">
                                {{ country?.countryManagerShortBiography || "..." }}
                            </p>
                        </div>
                        <div class="border"></div>
                    </ng-container>
                </div>
                <div class="items-section">
                    <div *ngIf="communitiesItems?.length > 0" class="section">
                        <div class="wrapper">
                            <div
                                    class="container"
                                    *ngFor="let community of communitiesItems | slice:0:3"
                            >
                                <app-item
                                        [item]="community"
                                        (selectItemAction)="
										itemClicked(community, 'community')
									"
                                >
                                </app-item>
                            </div>
                        </div>
                        <button
                                *ngIf="communitiesItems.length > 0"
                                class="btn-text-blue mt-20"
                                (click)="
								toggle(
								)
							"
                        >
                            All Communities
                        </button>
                    </div>
                    <div *ngIf="eventsItems?.length > 0" class="section">
                        <div class="wrapper">
                            <div
                                    class="container"
                                    *ngFor="let event of eventsItems | slice:0:3"
                            >
                                <app-item
                                        [item]="event"
                                        (selectItemAction)="
										itemClicked(event, 'event')
									"
                                >
                                </app-item>
                            </div>
                        </div>
                        <button
                                *ngIf="eventsItems.length > 0"
                                class="btn-text-blue mt-20"
                                (click)="
								toggle(
								)
							"
                        >
                            All Events
                        </button>
                    </div>
                    <div *ngIf="projectsItems?.length > 0" class="section">
                        <div class="wrapper">
                            <div
                                    class="container"
                                    *ngFor="let project of projectsItems | slice:0:3"
                            >
                                <app-project
                                        [showType]="true"
                                        [project]="project"
                                        (selectProjectAction)="
										itemClicked(project, 'project')
									"
                                >
                                </app-project>
                            </div>
                        </div>
                        <button
                                *ngIf="projectsItems.length > 0"
                                class="btn-text-blue mt-20"
                                (click)="toggle()">
                            All Projects
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div class="page-limit" *ngIf="globals.isDesktop">
            <div class="country-info">
                <div class="about-country">
                    <div class="title" *ngIf="globals.isDesktop">
                        About TOM:{{ country?.name === "usa"
                        ? (country?.name | uppercase)
                        : (country?.name | titlecase)}}
                    </div>
                    <div class="about-content">
                        {{country?.basicInfo}}
                    </div>
                </div>
                <ng-container *ngIf="country?.countryManager">
                    <div class="about-manager">
                        <ng-container
                                *ngIf="country?.countryManager">
                            <div class="about-manager-title bold manger-title">
                                {{country?.name?.charAt(0)?.toUpperCase() + country?.name?.slice(1)}} Manager
                            </div>
                            <div class="member-width">
                                <div class="mb-20">
                                    <app-member-info
                                            [emailHover]="true"
                                            [member]="country?.countryManager"
                                    ></app-member-info>
                                </div>
                            </div>

                            <div class="about bold">About</div>
                            <p class="text-body long-text wrap-content">
                                {{
                                country?.countryManagerShortBiography ||
                                "..."
                                }}
                            </p>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <section class="items-section">
                <app-items-slider
                        *ngIf="communitiesItems?.length"
                        [items]="communitiesItems"
                        [amount]="communitiesItems?.length"
                        [title]="'Communities'"
                        [slideRows]="communitiesItems.length <= 4 ? 1 : 2"
                        [type]="'community'"
                >
                </app-items-slider>
            </section>
            <section class="items-section">
                <app-items-slider
                        *ngIf="eventsItems?.length !== 0"
                        [items]="eventsItems"
                        [amount]="eventsItems?.length"
                        [title]="'Events'"
                        [slideRows]="1"
                        [type]="'event'"
                >
                </app-items-slider>
            </section>
            <section class="items-section">
                <app-items-slider
                        *ngIf="projectsItems?.length !== 0"
                        [items]="projectsItems"
                        [amount]="projectsItems?.length"
                        [title]="'Projects'"
                        [slideRows]="1"
                        [type]="'project'"
                >
                </app-items-slider>
            </section>

            <div class="page-section" style="padding-bottom: 50px" *ngIf="!!pressList?.length">
                <div class="press-title">
                    Press
                </div>
                <div class="press-container">
                    <div class="item" *ngFor="let press of pressList">
                        <app-press [press]="press"></app-press>
                    </div>
                </div>
                <button
                        class="btn-text-blue m-a"
                        *ngIf="globals.isDesktop && pressList?.length == 4"
                        (click)="showAllCommunityPress()"
                >
                    Show All
                </button>
                <button
                        class="btn-text-blue mt-5 m-a"
                        *ngIf="globals.isDesktop && pressList?.length > 4"
                        (click)="showLessCommunityPress()"
                >
                    Show Less
                </button>
            </div>
        </div>
    </div>

</div>

<!-- Desktop Section -->
