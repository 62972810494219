import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, } from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../models/user';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipList} from '@angular/material/chips';
import {UserService} from '../../services/user.service';
import {take, takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {Destroyable} from '../../shared/destroyable';


@Component({
	selector: 'app-user-autocomplete-checkbox',
	templateUrl: './user-autocomplete-checkbox.component.html',
	styleUrls: ['./user-autocomplete-checkbox.component.scss'],
})
export class UserAutocompleteCheckboxComponent
	extends Destroyable implements OnInit, AfterViewInit, OnDestroy {
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = false;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	users: any = [];
	emailButtonsStateArray: boolean[] = [];
	invitationForm = this.fb.group({
		users: this.fb.array([])
	});

	@ViewChild(MatAutocompleteTrigger)
	trigger: MatAutocompleteTrigger;

	@Input() startList: [];
	@Input() control: FormControl;
	@Input() placeHolder: string;
	@Input() hint: string;
	@Input() errorText: string;
	@Input() projectName: string | null;
	@Output() fieldChange?: EventEmitter<string> = new EventEmitter<string>();


	_required: boolean = false;
	@Input('required')
	set required(value: boolean) {
		this._required = value;
		if (value && this?.form?.controls?.userListName) {
			this.form.controls.userListName.setValidators([this.customTeamMembersValidator().bind(this)]);
		} else if (!value && this?.form?.controls?.userListName) {
			this.form.controls.userListName.setValidators([]);
		}
	}

	subscription: Subscription;
	public userOptions: Observable<{ items: User[] }>;
	public form: FormGroup;
	@ViewChild('userInput') userInput: ElementRef;
	@ViewChild('chipList') chipList: MatChipList;

	constructor(
		private userService: UserService,
		private fb: FormBuilder,
		public dataService: DataService
	) {
		super();
	}

	ngAfterViewInit() {
		this._subscribeToClosingActions();
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}


	ngOnInit() {
		this.subscribeToUser();

		this.form = this.fb.group({
			userListName: [null, [this.customTeamMembersValidator().bind(this)]],
		});

		this.form.get('userListName').statusChanges.subscribe(
			status => this.chipList.errorState = status === 'INVALID'
		);

		this.form.controls?.userListName?.valueChanges?.subscribe((inputValue) => {
			this.userOptions = this.getFilteredUsers(inputValue);
			this.fieldChange.emit();
		});
	}


	addUserInvite(userName: string) {
		const userForm = this.fb.group({
			email: ['', [
				Validators.required,
				Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
			userName: [userName, []],
		});
		this.emailButtonsStateArray.push(false);
		this.usersToInvite.push(userForm);
	}

	sendEmail(emailForm: FormGroup, index) {
		if (!this.emailButtonsStateArray[index]) {
			const email = emailForm.get('email').value ?? null;
			const fullName = emailForm.get('userName').value ?? '';
			if (!!email) {
				this.userService.sendInvitationEmail(email, this.projectName ? this.projectName : '').pipe(take(1)).subscribe(response => {
					this.emailButtonsStateArray[index] = true;
					this.users = this.users.filter(user => user.fullName !== fullName);
				});
			}
		}

	}

	getErrorMessage() {
		/*if (this.users?.some(user => user?.isExisting === false)) {
			console.log('in getErrorMessage: ', this.users);

			return 'Team member is not exist, send an invite';
		} else*/ if (this.users?.length === 0) {
			return 'Mandatory Field';
		}
		return '';
	}

	deleteUserInviteGroup(index: number) {
		this.usersToInviteArray.removeAt(index);
	}

	get usersToInvite() {
		const formArray = this.invitationForm?.controls['users'] as FormArray;
		return formArray.controls as FormGroup[];
	}

	get usersToInviteArray() {
		return this.invitationForm?.controls['users'] as FormArray;
	}

	private _subscribeToClosingActions(): void {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.trigger?.panelClosingActions?.subscribe(
			(e) => {
				if (!e || !e.source) {
					this?.form?.controls?.userListName?.setValue('');
				}
				this.form.get('userListName').updateValueAndValidity();
			},
			(err) => this._subscribeToClosingActions(),
			() => this._subscribeToClosingActions()
		);
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		if ((value || '').trim()) {
			const isOptionSelected = this.users.some(user => user?.fullName === value);
			if (!isOptionSelected) {
				this.users.push({
					fullName: value.trim(),
					isExisting: false
				});
				this.addUserInvite(value.trim());
				this.control?.setValue([...this.control?.value, value]);
			}
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}

		this.form.controls?.userListName?.setValue(null);
		this.form.get('userListName').updateValueAndValidity();
	}

	remove(user, index): void {
		if (user?.isExisting) {
			let value = this.control?.value;
			value?.splice(index, 1);
			value = value ?? [];
			this.control?.setValue([...value]);
		} else {
			const invitationFieldToRemoveIndex = this.usersToInvite.findIndex(form =>
				form.get('userName')?.value === user?.fullName
			);
			let value = this.control?.value;
			value?.splice(index, 1);
			value = value ?? [];
			this.control?.setValue([...value]);

			this.deleteUserInviteGroup(invitationFieldToRemoveIndex);
		}
		this.users?.splice(index, 1);
		this.form.get('userListName').updateValueAndValidity();
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		if (this.control.value) {
			if (this.control?.value?.some(user => user?._id?.toString() === event.option?.value?._id?.toString())) {
				return;
			} else {
				this.control?.setValue([...this.control?.value, event?.option?.value]);
				this?.users?.push({
						fullName: `${event.option?.value?.basicUserInformation?.firstName} ${event?.option?.value?.basicUserInformation?.lastName}`,
						isExisting: true
					}
				);
			}
		} else {
			this.control.setValue([event?.option?.value]);
			this.users = [
				{
					fullName: `${event.option?.value?.basicUserInformation?.firstName} ${event?.option?.value?.basicUserInformation?.lastName}`,
					isExisting: true
				}
			];
		}
		this.form.controls?.userListName?.setValue(null);
		this.userInput.nativeElement.value = '';
		this.form.get('userListName').updateValueAndValidity();
	}

	public getFilteredUsers(inputRegex) {
		return this.userService?.getUsersForAutoComplete(inputRegex);
	}

	fun($event) {
		this.trigger._onChange(' ');
		this.trigger.openPanel();
		this.form.get('userListName').updateValueAndValidity();
		this.userOptions = this.getFilteredUsers(' ');
	}

	private customTeamMembersValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			let valid = true;
			if (this.users?.length < 1 /*|| this.users.some(user => user.isExisting === false)*/) {
				valid = false;
			}
			return valid ? null : {'empty': {value: control.value}};
		};
	}

	subscribeToUser() {
		this.users = this.startList?.map(user => ({
			fullName: user,
			isExisting: true
		}));

		this.dataService.globalUser?.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			if (user && user._id && this.startList.length === 0) {
				this.users = [...this.users, {
					fullName: `${user.basicUserInformation.firstName} ${user.basicUserInformation.lastName}`,
					isExisting: true
				}];
				this.control?.setValue([...this.control?.value, user]);
			}
		});
	}
}
