import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentationSystemService} from '../../../services/documentation-system.service';
import {GlobalsService} from '../../../services/globals';


@Component({
	selector: 'app-documentation-tom-global-challenge-condition',
	templateUrl: './documentation-tom-global-challenge-condition.component.html',
	styleUrls: ['./documentation-tom-global-challenge-condition.component.scss']
})
export class DocumentationTomGlobalChallengeConditionComponent implements OnInit, AfterContentInit {

	@Input() tomGlobalChallengeId;
	@Input() events: any[];

	@Input() set isTOMGlobalChallengeEvent(value: boolean) {
		this.associatedValue = value;
		this.onSelectChallenge.emit(value);
	}

	@Input() label: string;
	@Output() fieldChange = new EventEmitter<string>();
	@Output() onSelectChallenge = new EventEmitter<boolean>();

	associatedValue: boolean = false;
	mouseover_yes: boolean = false;
	mouseover_no: boolean = false;

	constructor(
		public globals: GlobalsService,
		private documentationService: DocumentationSystemService,
	) {
	}

	ngOnInit(): void {
	}

	ngAfterContentInit(): void {
	}

	selectTOMGlobalChallenge(isSelected: boolean) {
		if (!!isSelected) {
			// search tom global challenge
			const tomGlobalChallengeEvents = this.events
				?.filter(option => option?._id?.toString() === this.tomGlobalChallengeId?.toString());

			// found the tom global challenge event
			if (!!tomGlobalChallengeEvents?.length && this.documentationService.valueOfDocumentation?.hasOwnProperty('event')) {
				const documentation = this.documentationService.valueOfDocumentation;
				documentation.event = tomGlobalChallengeEvents[0]?._id;
				this.documentationService.valueOfDocumentation = documentation;
				this.fieldChange.emit();
				this.associatedValue = true;
				this.onSelectChallenge.emit(true);
			}
		} else {
			// found the tom global challenge event
			if (this.documentationService.valueOfDocumentation?.hasOwnProperty('event')) {
				const documentation = this.documentationService.valueOfDocumentation;
				documentation.event = null;
				this.documentationService.valueOfDocumentation = documentation;
				this.fieldChange.emit();
				this.associatedValue = false;
				this.onSelectChallenge.emit(false);
			}
		}
	}
}
