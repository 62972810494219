import {AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output,} from '@angular/core';
import {animate, state, style, transition, trigger,} from '@angular/animations';
import {GlobalsService} from '../../services/globals';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {AnalyticsService} from 'src/app/services/analytics.service';

const OFFSET = 150;

interface Assets {
	menu: string;
	logo: string;
	person: string;
	white: boolean;
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
		trigger('slideInOut', [
			state(
				'in',
				style({
					transform: 'translate3d(0, 0, 0)',
				})
			),
			state(
				'out',
				style({
					transform: 'translate3d(-100%, 0, 0)',
				})
			),
			transition('in => out', animate('400ms ease-in-out')),
			transition('out => in', animate('400ms ease-in-out')),
		]),
	],
})
export class HeaderComponent implements OnInit, AfterContentInit, OnDestroy {
	assets: Assets = <Assets>{};
	menuState: string = 'out';
	isLogin: boolean = false;
	active: string = '';
	userSubscirbe: Subscription;
	image: string;
	isPC: boolean = false;
	@Output() headerFinishedLoading = new EventEmitter();
	aboutItems: any[];
	projectsItems: any[];

	constructor(
		public globals: GlobalsService,
		private router: Router,
		public dataService: DataService,
		public analyticsService: AnalyticsService,
		private location: Location
	) {
		router.events.subscribe((val) => {
			if (location.path() !== '') {
				this.active = location.path();
			} else {
				this.active = 'Home';
			}
		});
	}

	ngOnInit() {
		this.globals.whiteHeader.subscribe((isWhite) => {
			if (isWhite) {
				this.setWhiteAssets();
			} else {
				this.setColoredAssets();
			}
		});
		this.userSubscirbe = this.dataService.globalUser.subscribe((user) => {
			if (user && user.imageUrl) {
				this.image = user.imageUrl;
			} else {
				this.image = undefined;
			}
		});
		// folding menu if need
		// this.aboutItems = [
		// 		{ title: 'Our Outlook', link: '/about', fragment: 'vision' },
		// 		{ title: 'Mission & Objective', link: '/about', fragment: 'mission' },
		// 		{ title: 'The TOM Process', link: '/about', fragment: 'process' },
		// 		{ title: 'Global Team', link: '/about', fragment: 'team' },
		// 		{ title: 'About Reut', link: '/about', fragment: 'reut' },
		// 		{ title: 'Our Partners', link: '/about', fragment: 'our' },
		// 		{ title: 'Press Kit', link: '/about', fragment: 'kit' },
		// 		{ title: 'Communities', link: '/about', fragment: 'communities' },
		// ]
		// this.projectsItems = [
		// 	{ title: 'Products', link: `/results?search=&types=3`, fragment: 'projects' },
		// 	{ title: 'Prototypes', link: `/results?search=&types=2`, fragment: 'projects' },
		// 	{ title: 'Challenges', link: `/results?search=&types=1`, fragment: 'projects' },
		// ]
	}

	// navigateTo(url: string, fragment?: string) {
	// 	debugger
	// 	if (url && fragment !== 'projects') {
	// 		this.router.navigate([url], { fragment: fragment });
	// 	}
	// 	if(url && fragment === 'projects'){
	// 		this.router.navigateByUrl(url);
	// 	}
	// }

	ngAfterContentInit() {
		this.headerFinishedLoading.emit();
		if (navigator.appVersion.indexOf('Win') !== -1) {
			this.isPC = true;
		}
	}

	setWhiteAssets() {
		this.assets.logo = './assets/images/tom-logo.svg';
		this.assets.menu = './assets/images/menu.svg';
		this.assets.person = './assets/images/person.svg';
		this.assets.white = true;
	}

	setColoredAssets() {
		this.assets.logo = './assets/images/tom-logo-color.svg';
		this.assets.menu = './assets/images/menu-color.svg';
		this.assets.person = './assets/images/person-color.svg';
		this.assets.white = false;
	}

	toggleMenu() {
		this.menuState = this.menuState === 'out' ? 'in' : 'out';
	}

	public moveToProfile() {
		this.router.navigateByUrl('/profile');
	}

	ngOnDestroy(): void {
		this.userSubscirbe.unsubscribe();
	}
}
