import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { DynamicFormModel } from '../../../models/dynamic-form';
import { ActivatedRoute, Router } from '@angular/router';
import { Community } from '../../../models/community';
import { Pagination } from '../../../models/pagination';
import { CommunityService } from '../../../services/community.service';
import { EventService } from '../../../services/event.service';
import { EventModel } from '../../../models/event';
import { MediaInputElement } from '../../../models/documentation';
import { Observable, zip } from 'rxjs';
import { catchError, combineLatest, map } from 'rxjs/operators';
import { log } from 'util';
import { environment } from '../../../../environments/environment';


@Component({
	selector: 'app-challenge-section',
	templateUrl: './challenge-section.component.html',
	styleUrls: ['./challenge-section.component.scss']
})
export class ChallengeSectionComponent implements OnInit {

	@Output() saveChanges = new EventEmitter();

	documentationCurrentValue = this.documentationService?.valueOfDocumentation;
	projectInformationSection: DynamicFormModel[] = [
		{
			id: 'projectName',
			question: 'Name of your project*',
			type: 'text',
			value: this.documentationCurrentValue?.projectName ?? '',
			validators: [
				Validators.required,
			],
			errorMessage: 'Mandatory field',
			tooltip: 'Project Names should be titled using the following format: “Project Name + what the project IS. Example: “Tech Prosthesis for Digital Screens”'
		},
		{
			id: 'teamMembers',
			question: 'Add your team members*',
			type: 'multi-select',
			value: this.documentationCurrentValue?.teamMembers ? [...this.documentationCurrentValue?.teamMembers, ...this.documentationCurrentValue?.teamMembersFreeText] : null,
			validators: [
				Validators.required,
			],
			errorMessage: 'Mandatory field',
		},
		{
			id: 'needKnowerFreeText',
			question: 'Who is your Need Knower?*',
			type: 'text',
			value: this.documentationCurrentValue?.needKnowerFreeText ?? '',
			validators: [
				Validators.required,
			],
			title: 'Every TOM solution must address a challenge faced by a real person, or group. We call them "Need Knowers."',
			errorMessage: 'Mandatory field',
			tooltip: 'Add the Need Knower name. It can be a name of a person, organization, care center, etc.'
		},
		{
			id: 'needKnower',
			question: 'Tag your need knower',
			type: 'autocomplete',
			value: this.documentationCurrentValue?.needKnower ?? null,
			title: 'If your Need Knower has a TOM profile, you can tag them to your project.',
			tooltip: 'Your Need Knower must have a TOM profile to be tagged, simply type their name and autocomplete will find them.'
		},

		{
			id: 'yourNeedKnower',
			question: 'Who are the potential users of this solution?*',
			type: 'textarea',
			value: this.documentationCurrentValue?.yourNeedKnower ?? null,
			validators: [
				Validators.required,
			],
			errorMessage: 'Mandatory field',
		},
		{
			id: 'projectNeeded',
			question: 'Why is this project needed?*',

			type: 'textarea',
			value: this.documentationCurrentValue?.projectNeeded ?? null,
			validators: [
				Validators.required,
			],
			errorMessage: 'Mandatory field',
			// tooltip: 'What is the challenge faced by your Need Knower?',
		},
	];
	communities: Community[] = [];
	events: EventModel[] = [];
	public pagination: Pagination;
	isCommunity: boolean = false;
	isEvent: boolean = false;
	isFormValid: boolean = false;

	// tom global challenge
	tomGlobalChallengeId = environment.tomGlobalChallengeId;
	isTOMGlobalChallengeEvent: boolean = false;

	fileInput: MediaInputElement[] = [
		{
			title: 'Upload a photo of your Need Knower (if possible)',
			options: {
				fieldName: 'needKnower',
				isMultiple: false,
				formats: ['image/*'],
				required: false
			},
		},
	];


	constructor(
		private documentationService: DocumentationSystemService,
		private communityService: CommunityService,
		private eventService: EventService) {
		this.pagination = new Pagination(300);
	}


	ngOnInit(): void {
		zip(this.getEvents(), this.getCommunities())
			.subscribe(([events, communities]) => {
				// init communities
				this.communities = communities;

				// init events
				this.events = events;

				// init forms
				this.initForms();
			});
	}

	get isValid(): boolean {
		return this.isFormValid;
	}

	getCommunities(): Observable<Community[]> {
		return this.communityService.getCommunities(this.pagination).pipe(
			map((resp) => {
				return resp?.items?.map(item => {
					try {
						return Object.assign(new Community(), item);
					} catch (err) {
						console.log(err);
					}
				});
			}),
		);
	}

	getEvents(): Observable<EventModel[]> {
		return this.eventService.getAllEvents().pipe(
			map((resp) => {
				return resp?.map(item => {
					try {
						return Object.assign(new EventModel(), item);
					} catch (err) {
						console.log(err);
					}
				});
			}),
		);
	}


	initForms() {
		this.projectInformationSection.forEach(field => {
			const fieldValue = this.documentationCurrentValue[field['id']];
			if (fieldValue === undefined) {
				field['value'] = null;
			} else {
				field['value'] = fieldValue;
			}
		});

		// tom global challenge
		const tomGlobalChallengeEvents = this.events
			?.filter(option => option?._id?.toString() === this.tomGlobalChallengeId?.toString());

		if (!!tomGlobalChallengeEvents?.length
			&& ((this.documentationCurrentValue?.event as EventModel)?._id === tomGlobalChallengeEvents[0]._id
				|| this.documentationCurrentValue?.event === tomGlobalChallengeEvents[0]._id)) {
			this.isTOMGlobalChallengeEvent = true;
		} else {
			this.isTOMGlobalChallengeEvent = null;
		}

		// events
		if (this.documentationCurrentValue?.event) {
			this.isEvent = true;
		} else if (this.documentationCurrentValue?._id !== '') {
			this.isEvent = false;
		} else {
			this.isEvent = null;
		}

		// communities
		if (this.documentationCurrentValue?.community) {
			this.isCommunity = true;
		} else if (this.documentationCurrentValue?._id !== '') {
			this.isCommunity = false;
		} else {
			this.isCommunity = null;
		}
	}


	dynamicFormChange(form: FormGroup) {

		this.isFormValid = form.valid;

		if (this.isFormValid && this.documentationService.valueOfDocumentation.type < 1) {
			this.documentationService.valueOfDocumentation.type = 1;
		}
		const documentation = this.documentationService.valueOfDocumentation;
		Object.keys(form?.controls).forEach(key => {
			const formControl = form?.controls[key];
			if (documentation?.hasOwnProperty(key)) {
				documentation[key] = formControl?.value;
			}
		});
		this.documentationService.valueOfDocumentation = documentation;
	}

	fieldChange(field: string) {
		this.documentationService.valueOfEditField = field;
	}


}
