<div class="select-form">
    <label class="B2-dark-text">{{label}}</label>

    <div class="buttons">
        <button class="regular-button" (click)="onChangeAssociated('true')" (mouseover)="mouseover_yes = true"
                (mouseout)="mouseover_yes = false"
                [ngClass]="{'pressed-button' : associatedValue === 'true', 'hover-edit-button': mouseover_yes}">Yes
        </button>
        <!--        <button class="regular-button" (click)="onChangeAssociated('true')" (mouseover)="mouseover_yes = true" (mouseout)="mouseover_yes = false" mat-flat-button [ngClass]="{'pressed-button' : associatedValue === 'true', 'hover-edit-button': mouseover_yes}">Yes</button>-->
        <button class="regular-button" (click)="onChangeAssociated('false')" (mouseover)="mouseover_no = true"
                (mouseout)="mouseover_no = false"
                [ngClass]="{ 'pressed-button' : associatedValue === 'false', 'hover-edit-button': mouseover_no}">No
        </button>
    </div>
    <br>

    <!--    type is select-->
    <div class="form-column" *ngIf="isValueExists && type === 'select'">
        <mat-form-field class="full-width" hideRequiredMarker="true">
            <mat-label>
                {{selectLabel}}
            </mat-label>
            <input aria-label='user' matInput
                   [formControl]="selectInputFormControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectChanged($event?.option.value)">
                <mat-option *ngFor="let value of filteredValuesList | async " [value]="value">
                    {{displayValue(value)}}
                </mat-option>
            </mat-autocomplete>
            <mat-icon class="icon" matSuffix>search</mat-icon>
        </mat-form-field>
    </div>

<!--  type is text-->
    <mat-form-field appearance="fill" *ngIf="isValueExists && field?.type ==='text'">
        <mat-label>{{field?.selectLabel}}</mat-label>
        <textarea #message maxlength="256" matInput
                  [value]="field?.selectLabelValue" (change)="onTextChange($event.target.value)"></textarea>
        <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
    </mat-form-field>


</div>
