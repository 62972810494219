import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentationSystemService} from '../../../services/documentation-system.service';
import {GlobalsService} from '../../../services/globals';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';


@Component({
	selector: 'app-documentation-select-form',
	templateUrl: './documentation-select-form.component.html',
	styleUrls: ['./documentation-select-form.component.scss']
})
export class DocumentationSelectFormComponent implements OnInit, AfterContentInit {

	@Input() isValueExists: boolean;
	@Input() valuesList: any[];
	@Input() label: string;
	@Input() link: string;
	@Input() linkText: string;
	@Input() selectLabel: string;
	@Input() valueToDisplay: string;
	@Input() field: any;
	@Input() type: string;
	@Output() fieldChange = new EventEmitter<string>();

	associatedValue: string = '';
	mouseover_yes: boolean = false;
	mouseover_no: boolean = false;


	// select options from async list
	filteredValuesList: BehaviorSubject<any[]> = new BehaviorSubject([]);
	selectInputFormControl: FormControl = new FormControl(null);

	constructor(
		public globals: GlobalsService,
		private documentationService: DocumentationSystemService,
	) {
	}


	onChangeAssociated(value) {
		console.log(value);
		console.log(this.documentationService.valueOfDocumentation);
		const documentation = this.documentationService.valueOfDocumentation;
		if (typeof this.field === 'object') {
			documentation.eligibility[this.field.id] = (value === 'true');
		} else {
			this.isValueExists = (value === 'true');
			if (!this.isValueExists) {
				documentation[this.field] = null;
			}
		}
		this.associatedValue = value;
		this.documentationService.valueOfDocumentation = documentation;
	}

	onSelectChanged(value) {
		if (value !== null && value !== '' && this.documentationService.valueOfDocumentation?.hasOwnProperty(this.field)) {
			const documentation = this.documentationService.valueOfDocumentation;
			documentation[this.field] = value?._id;
			this.documentationService.valueOfDocumentation = documentation;
			this.selectInputFormControl?.setValue(this.displayValue(value));
			this.fieldChange.emit();
		}
	}

	onTextChange(value) {
		if (value !== null && value !== '' && this.documentationService.valueOfDocumentation?.eligibility?.hasOwnProperty(this.field.id)) {
			const documentation = this.documentationService.valueOfDocumentation;
			documentation.eligibility[this.field.id] = value;
			this.documentationService.valueOfDocumentation = documentation;
		}
	}

	getSelectValue() {
		return this.documentationService.valueOfDocumentation[this.field];
	}

	displayValue(value) {
		return value[this.valueToDisplay];
	}

	ngOnInit(): void {
		if (this.isValueExists) {
			this.associatedValue = 'true';
		} else if (this.isValueExists === false) {
			this.associatedValue = 'false';
		}
		if (this.type === 'select') {
			this.selectInputFormControl.valueChanges.subscribe(userInput => {
				if (typeof userInput === 'string') {
					const filteredOptionsArray = this.valuesList?.filter(option => option?.name?.toString()?.toLowerCase()?.includes(userInput?.toLowerCase()));
					this.filteredValuesList.next(filteredOptionsArray);
				}
			});
		}
	}

	ngAfterContentInit(): void {
		if (this.isValueExists && this.type === 'select') {
			const existingElement = this.valuesList?.find(object => object?._id === this.getSelectValue());
			if (!!existingElement) {
				this.selectInputFormControl.setValue(this.displayValue(existingElement));
			}
		}
	}


}
