<div class="body">
    <app-dynamic-form *ngIf="projectInformationSection" class="input-fields"
                      [formFields]="projectInformationSection"
                      (formChange)="dynamicFormChange($event)"
                      (fieldChange)="fieldChange($event)"
                      [projectName]="(documentationService?.projectAsObservable | async)?.projectName"
    ></app-dynamic-form>

    <app-documentation-tom-global-challenge-condition
            [tomGlobalChallengeId]="tomGlobalChallengeId"
            [events]="events"
            [label]="'Is your project a part of the 2024 TOM Global Innovation Challenge?'"
            (fieldChange)="fieldChange('event')"
            [isTOMGlobalChallengeEvent]="isTOMGlobalChallengeEvent"
            (onSelectChallenge)="isTOMGlobalChallengeEvent = $event"
    ></app-documentation-tom-global-challenge-condition>

    <app-documentation-select-form *ngIf="communities?.length > 0" [valuesList]="communities"
                                   [isValueExists]="isCommunity"
                                   [field]="'community'"
                                   [valueToDisplay]="'name'"
                                   [label]="'Is your project part of a TOM community?'"
                                   [link]="'https://tomglobal.org/communities'"
                                   [linkText]="'to see all TOM communities'"
                                   [selectLabel]="'Select community'"
                                   [type]="'select'"
                                   (fieldChange)="fieldChange('community')"
    ></app-documentation-select-form>

    <app-documentation-select-form *ngIf="events?.length > 0 && !isTOMGlobalChallengeEvent" [valuesList]="events"
                                   [isValueExists]="isEvent"
                                   [field]="'event'"
                                   [valueToDisplay]="'name'"
                                   [label]="'Is your project part of a TOM event, Makeathon, or other activity?'"
                                   [link]="'https://tomglobal.org/events'"
                                   [linkText]="'to see all current TOM events'"
                                   [selectLabel]="'Select event'"
                                   [type]="'select'"
                                   (fieldChange)="fieldChange('event')"
    ></app-documentation-select-form>

    <app-photos-videos [fileInput]="fileInput" (fieldChange)="fieldChange($event)"
                       [isTOMGlobalChallengeEvent]="isTOMGlobalChallengeEvent"></app-photos-videos>


</div>
