import {Component, Inject, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./login-popup.component.scss', './donate-popup.component.scss', './download-popup.component.scss']
})
export class PopupComponent implements OnInit {

	public title: string;
	public text: string;
	public image: string;
	public button: string;
	public style: string;
	public buttonIcon: string;

	constructor(public globals: GlobalsService,
				private dialogRef: MatDialogRef<PopupComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit() {
		switch (this.data.action) {
			case 'login':
				this.setLoginPopup();
				break;
			case 'donate':
				this.setDonatePopup();
				break;
			case 'communityRegister':
				this.setCommunityJoinPopup();
				break;
			case 'joinCommunity':
				this.setJoinRelatedCommunityEvent();
				break;
			case 'download':
				this.setDownloadPopup();
				break;
			case 'joinEvent':
				this.setJoinEventsText();
				break;
			case 'loginDownload':
				this.setLoginDownloadPopup();
				break;
			default:
				this.setLoginPopup();
		}
	}

	private setLoginPopup() {
		this.title = 'Login is Required';
		this.text = 'Please log in to join the community';
		this.image = 'community@blue.svg';
		this.button = 'JOIN TOM';
		this.style = 'login';
	}

	private setLoginDownloadPopup() {
		this.title = 'Login is Required';
		this.text = 'Please login, in order to download the file';
		this.image = 'community@blue.svg';
		this.button = 'JOIN TOM';
		this.style = 'login';
	}

	private setCommunityJoinPopup() {
		this.title = 'Being part of the community Required';
		this.text = 'Please join the community';
		this.image = 'community@blue.svg';
		this.button = 'Go to community';
		this.style = 'login';
	}

	private setJoinRelatedCommunityEvent() {
		this.title = 'You need to be an active community member';
		this.text = 'Please join the community before applying to this event';
		this.image = 'community@blue.svg';
		this.button = 'Go to community page';
		this.style = 'login';
	}

	private setDonatePopup() {
		this.title = 'Thank You!';
		this.text = 'text about donation.';
		this.image = 'donation.svg';
		this.button = 'DONATE';
		this.style = 'donate';
		this.buttonIcon = 'heart-white.svg';
	}

	private setDownloadPopup() {
		this.title = 'Download Project';
		this.text = `For additional information about the project and ways to build it, please contact the project's community manager.`;
		this.image = 'download-product.svg';
		this.button = 'DOWNLOAD PROJECT';
		this.style = 'download';
		this.buttonIcon = 'download-arrow.svg';
	}

	private setJoinEventsText() {
		this.title = 'Login is required';
		this.text = 'Please log in to join the event';
		// this.image = 'download-product.svg';
		this.button = 'Go to login page';
		this.style = 'login';
		// this.buttonIcon = 'download-arrow.svg';
	}

	closeDialog() {
		this.dialogRef.close(false);
	}

	confirmDialog() {
		this.dialogRef.close(true);
	}
}
