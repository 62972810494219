<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'">
    <div class="member-info" [ngClass]="bottom ? 'bottom' : ''">
        <div class="member-photo round" (click)="navigateToUserProfile()">
            <ng-container>
                <img class="photo round" [src]="image"/>
                <span
                        class="flag round flag-icon + flag-icon-{{
						member.basicUserInformation.countryId?.alpha2Code
							| lowercase
					}} flag-icon-squared"
                ></span>
            </ng-container>
        </div>
        <div class="info">
            <div class="member-name heavy" (click)="navigateToUserProfile()">
                {{ member?.basicUserInformation?.firstName || "" }}
                {{ member?.basicUserInformation?.lastName || "" }}
            </div>
            <!-- <div class="member-email hide text-gray" [ngClass]="emailHover ? 'email-hover' : ''"> -->
            <div
                    class="member-email text-body"
                    [ngClass]="{ 'email-mobile': showMailOnMobile }"
                    *ngIf="mail"
            >
                {{ member?.basicUserInformation?.email || "" }}
            </div>
        </div>
    </div>
</div>
