import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {User} from '../models/user';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService extends HttpService {

	public login(email: string, password: string): Observable<any> {
		return this.post('/access/login', {email: email, password: password, passwordConfirmation: password});
	}

	public facebookLogin(facebookId: string) {
		return this.post('/access/login', {facebookId});
	}

	public sendInvitationEmail(email: string, fullName: string) {
		return this.post('/user/invite', {email, fullName});
	}


	public getAllUsers(): Observable<any> {
		return this.get('/user');
	}

	public getUserById(userId: string): Observable<any> {
		return this.get('/user/detailsId', {userId});
	}

	public getUsersForAutoComplete(userInput: string): Observable<any> {
		let url;
		if (userInput) {
			url = `/user/autocomplete?userInput=${userInput}`;
		} else {
			url = '/user/autocomplete';
		}
		return this.get(url);
		// return this.get('/user');
	}

	public register(user: User): Observable<any> {
		return this.post('/access/register', user);
	}

	public getUserData(): Observable<any> {
		return this.get('/user/details');
	}

	public changePassword(email: string, currentPassword: string, newPassword: string): Observable<any> {
		return this.put('/user/changePassword', {email, currentPassword, newPassword});
	}

	public forgotPassword(email: string): Observable<any> {
		return this.post('/user/forgotpassword', {email});
	}

	public editUserDetails(userDetails: User): Observable<any> {
		return this.put('/user/edit', userDetails);
	}

	public subscribe(email): Observable<any> {
		return this.post('/newsLetter', {email: email});
	}

	public getPublicUserData(userId: string): Observable<any> {
		return this.get('/user/public', {userId});
	}

	public checkEmailInDb(email: string): Observable<any> {
		return this.post('/access/emailOk', {email});
	}
}
