import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Pagination} from '../models/pagination';
import {type} from 'os';

@Injectable({
	providedIn: 'root',
})
export class PressService extends HttpService {
	public getAllPressItems(
		pagination: Pagination,
		countryId: string,
		communityId: string,
		typeId: string
	) {
		const params = pagination.getPaginationAsParams();

		if (countryId) {
			params.countryId = countryId;
		}
		if (communityId) {
			params.communityId = communityId;
		}
		if (typeId) {
			params.typeId = typeId;
		}

		return this.get('/press', params);
	}

	public getPopularPressItems() {
		return this.get('/press', {isPopular: true});
	}

	public getPressKit() {
		return this.get('/press/kit');
	}
}
