<div [ngClass]="(globals.isDesktop)?'desktop':'mobile'">
    <div class="other-project">
        <div class="row">
            <div class="project-photo round"
                 [ngStyle]="{'background-image': 'url(' + logoUrl + ')'}">
            </div>
            <div style="max-width: 80%;">
                <div class="project-name bold text-blue"
                     (click)="navigateToProjectPage()">{{otherProject.projectName}}</div>
            </div>
        </div>
    </div>
</div>
