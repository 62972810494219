import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../models/user';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {UserService} from '../../services/user.service';
import {take} from 'rxjs/operators';


@Component({
	selector: 'app-user-autocomplete',
	templateUrl: './user-autocomplete.component.html',
	styleUrls: ['./user-autocomplete.component.scss']
})
export class UserAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

	@Input() startText: string;
	@Input() formControl: FormControl;
	@Input() placeHolder: string;
	@Input() hint: string;
	@Input() tooltip: string;
	@Input() projectName: string | null;
	@Output() fieldChange?: EventEmitter<string> = new EventEmitter<string>();


	_required: boolean = true;
	@Input('required')
	set required(value: boolean) {
		this._required = value;
		if (value && this?.form?.controls?.name) {
			this.form.controls.name.setValidators([Validators.required]);
		} else if (!value && this?.form?.controls?.name) {
			this.form.controls.name.setValidators([]);
		}
	}

	_disabled = false;
	@Input('disabled')
	set disabled(value: boolean) {
		this._disabled = value;
		if (value && this?.form?.controls?.name) {
			this.form.controls.name.disable();
		} else if (!value && this?.form?.controls?.name) {
			this.form.controls.name.enable();
		}
	}

	subscription: Subscription;
	public userOptions: Observable<{ items: User[] }>;
	public form: FormGroup;
	userForm = this.fb.group({
		email: ['', [
			Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
		userName: ['', []],
	});
	displayEmailForm = false;
	emailSent = false;

	constructor(private userService: UserService,
				private fb: FormBuilder) {
	}

	ngAfterViewInit() {
		this._subscribeToClosingActions();
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	ngOnInit() {
		this.form = this.fb.group({
			name: [{value: this?.startText, disabled: this._disabled}, []]
		});

		this.form.get('name')?.valueChanges?.subscribe(inputValue => {
			this.userOptions = this.getFilteredUsers(inputValue);
			this.fieldChange.emit();
		});

	}

	private _subscribeToClosingActions(): void {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}

		this.subscription = this.trigger?.panelClosingActions?.subscribe(e => {
				if (!e || !e.source) {
					const userName = this.form.controls?.name?.value;
					const currentNeedKnowerName = this.formControl?.value?.basicUserInformation?.firstName + ' ' + this.formControl?.value?.basicUserInformation?.lastName;
					if (userName && userName.toString()?.trim().length > 0 && (userName !== this.startText || userName?.trim() !== currentNeedKnowerName?.trim())) {
						this.formControl?.setValue(null);
						this.userForm.controls['userName'].setValue(this.form.controls?.name?.value);
						this.displayEmailForm = true;
					} else if (!userName && this.displayEmailForm) {
						this.displayEmailForm = false;
						this.userForm.controls['userName'].setValue(null);
					}
				}
			},
			err => this._subscribeToClosingActions(),
			() => this._subscribeToClosingActions());
	}


	handler(event: MatAutocompleteSelectedEvent): void {
		this.formControl.setValue(event.option.value);
		this.form.controls.name.setValue(`${event.option.value.basicUserInformation.firstName} ${event.option.value.basicUserInformation.lastName}`);
		this.displayEmailForm = false;
	}

	public getFilteredUsers(inputRegex) {
		return this.userService.getUsersForAutoComplete(inputRegex);
	}

/*	sendEmail(emailForm: FormGroup) {
		if (!this.emailSent) {
			const email = emailForm.get('email').value ?? null;
			const fullName = emailForm.get('userName').value ?? '';
			if (!!email) {
				this.userService.sendInvitationEmail(email, this.projectName ? this.projectName : '')
					.pipe(take(1)).subscribe(response => {
					this.emailSent = true;
				});
			}
		}
	}*/

	fun() {
		// this.trigger._onChange(' ');
		this.trigger.openPanel();
		this.userOptions = this.getFilteredUsers(' ');
		const userName = this.form.controls?.name?.value;
		if (userName.toString()?.trim().length === 0) {
			this.displayEmailForm = false;
		}
	}


}
