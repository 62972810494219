<div class="working-model">
    <app-tools-section #toolsSection (fieldChange)="fieldChange('tools')"></app-tools-section>
    <mat-divider class="divider"></mat-divider>

    <app-materials-section (fieldChange)="fieldChange($event)"></app-materials-section>
    <mat-divider class="divider"></mat-divider>

    <app-project-files></app-project-files>
    <mat-divider class="divider"></mat-divider>

    <app-assembly-guide (fieldChange)="fieldChange($event)"></app-assembly-guide>
    <mat-divider class="divider"></mat-divider>

    <app-special-instructions (fieldChange)="fieldChange($event)"></app-special-instructions>
    <mat-divider class="divider"></mat-divider>

    <app-photos-videos #photosVideosComponent [fileInput]="fileInput" (fieldChange)="fieldChange($event)"></app-photos-videos>
    <mat-divider class="divider"></mat-divider>

    <div class="footer" *ngIf="(documentation$ | async)?.type === 3 " >
            <span class="B2-dark-text">
       Congratulations on completing the Working Model stage of your project! <br> Don’t forget to save your work.
            </span>

        <div class="upgrade-button">
            <img class="icon" src="./assets/images/upgrade-icon.png"/>
            <span class="upgrade-text">     If you are ready to upgrade your Working Model, proceed to the Prototype stage. </span>
        </div>
    </div>
</div>
















