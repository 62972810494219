import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { basicToolsGroup, MediaInputElement, otherToolsGroup } from '../../../models/documentation';
import { ToolsSectionComponent } from '../tools-section/tools-section.component';
import { PhotosVideosComponent } from '../photos-videos/photos-videos.component';


@Component({
	selector: 'app-working-model-section',
	templateUrl: './working-model-section.component.html',
	styleUrls: ['./working-model-section.component.scss']
})
export class WorkingModelSectionComponent implements OnInit, OnDestroy {
	// subscriptions
	valuesChangeSubscription: Subscription;
	documentationCurrentValue = this.documentationService?.valueOfDocumentation;
	documentation$ = this.documentationService?.documentationAsObservable;


	// forms
	basicToolsFormGroup = new FormGroup({});
	otherToolsFormGroup = new FormGroup({});
	basicToolsGroup = basicToolsGroup;
	otherToolsGroup = otherToolsGroup;
	toolsFormGroup = new FormGroup({ basicTools: this.basicToolsFormGroup, otherTools: this.otherToolsFormGroup });

	@ViewChild('toolsSection') toolsSectionComponent: ToolsSectionComponent;
	@ViewChild('photosVideosComponent') photosVideosComponent: PhotosVideosComponent;


	fileInput: MediaInputElement[] = [
		{
			title: 'Upload a photo of your project working model*',
			options: {
				fieldName: 'workingModelPhoto',
				isMultiple: false,
				formats: ['image/*'],
				required: true
			},
			tooltip: 'A working model proves that the basic assumptions or features of the proposed solution work. It can be made of any material.',
		},
	];

	constructor(private fb: FormBuilder, private documentationService: DocumentationSystemService) {
	}


	ngOnInit() {
		this.initFormGroup();
		this.subscribeToFormGroups();
	}


	initFormGroup() {
		this.basicToolsGroup.forEach((toolGroup, index) => {
			this.basicToolsFormGroup.addControl(index.toString(), new FormControl([]));
		});
		this.otherToolsGroup.forEach((toolGroup, index) => {
			this.otherToolsFormGroup.addControl(index.toString(), new FormControl([]));
		});

		this.documentationCurrentValue?.tools?.forEach(tool => {
			this.basicToolsGroup.forEach((basicToolGroup, index) => {
				if (basicToolGroup?.name === tool) {
					this.basicToolsFormGroup.controls[index?.toString()].setValue([...this.basicToolsFormGroup?.controls[index.toString()].value, tool]);
				}
			});
			this.otherToolsGroup.forEach((otherToolGroup, index) => {
				if (otherToolGroup?.name === tool) {
					this.otherToolsFormGroup.controls[index?.toString()].setValue([...this.otherToolsFormGroup?.controls[index.toString()].value, tool]);
				}
			});
		});
	}

	subscribeToFormGroups() {
		this.valuesChangeSubscription = this.toolsFormGroup?.valueChanges?.subscribe(value => {
			let tools = [];
			Object.keys(value?.basicTools).forEach(key => {
				tools = [...tools, ...value.basicTools[key]];
			});
			Object.keys(value?.otherTools).forEach(key => {
				tools = [...tools, ...value.otherTools[key]];
			});
			this.documentationService.valueOfDocumentation = {
				...this.documentationService.valueOfDocumentation,
				tools: tools
			};
		});
	}

	ngOnDestroy(): void {
		this.valuesChangeSubscription.unsubscribe();
	}

	fieldChange(field: string) {
		this.documentationService.valueOfEditField = field;
	}

	get isValid(): boolean {
		return this.toolsSectionComponent.isAtLeastOneTool && !!this.photosVideosComponent?.existingFiles?.workingModelPhoto;
	}


}
