import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Documentation, otherToolsGroup} from '../models/documentation';
import {HttpService} from './http.service';
import {AdditionalInformation, Project} from '../models/project';
import {ProjectService} from './project.service';
import {switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import S3FileUpload from '../helpers/file-upload/s3-file-upload';
import JSZip from 'jszip';
import {DataService} from './data.service';


@Injectable({
	providedIn: 'root'
})
export class DocumentationSystemService extends HttpService {
	private FileUpload: S3FileUpload;


	constructor(public http: HttpClient, private projectService: ProjectService, private dataService: DataService) {
		super(http);
		this.FileUpload = new S3FileUpload();
	}

	askedForProduct = false;

	private documentationSource = new BehaviorSubject<Documentation>(null);
	documentationAsObservable = this.documentationSource?.asObservable();


	private projectSource = new BehaviorSubject<Project>(null);
	projectAsObservable = this.projectSource?.asObservable();

	private uploadingDocumentationSource = new BehaviorSubject<boolean>(false);
	uploadingDocumentationAsObservable = this.uploadingDocumentationSource?.asObservable();

	private currentEditFieldSource = new BehaviorSubject<string>(null);
	currentEditFieldAsObservable = this.currentEditFieldSource?.asObservable();


	set valueOfEditField(value: string) {
		if (!!value && value !== 'undefined') {
			this.currentEditFieldSource?.next(value);
		}
	}

	get valueOfDocumentation() {
		return this.documentationSource?.value;
	}

	set valueOfDocumentation(value: Documentation) {
		value.type = value?.type > 0 ? this.getProjectTypeFromDocumentation(value) : 0;
		value.documentationFiles.updatedPhoto = this.getRelevantProjectPhoto(value);
		value.eligibleToBeProduct = (value?.eligibility?.customization && value?.eligibility?.affordability && value?.eligibility?.replicable && value?.eligibility?.userTesting);
		this.documentationSource?.next(value);
		this.updateProjectValuesFromDocumentation();
	}

	get valueOfProject() {
		return this.projectSource?.value;
	}

	set valueOfProject(value: Project) {
		this.projectSource?.next(value);
	}

	get valueOfUploadingDocumentation() {
		return this.uploadingDocumentationSource?.value;
	}

	set valueOfUploadingDocumentation(value: boolean) {
		this.uploadingDocumentationSource?.next(value);
	}


	public getDocumentationDetails(documentationId?: string, projectId?: string) {
		return this.get('/documentation/details', {documentationId, projectId});
	}

	public updateDocumentation(documentation: Documentation): Observable<any> {
		this.valueOfDocumentation.updatedBy = this.dataService.userValue?._id;
		documentation.needKnower = documentation?.needKnower?._id ?? null;
		// documentation.teamMembers = Array.from(new Set([...documentation?.teamMembers?.map(user => user?._id) ?? [], this.dataService?.userValue?._id]));
		return this.projectService.updateProject(this.valueOfProject).pipe(
			switchMap(response => {
				const url = '/documentation';
				return this.put(url, documentation);
			}),
		);
	}

	// set updatedPhoto value by the matching challenge photo.
	private getRelevantProjectPhoto(documentation: Documentation) {
		if (!!documentation?.documentationFiles?.prototypePhoto) {
			return documentation?.documentationFiles?.prototypePhoto;
		} else if (!!documentation?.documentationFiles?.workingModelPhoto) {
			return documentation?.documentationFiles?.workingModelPhoto;
		} else {
			return documentation?.documentationFiles?.conceptPhoto ?? '';
		}
	}

	public createNewDocumentation(documentation: Documentation): Observable<any> {
		documentation.createdBy = this.dataService.userValue?._id;
		documentation.updatedBy = this.dataService.userValue?._id;
		documentation.needKnower = documentation?.needKnower?._id ?? null;
		documentation.teamMembers = Array.from(new Set([...documentation?.teamMembers?.map(user => user?._id ?? user) ?? [], this.dataService?.userValue?._id]));
		return this.projectService.createProject(this.valueOfProject).pipe(
			switchMap(response => {
				const url = '/documentation';
				this.valueOfDocumentation.project = response?._id ?? null;
				return this.post(url, documentation);
			}),
		);
	}


	private generateProjectFilesUrlsArray() {
		const relevantFields = ['updatedPhoto', 'explodedView', 'beingUsed'];
		const filesUrls = [];
		relevantFields.forEach(field => {
			const fileInDocumentation = this.valueOfDocumentation.documentationFiles[field];
			if (!!fileInDocumentation && typeof fileInDocumentation === 'string') {
				filesUrls.push(fileInDocumentation);
			}
		});
		return filesUrls;
	}

	private updateProjectValuesFromDocumentation() {
		if (!!this.valueOfProject) {
			const project = this.valueOfProject;
			project.projectName = this.valueOfDocumentation?.projectName;
			project.type = this.valueOfDocumentation?.type > 0 ? this.valueOfDocumentation?.type : 1;
			project.communityId = this.valueOfDocumentation?.community;
			project.relatedEventId = this.valueOfDocumentation?.event;
			project.members = Array.from(new Set([...this.valueOfDocumentation?.teamMembers?.map(user => user?._id), this.dataService?.userValue?._id])); // as set to remove duplicates.
			project.challengeName = this.valueOfDocumentation?.projectName;
			project.resources = this.generateProjectResources();
			project.imagesUrls = this.generateProjectFilesUrlsArray();

			// additional Info
			if (!project?.additionalInformation) {
				project.additionalInformation = new AdditionalInformation();
			}
			project.additionalInformation.disabledPersonDetails = this.valueOfDocumentation?.yourNeedKnower;
			project.additionalInformation.challengeDetails = this.valueOfDocumentation?.projectNeeded;

			if (this.valueOfDocumentation?.needKnower) {
				project.additionalInformation.teamName = this.valueOfDocumentation?.needKnower?.basicUserInformation?.firstName ? `Team ${this.valueOfDocumentation?.needKnower?.basicUserInformation?.firstName}` : '';
			} else { // get the need knower free text
				project.additionalInformation.teamName = this.valueOfDocumentation?.needKnowerFreeText;
			}
			project.additionalInformation.challengeImage = this.valueOfDocumentation?.documentationFiles?.needKnower;

			this.valueOfProject = project;
		}
	}

	private generateProjectResources() {

		let resources =
			`<p><strong>Who?</strong></p><p>${this.valueOfDocumentation?.yourNeedKnower ?? ''}</p>
		<br>
		<p><strong>Why?</strong></p><p>${this.valueOfDocumentation?.projectNeeded ?? ''}</p>
		<br>
		<p><strong>How?</strong></p><p>${this.valueOfDocumentation?.solutionAddress ?? ''}</p>
		<br>
		<p><strong>What?</strong></p><p>${this.valueOfDocumentation?.solutionOffering ?? ''}</p>
		<br>`;

		if (this.valueOfDocumentation?.tools?.length > 0) {
			resources += `<p>Tools needed</p> <ul> </ul>`;
			for (const item of this.valueOfDocumentation?.tools) {
				resources += `<li> ${item}</li>`;
			}
		}
		return resources;
	}

	private urlToPromise(url): Promise<any> {
		return new Promise((resolve, reject) => {
			return this.http.get(url, {responseType: 'blob'}).toPromise().then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public async generateZipFileAsPromise(pdfFile: File): Promise<any> {
		const zip = new JSZip();
		zip.file(pdfFile?.name, pdfFile);
		const projectFiles = zip.folder('project files');
		this.valueOfDocumentation?.projectFiles?.forEach((fileUrl, index) => {
			if (!!fileUrl && typeof fileUrl === 'string') {
				const url = new URL(fileUrl);
				const fileName = url?.pathname;
				projectFiles.file(fileName, this.urlToPromise(fileUrl), {base64: true});
			}
		});

		const bonusFiles = zip.folder('Bonus content');
		this.valueOfDocumentation?.bonusInformation?.files?.forEach((bonusFile, index) => {
			if (!!bonusFile && typeof bonusFile?.fileUrl === 'string') {
				const url = new URL(bonusFile?.fileUrl);
				const fileName = url?.pathname;
				bonusFiles.file(fileName, this.urlToPromise(bonusFile?.fileUrl), {base64: true});
			}
		});

		// project overview video
		if (!!this.valueOfDocumentation.documentationFiles.projectOverviewVideo && typeof this.valueOfDocumentation.documentationFiles.projectOverviewVideo === 'string') {
			const url = new URL(this.valueOfDocumentation.documentationFiles.projectOverviewVideo);
			const fileName = url?.pathname;
			bonusFiles.file(fileName, this.urlToPromise(this.valueOfDocumentation.documentationFiles.projectOverviewVideo), {base64: true});
		}

		// being used video
		if (!!this.valueOfDocumentation.documentationFiles.beingUsedVideo && typeof this.valueOfDocumentation.documentationFiles.beingUsedVideo === 'string') {
			const url = new URL(this.valueOfDocumentation.documentationFiles.beingUsedVideo);
			const fileName = url?.pathname;
			bonusFiles.file(fileName, this.urlToPromise(this.valueOfDocumentation.documentationFiles.beingUsedVideo), {base64: true});
		}

		// other videos
		this.valueOfDocumentation?.documentationFiles?.otherVideos?.forEach((otherVideo, index) => {
			if (!!otherVideo && typeof otherVideo === 'string') {
				const url = new URL(otherVideo);
				const fileName = url?.pathname;
				bonusFiles.file(fileName, this.urlToPromise(otherVideo), {base64: true});
			}
		});

		return new Promise((resolve, reject) => {
			return zip.generateAsync({type: 'blob'}).then((zipAsBlob) => {
				const zipFile = new File([zipAsBlob], `${this.valueOfDocumentation?.projectName}.zip`, {
					lastModified: new Date().getTime(),
					type: zipAsBlob?.type
				});
				return this.FileUpload.UploadFileWithPromise(zipFile).then((response) => {
					this.valueOfProject = {...this.valueOfProject, downloadLink: response?.Location ?? null};
					resolve(response);
				});
			}).catch((error) => {
				reject(error);
			});
		});
	}


	private checkIfPrototype(documentation: Documentation) {
		// check project dimensions section values
		if (!documentation?.height || !documentation?.length || !documentation?.width) {
			return false;
		}

		// check project cost and time section values
		if (!documentation?.cost || !documentation?.timeAssembly || !documentation?.timeMachine) {
			return false;
		}

		// check if all materials field full
		for (const material of documentation?.materials) {
			if (!material?.qty || !material?.unit || !material?.itemDescription || !material?.dimensions || !material?.material) {
				return false;
			}
		}

		// check prototype photo
		if (!documentation?.documentationFiles?.prototypePhoto) {
			return false;
		}
		return true;
	}

	private checkIfWorkingModel(documentation: Documentation) {
		// check assembly steps section values
		if (documentation?.assemblySteps?.length === 0) {
			return false;
		}

		// check material section values
		if (documentation?.materials?.length === 0) {
			return false;
		}

		// check that at least one tool is being used.
		if (documentation.tools?.length === 0) {
			return false;
		}

		// check working model photo
		if (!documentation.documentationFiles?.workingModelPhoto) {
			return false;
		}
		// check project files
		return documentation?.projectFiles?.length !== 0;

	}

	private checkIfConcept(documentation: Documentation) {
		// check project information section values
		if (!documentation?.projectName || !documentation?.teamMembers || !documentation?.yourNeedKnower) {
			return false;
		}
		if (!documentation?.needKnower && !documentation.needKnowerFreeText) {
			return false;
		}
		// check project description section values
		if (!documentation?.projectNeeded || !documentation?.solutionAddress
			|| !documentation?.solutionOffering) {
			return false;
		}
		// check project categories
		if (this.valueOfProject?.categories?.length === 0) {
			return false;
		}
		// check concept photo
		if (!documentation?.documentationFiles?.conceptPhoto) {
			return false;
		}
		return true;
	}

	public getProjectTypeFromDocumentation(documentation: Documentation) {
		const checkConcept = this.checkIfConcept(documentation);
		if (checkConcept) {
			const checkWorkingModel = this.checkIfWorkingModel(documentation);
			if (checkWorkingModel) {
				const checkPrototype = this.checkIfPrototype(documentation);
				if (checkPrototype) {
					return 4;
				}
				return 3;
			}
			return 2;
		}
		return 1;
	}


	public initNewDocumentation() {
		this.valueOfDocumentation = new Documentation();
		this.valueOfProject = new Project();
	}


	public askToAcceptAsProduct(documentationId: string) {
		const url = '/documentation/approveProduct';
		return this.put(url, {documentationId, requestedToBeProduct: true});
	}

	public generatePdf(htmlBody: string, styles: string) {
		const url = '/documentation/pdf';
		return this.putWithBuffer(url, {htmlBody, styles},);
	}


}
