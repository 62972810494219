<div class="question-box" *ngIf="!isQuestionsAnswered && (documentation$ | async)?.type === 3">
    <div class="question">
        <label class="text">Do you need to update the “Tools Needed” section?
        </label>
        <div class="buttons">
            <button class="regular-button" [class.pressed]="isToolsChange ===QuestionStatus.YES"
                    (click)="onChangeAssociated(QuestionStatus.YES ,'tools')">Yes
            </button>
            <button class="regular-button" [class.pressed]="isToolsChange ===QuestionStatus.NO"
                    (click)="onChangeAssociated(QuestionStatus.NO, 'tools')">No
            </button>
        </div>
    </div>
    <div class="question" *ngIf="checkMaterialsValidityForPrototype()">
        <label class="text">Do you need to update the “Materials” section?
        </label>
        <div class="buttons">
            <button class="regular-button" [class.pressed]="isMaterialsChange ===QuestionStatus.YES"
                    (click)="onChangeAssociated(QuestionStatus.YES ,'materials')">Yes
            </button>
            <button class="regular-button" [class.pressed]="isMaterialsChange ===QuestionStatus.NO"
                    (click)="onChangeAssociated(QuestionStatus.NO, 'materials')">No
            </button>
        </div>
    </div>
    <div class="question">
        <label class="text">Do you need to update the “Assembly Guide” section?
        </label>
        <div class="buttons">
            <button class="regular-button" [class.pressed]="isAssemblyGuideChange === QuestionStatus.YES"
                    (click)="onChangeAssociated(QuestionStatus.YES ,'assembly')">Yes
            </button>
            <button class="regular-button" [class.pressed]="isAssemblyGuideChange === QuestionStatus.NO"
                    (click)="onChangeAssociated(QuestionStatus.NO, 'assembly')">No
            </button>
        </div>
    </div>
    <div class="question">
        <label class="text">Do you need to edit the “Make the Parts” section?
        </label>
        <div class="buttons">
            <button class="regular-button" [class.pressed]="isInstructionsChange === QuestionStatus.YES"
                    (click)="onChangeAssociated(QuestionStatus.YES ,'instruction')">Yes
            </button>
            <button class="regular-button" [class.pressed]="isInstructionsChange === QuestionStatus.NO"
                    (click)="onChangeAssociated(QuestionStatus.NO, 'instruction')">No
            </button>
        </div>
    </div>

</div>

<div class="content" *ngIf="isQuestionsAnswered || (documentation$ | async).type !== 3">

    <app-tools-section *ngIf="isToolsChange !== QuestionStatus.NO"
                       (fieldChange)="fieldChange('tools')"></app-tools-section>
    <mat-divider *ngIf="isToolsChange !== QuestionStatus.NO" class="divider"></mat-divider>

    <app-materials-section *ngIf="isMaterialsChange !== QuestionStatus.NO" (fieldChange)="fieldChange($event)"
                           [isAllFieldsRequired]="true"></app-materials-section>
    <mat-divider *ngIf="isMaterialsChange !==QuestionStatus.NO" class="divider"></mat-divider>

    <app-project-files *ngIf="isMaterialsChange !== QuestionStatus.NO"></app-project-files>
    <mat-divider *ngIf="isMaterialsChange !== QuestionStatus.NO" class="divider"></mat-divider>

    <app-assembly-guide  *ngIf="isAssemblyGuideChange !== QuestionStatus.NO" (fieldChange)="fieldChange($event)"></app-assembly-guide>
    <mat-divider *ngIf="isAssemblyGuideChange !==QuestionStatus.NO" class="divider"></mat-divider>

    <app-special-instructions *ngIf="isInstructionsChange !== QuestionStatus.NO"
                              (fieldChange)="fieldChange($event)"></app-special-instructions>
    <mat-divider *ngIf="isInstructionsChange !== QuestionStatus.NO" class="divider"></mat-divider>

    <app-specifications-section #specificationsSectionComponent (fieldChange)="fieldChange($event)"></app-specifications-section>
    <mat-divider class="divider"></mat-divider>

    <app-photos-videos (fieldChange)="fieldChange($event)"></app-photos-videos>
    <mat-divider class="divider"></mat-divider>

    <button *ngIf="(documentation$ | async)?.type === 4" class="upgrade-button">
        <img class="icon" src="./assets/images/upgrade-icon.png"/>
        <span class="upgrade-text">{{'Go ahead to Product! '}}</span>
    </button>

</div>






