import {AfterViewInit, Component, ElementRef, OnInit, ViewChild,} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {ProjectService} from 'src/app/services/project.service';
import {Router} from '@angular/router';
import {DataService} from 'src/app/services/data.service';
import {HomepagePopService} from 'src/app/services/homepage-pop.service';
import {MatDialog} from '@angular/material/dialog';
import {HomepagePopupComponent} from 'src/app/components/homepage-popup/homepage-popup.component';
import {AuthService} from 'src/app/services/auth.service';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, AfterViewInit {
	public lottieConfig: Object;


	@ViewChild('svgAnimationItem_1', {read: ElementRef})
	svgAnimationItem_1: ElementRef;
	@ViewChild('svgAnimationItem_2', {read: ElementRef})
	svgAnimationItem_2: ElementRef;

	constructor(
		public globals: GlobalsService,
		private projectService: ProjectService,
		private route: Router,
		public dataService: DataService,
		private homepagePopService: HomepagePopService,
		private dialog: MatDialog,
		private authService: AuthService
	) {
		this.dataService.globalUser;
	}

	ngAfterViewInit() {

	}


	ngOnInit() {
		const token = this.authService.getToken();
		if (!token || (token && !localStorage.getItem('pop'))) {
			this.getHomepagePopData();
		}
	}

	getHomepagePopData() {
		this.homepagePopService.getHomepagePopData().subscribe((res) => {
			if (res.show) {
				this.openHomepagePop(res);
			}
		});
	}

	openHomepagePop(data) {
		const dialogRef = this.dialog.open(HomepagePopupComponent, {
			height: '292px',
			maxWidth: '95%',
			// disableClose: true,
			data: data,
		});

		dialogRef.afterClosed().subscribe((result) => {
			localStorage.setItem('pop', 'true');
		});
	}

	// private scrollToTop() {
	// 		let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
	// 		// let currentScroll = window.pageYOffset;
	// 		console.log(currentScroll);
	// 		if (currentScroll > 0) {
	// 			window.requestAnimationFrame(this.scrollToTop);
	// 			window.scrollTo(0, currentScroll - (currentScroll / 5));
	// 		}
	// }
	//
	// handleAnimation(animationContext: any, animationIndex: number) {
	// 	if (animationIndex === 1) {
	// 		this.animationItem_1 = animationContext;
	// 	} else {
	// 		this.animationItem_2 = animationContext;
	// 	}
	// }
	//
	// stop(animationIndex: number) {
	// 	if (animationIndex === 1) {
	// 		this.animationItem_1.stop();
	// 	} else {
	// 		this.animationItem_2.stop();
	// 	}
	// }
	//
	// playAnimation(animationIndex: number) {
	// 	if (animationIndex === 1) {
	// 		this.animationItem_1.play();
	// 	} else {
	// 		this.animationItem_2.play();
	// 	}
	// }
	//
	// // pause() {
	// // 	this.animationItem_1.pause();
	// // }
	//
	// setSpeed(speed: number) {
	// 	this.animationSpeed = speed;
	// 	this.animationItem_1.setSpeed(speed);
	// 	this.animationItem_2.setSpeed(speed);
	// }
}
