<div class="documentation-page">
    <div class="documentation-header">
        <div class="documentation-header__left">
            <div class="logo">
                <img class="pointer" src="assets/images/tom-logo@black.svg" routerLink="/home">
            </div>
            <div class="preview-type" [ngClass]="{'preview-type-selected': previewType === PreviewTypeEnum.pdf}"
                (click)="selectPreviewType(PreviewTypeEnum.pdf)">
                <span class="preview-type__text">{{'Project file'}}</span>
            </div>
        </div>
        <div class="documentation-header__right">
            <div class="preview-type" [ngClass]="{'preview-type-selected': previewType === PreviewTypeEnum.project}"
                (click)="selectPreviewType(PreviewTypeEnum.project)">
                <span class="preview-type__text">{{'Project webpage'}}</span>
            </div>
            <div class="buttons-and-image">
                <div class="profile-image">
                    <img class="pointer profile-image"
                        [class.circle]="userProfileImage !== 'assets/images/person-color.svg'"
                        src="{{userProfileImage}}" routerLink="/profile">
                </div>
                <div class="buttons" [class.disable-events]="isUploading()">
                    <a class="header-btn save-btn" (click)="initUploadingDocumentation()">
                        <span class>{{saveButtonState}}</span>
                        <div class="loader" *ngIf="isUploading()"></div>
                    </a>
                    <a class="header-btn publish-btn" (click)="initPublishDocumentation()"
                        [class.disabled-btn]="isPublishButtonDisabled()">
                        <span class>{{project?.status === 'active' ? 'Published' : 'Publish'}}</span>
                    </a>
                </div>
            </div>

        </div>

    </div>
    <div class="documentation-page__content">
        <div class="editor-area">
            <div class="editor-body">
                <mat-stepper [linear]="true" #stepper class="stepper" orientation="vertical"
                    (selectionChange)="selectionChange($event)">
                    <mat-step [completed]="isChallengeSectionValid" [stepControl]="challengeFormGroup" state="empty"
                        label="Challenge">
                        <ng-template matStepLabel>
                            <div class="step-title" [class.step-title-selected]="isStepSelected(0)" id="0" #0>
                                <span class="step-title__label">
                                    {{'Challenge'}}
                                </span>
                                <mat-icon class="icon">{{ isStepSelected(0) ? 'keyboard_arrow_up' :
                                    'keyboard_arrow_down'}}</mat-icon>
                            </div>
                        </ng-template>
                        <ng-template matStepContent>
                            <app-challenge-section #challengeSection [class.disabled]="!isStepEnabled(0)"
                                *ngIf="documentationLoaded"></app-challenge-section>
                        </ng-template>
                    </mat-step>
                    <mat-step [completed]="isConceptSectionValid" state="empty" label="Concept">
                        <ng-template matStepLabel>
                            <div class="step-title" [class.step-title-selected]="isStepSelected(1)">
                                <span class="step-title__label">
                                    {{'Concept'}}
                                </span>
                                <mat-icon class="icon">{{ isStepSelected(1) ? 'keyboard_arrow_up' :
                                    'keyboard_arrow_down'}}</mat-icon>
                            </div>
                        </ng-template>
                        <ng-template matStepContent>
                            <app-concept-section #conceptSection [class.disabled]="!isStepEnabled(1)"
                                *ngIf="documentationLoaded"></app-concept-section>
                        </ng-template>
                    </mat-step>
                    <mat-step [completed]="isWorkingModelValid" state="empty" label="Working model">
                        <ng-template matStepLabel>
                            <div class="step-title" [class.step-title-selected]="isStepSelected(2)">
                                <span class="step-title__label">
                                    {{'Working Model'}}
                                </span>
                                <mat-icon class="icon">{{ isStepSelected(2) ? 'keyboard_arrow_up' :
                                    'keyboard_arrow_down'}}</mat-icon>
                            </div>
                        </ng-template>
                        <ng-template matStepContent>
                            <app-working-model-section #workingModelSection
                                *ngIf="documentationLoaded && currentStepIndex === 2"
                                [class.disabled]="!isStepEnabled(2)"></app-working-model-section>
                        </ng-template>
                    </mat-step>
                    <mat-step [completed]="isProtoTypeValid" state="empty" label="Prototype">
                        <ng-template matStepLabel>
                            <div class="step-title" [class.step-title-selected]="isStepSelected(3)">
                                <span class="step-title__label">
                                    {{'Prototype'}}
                                </span>
                                <mat-icon class="icon">{{ isStepSelected(3) ? 'keyboard_arrow_up' :
                                    'keyboard_arrow_down'}}</mat-icon>
                            </div>
                        </ng-template>
                        <ng-template matStepContent>
                            <app-prototype-section #prototypeSection
                                *ngIf="documentationLoaded && currentStepIndex === 3"
                                [class.disabled]="!isStepEnabled(3)"></app-prototype-section>
                        </ng-template>
                    </mat-step>
                    <mat-step state="empty" label="Product">
                        <ng-template matStepLabel>
                            <div class="step-title" [class.step-title-selected]="isStepSelected(4)">
                                <span class="step-title__label">
                                    {{'Product'}}
                                </span>
                                <mat-icon class="icon">{{ isStepSelected(4) ? 'keyboard_arrow_up' :
                                    'keyboard_arrow_down'}}</mat-icon>
                            </div>
                        </ng-template>
                        <ng-template matStepContent>
                            <app-product-section *ngIf="documentationLoaded" [class.disabled]="!isStepEnabled(4)"
                                (saveChanges)="initUploadingDocumentation()"></app-product-section>
                        </ng-template>
                    </mat-step>


                    <!-- icon overrides by step states: edit/done/number/error/empty -->
                    <ng-template matStepperIcon="edit" let-index="index">
                        <img [src]="getStepIconSrcByIndex(index)" />
                    </ng-template>
                    <ng-template matStepperIcon="done" let-index="index">
                        <img [src]="getStepIconSrcByIndex(index)" />
                    </ng-template>
                    <ng-template matStepperIcon="number" let-index="index">
                        <img [src]="getStepIconSrcByIndex(index)" />
                    </ng-template>
                    <ng-template matStepperIcon="error" let-index="index">
                        <img [src]="getStepIconSrcByIndex(index)" />
                    </ng-template>
                    <ng-template matStepperIcon="empty" let-index="index">
                        <img [src]="getStepIconSrcByIndex(index)" />
                    </ng-template>
                </mat-stepper>

            </div>


        </div>
        <div class="preview-area">
            <div class="preview-content">
                <!--            pdf view-->
                <app-documentation-preview [hidden]="previewType === PreviewTypeEnum.project"
                    (updateDocumentationEvent)="onUploadingDocumentation($event)">
                </app-documentation-preview>
                <!--            project view-->
                <app-project-page *ngIf="previewType === PreviewTypeEnum.project" [isPreviewOfDocumentation]="true">
                </app-project-page>
            </div>
        </div>
    </div>

    <app-welcome-popup></app-welcome-popup>
</div>