<div class="form">
    <div class="row">
        <div (click)="fileDropRef.click()" appDnd (fileDropped)="onFileDropped($event)" class="upload-files-container">
            <span class="upload-title">
             {{options?.title ? options?.title : (options?.isMultiple ? '+ Add files' : '+ Add file')}}
            </span>
            <span class="upload-subtitle">
                             {{options?.isMultiple ? 'or drop files here' : 'or drop file here'}}
            </span>
            <input #fileDropRef id="fileDropRef"
                   [multiple]="options?.isMultiple"
                   accept="{{options?.formats}}"
                   type="file"
                   (change)="fileBrowseHandler($event)"
                   style="display: none"
                   [disabled]="isDisableState"
            />
        </div>
        <span class="red" *ngIf="options?.required && files?.length === 0">
            Mandatory Field
        </span>
        <div *ngIf="files?.length > 0" class="progress-container">
            <div class="row-wrapper" *ngFor="let file of files; let i = index">
                <div class="file-data">
                    <div class="file">
                        <span>{{file?.name | slice:0:40}}</span>
                        <mat-progress-bar mode="determinate" [value]="file?.progress"></mat-progress-bar>
                    </div>
                    <button *ngIf="['jpg', 'jpeg', 'png'].includes(file?.type?.toLowerCase()) && file?.progress === 100"
                        class="edit-delete-file">
                        <mat-icon (click)="editFile(i)">edit</mat-icon>
                    </button>
                    <button class="edit-delete-file">
                        <img (click)="deleteFile(i)" src="assets/images/close-grey.png" alt="">
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>
