import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DynamicFormModel} from '../../models/dynamic-form';
import {GlobalsService} from '../../services/globals';
import {Destroyable} from '../../shared/destroyable';
import {take, takeUntil} from 'rxjs/operators';
import {DocumentationSystemService} from '../../services/documentation-system.service';
import {UserService} from '../../services/user.service';


@Component({
	selector: 'app-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DynamicFormComponent extends Destroyable implements OnInit {
	myForm: FormGroup;

	public existingValues: any = {};
	@Input() formFields: DynamicFormModel[] | null;
	@Input() projectName: string | null;
	@Output() formChange = new EventEmitter<FormGroup>();
	@Output() fieldChange = new EventEmitter<string>();
	userForm = this.fb.group({
		email: ['', [
			Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
		userName: ['', []],
	});
	emailSent = false;


	constructor(
		public globals: GlobalsService,
		private fb: FormBuilder,
		private documentationService: DocumentationSystemService,
		private userService: UserService
	) {
		super();

	}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		if (!!this.formFields) {
			this.myForm = this.fb.group({});
			this.formFields?.forEach(formItem => {
				if (!!formItem?.value && typeof (formItem?.value) !== 'string') {
					if (Array.isArray(formItem?.value)) {
						this.documentationService.valueOfDocumentation['teamMembersFreeText'] = [];
						this.existingValues[formItem?.id] = formItem.value?.map(value => value.basicUserInformation ?
							`${value.basicUserInformation.firstName} ${value.basicUserInformation.lastName}` :
							value
						);

					} else {
						const fullName = `${formItem.value?.basicUserInformation?.firstName} ${formItem.value?.basicUserInformation?.lastName}`;
						this.existingValues[formItem?.id] = fullName ?? null;
					}
				}
				this.myForm?.addControl(formItem?.id, new FormControl(formItem?.value ? formItem?.value : null, formItem?.validators));
				this.myForm?.get(formItem?.id).valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(formInput => {
					this.fieldChange?.emit(formItem?.id);
				});
			});
			this.formChange?.emit(this.myForm);
		}
		this.subscribeToFormGroup();
	}


	subscribeToFormGroup() {
		this.myForm?.valueChanges?.pipe(takeUntil(this.destroy$))?.subscribe(val => {
			const userName = val?.needKnowerFreeText;
			if (userName) {
				this.userForm.controls['userName'].setValue(val.needKnowerFreeText);
			} else {
				this.userForm.controls['userName'].setValue(null);
			}

			this.formChange?.emit(this.myForm);
		});
	}


	inputChange(fieldName: string) {
		this.fieldChange.emit(fieldName);
	}

	sendEmail(emailForm: FormGroup) {
		if (!this.emailSent) {
			const email = emailForm.get('email').value ?? null;
			const fullName = emailForm.get('userName').value ?? '';
			if (!!email) {
				this.userService.sendInvitationEmail(email, this.projectName ? this.projectName : '')
					.pipe(take(1)).subscribe(response => {
					this.emailSent = true;
				});
			}
		}
	}


}
