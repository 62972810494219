<div class="form-column" *ngIf="form">
    <mat-form-field class="full-width" hideRequiredMarker="true">
        <mat-label>
            {{placeHolder}}
            <img *ngIf="tooltip" src="./assets/images/info-icon/info-icon-grey.png" class="info-icon" tippy="{{tooltip}}"   variation="documentation"/>
        </mat-label>
        <input  aria-label='user'  matInput
               [formControl]="form?.controls['name']"
               [matAutocomplete]="auto" >

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handler($event)">
            <mat-option *ngFor="let user of (userOptions |async)?.items " [value]="user" >
                <img class="small-image" [src]="user?.imageUrl">
                {{user?.basicUserInformation?.firstName}}
                {{user?.basicUserInformation?.lastName}}
            </mat-option>
        </mat-autocomplete>
        <mat-hint class="B4-text" *ngIf="hint">{{hint ?? ''}}</mat-hint>

    </mat-form-field>

</div>
