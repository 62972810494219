import {BrowserModule, Meta} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CanActivateLoggedUser} from './shared/CanActivateLoggedUser';
import {CanActivateNotLoggedUser} from './shared/CanActivateNotLoggedUser';
import {ErrorInterceptor} from './shared/errorInterceptor';
import {JwtInterceptor} from './shared/jwtInterceptor';
import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {MenuComponent} from './components/menu/menu.component';
import {TeamMembersComponent} from './components/team-members/team-members.component';
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {StreetAutoCompleteComponent} from './components/street-auto-complete/street-auto-complete.component';
import {ElementPositionPipe} from './pipes/element-position.pipe';
import {SlideshowModule} from 'ng-simple-slideshow';
import {VideoPopupComponent} from './components/video-popup/video-popup.component';
import {RouterModule} from '@angular/router';
import {OnlyNumber} from './helpers/onlyDigits';
import {MakersPopupComponent} from './components/makers-popup/makers-popup.component';
import {MakerTabComponent} from './components/maker-tab/maker-tab.component';
import {MakerPopupMailComponent} from './components/maker-pop-mail/maker-popup-mail.component';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {WelcomeSectionComponent} from './components/welcome-section/welcome-section.component';
import {AboutSectionComponent} from './components/about-section/about-section.component';
import {JoinSectionComponent} from './components/join-section/join-section.component';
import {PressSectionComponent} from './components/press-section/press-section.component';
import {HomepagePopupComponent} from './components/homepage-popup/homepage-popup.component';
import {SharedModule} from './modules/shared.module';
import {DocumentationSystemService} from './services/documentation-system.service';
import {DocumentationModule} from './modules/documentation.module';

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		HomePageComponent, WelcomeSectionComponent,
		AboutSectionComponent, JoinSectionComponent,
		PressSectionComponent, HomepagePopupComponent,
		AppComponent,
		HeaderComponent,
		FooterComponent,
		OnlyNumber,
		MenuComponent,
		StreetAutoCompleteComponent,
		ElementPositionPipe,
		VideoPopupComponent,
		MakersPopupComponent,
		MakerTabComponent,
		MakerPopupMailComponent,
	],
	imports: [
		BrowserModule,
		ShareIconsModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatInputModule,
		MatTooltipModule,
		MatButtonModule,
		MatSelectModule,
		MatDialogModule,
		SlideshowModule,
		SharedModule,
		RouterModule.forRoot(routes, {anchorScrolling: 'enabled', relativeLinkResolution: 'legacy'}),
		DocumentationModule,
	],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {hasBackdrop: true},
		},
		CanActivateLoggedUser,
		CanActivateNotLoggedUser,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
		},
		Meta,
		DocumentationSystemService,
	],
	exports: [
		TeamMembersComponent,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
