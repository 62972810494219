<!-- Mobile View -->
<div *ngIf="globals.isMobile" class="mobile">
    <div class="footer">
        <div class="stay-updated">
            <div class="title bold">Stay Updated</div>
            <p class="body-text">
                Enter your email address to receive updates with the latest news
                and events.
            </p>
            <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit()">
                <input
                        class="input-email"
                        type="email"
                        placeholder="Email Address"
                        (focus)="emailOnFocus = true"
                        (focusout)="emailOnFocus = false"
                        formControlName="email"
                        (change)="subscribeSuccess = false"
                />
                <div
                        class="form-error"
                        *ngIf="emailOnFocus && controls.email.hasError('required')"
                        style="margin-bottom: 20px"
                >
                    Email is required
                </div>
                <div
                        class="form-error"
                        *ngIf="controls.email.hasError('pattern')"
                        style="margin-bottom: 20px"
                >
                    Invalid email format
                </div>
                <ng-container *ngIf="!subscribeSuccess; else subscribedText">
                    <button
                            class="subscribe-button text-center bold"
                            [disabled]="subscribeForm.invalid"
                            type="submit"
                    >
                        Subscribe
                    </button>
                </ng-container>
                <ng-template #subscribedText>
                    <button class="subscribe-button text-center bold">
                        Subscribe Successfully
                    </button>
                </ng-template>
            </form>
        </div>

        <div class="links-container">
            <div class="links">
                <div class="link-title">Projects</div>
                <div>
					<span (click)="moveToResultsPage(projectTypes.product)"
                    >Products</span
                    >
                </div>
                <div>
					<span (click)="moveToResultsPage(projectTypes.prototype)"
                    >Prototypes</span
                    >
                </div>
                <div>
					<span (click)="moveToResultsPage(projectTypes.work)"
                    >Working Models</span
                    >
                </div>

                <div>
					<span (click)="moveToResultsPage(projectTypes.concept)"
                    >Concepts</span
                    >
                </div>
                <div>
					<span (click)="moveToResultsPage(projectTypes.challenge)"
                    >Challenges</span
                    >
                </div>
                <div class="space"></div>
            </div>

            <div class="links">
                <div class="link-title">TOM</div>
                <div>
                    <span routerLink="/communities">Communities</span>
                </div>
                <div>
                    <span routerLink="/events">Events</span>
                </div>
                <div>
                    <span routerLink="/press">Press</span>
                </div>
                <div>
                    <span (click)="navigateToExternalUrl('https://www.tomuniversity.org/')">TOM@University</span>
                </div>
                <div>
                    <span (click)="navigateToExternalUrl('https://www.tomchallenge.org/')">Open Innovation Challenge</span>
                </div>
                <div>
                    <span routerLink="/contact">Contact Us</span>
                </div>
                <div>
                    <span class="bold" routerLink="/supportTOM">Donate</span>
                </div>
            </div>

            <div class="links">
                <div class="link-title" routerLink="/about">About Us</div>
                <div>
                    <span (click)="navigateToUrl('/about#vision')">Vision</span>
                </div>
                <div>
					<span (click)="navigateToUrl('/about#mission')"
                    >Mission</span
                    >
                </div>
                <div>
					<span (click)="navigateToUrl('/about#process')"
                    >TOM Process</span
                    >
                </div>
                <div>
                    <span (click)="navigateToUrl('/about#team')">Team</span>
                </div>
                <div>
					<span (click)="navigateToUrl('/about#story')"
                    >TOM Story</span
                    >
                </div>
                <div>
					<span (click)="navigateToUrl('/about#reut')"
                    >About Reut</span
                    >
                </div>
                <div>
                    <span (click)="navigateToUrl('/about#kit')">Press Kit</span>
                </div>
            </div>
        </div>

        <div class="follow-us">
            <div class="follow-us-title">FOLLOW US</div>
            <div class="icons">
                <a
                        target="_blank"
                        href="https://www.facebook.com/TikkunOlamMakers/"
                >
                    <img
                            class="small-icon"
                            src="./assets/images/facebook-logo.svg"
                    />
                </a>
                <a target="_blank" href="https://twitter.com/tikkunolammakrs">
                    <img class="small-icon" src="./assets/images/twitter.svg"/>
                </a>
                <a
                        target="_blank"
                        href="https://www.linkedin.com/company/tom---tikkun-olam-makers/"
                >
                    <img
                            class="small-icon"
                            src="./assets/images/linkedin-icon.svg"
                    />
                </a>
                <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCSvUYvIx6HZuvcD3SvI2s8Q/videos"
                >
                    <img
                            class="small-icon"
                            src="./assets/images/youtube-symbol.svg"
                    />
                </a>
                <a
                        target="_blank"
                        href="https://www.instagram.com/tikkunolammakers/"
                >
                    <img
                            class="small-icon"
                            src="./assets/images/instagram.svg"
                    />
                </a>
            </div>
        </div>

        <div class="policy bold">
            <div>
				<span routerLink="/terms" style="font-weight: 200"
                >Terms of Service</span
                >
            </div>
            <div>
				<span routerLink="/privacy" style="font-weight: 200"
                >Privacy Policy</span
                >
            </div>
        </div>
    </div>
</div>
<!-- END Mobile View -->

<!-- Desktop View -->
<div *ngIf="globals.isDesktop" class="desktop">
    <div class="footer">
        <div class="limit-width">
            <div class="limit">
                <div class="logos">
                    <div class="logos-top">
                        <img
                                class="small-icon"
                                src="./assets/images/tom-logo-footer.svg"
                        />
                    </div>
                    <div class="logos-bottom">
                        <a target="_blank" href="https://www.moveo.group/"
                        ><img
                                class="small-icon"
                                src="./assets/images/moveo-logo.svg"
                        /></a>
                    </div>
                </div>

                <div class="links">
                    <div class="link-title">TOM</div>
                    <div routerLink="/communities">Communities</div>
                    <div routerLink="/events">Events</div>
                    <div routerLink="/press">Press</div>
                    <div (click)="navigateToExternalUrl('https://www.tomuniversity.org/')">TOM@University
                    </div>
                    <div (click)="navigateToExternalUrl('https://www.tomchallenge.org/')">Open Innovation Challenge
                    </div>
                    <div routerLink="/contact">Contact Us</div>
                    <div class="bold" routerLink="/supportTOM">Donate</div>
                </div>

                <div class="links">
                    <div class="link-title" routerLink="/about">About Us</div>
                    <div (click)="navigateToUrl('/about#vision')">Vision</div>
                    <div (click)="navigateToUrl('/about#mission')">Mission</div>
                    <div (click)="navigateToUrl('/about#process')">
                        TOM Process
                    </div>
                    <div (click)="navigateToUrl('/about#team')">Team</div>
                    <div (click)="navigateToUrl('/about#story')">TOM Story</div>
                    <div (click)="navigateToUrl('/about#reut')">About Reut</div>
                    <div (click)="navigateToUrl('/about#kit')">Press Kit</div>
                </div>

                <div class="project-legal">
                    <div class="links">
                        <div class="link-title" routerLink="/search">
                            Projects
                        </div>
                        <div (click)="moveToResultsPage(projectTypes.product)">
                            Products
                        </div>
                        <div
                                (click)="moveToResultsPage(projectTypes.prototype)"
                        >
                            Prototypes
                        </div>
                        <div (click)="moveToResultsPage(projectTypes.work)">
                            Working Models
                        </div>

                        <div (click)="moveToResultsPage(projectTypes.concept)">
                            Concepts
                        </div>
                        <div
                                (click)="moveToResultsPage(projectTypes.challenge)"
                        >
                            Challenges
                        </div>
                    </div>
                    <div class="space"></div>
                    <div class="links">
                        <div class="link-title" style="cursor: auto">Legal</div>
                        <div
                                style="opacity: 1; font-weight: 200"
                                routerLink="/terms"
                        >
                            Terms of Service
                        </div>
                        <div
                                style="opacity: 1; font-weight: 200"
                                routerLink="/privacy"
                        >
                            Privacy Policy
                        </div>
                    </div>
                </div>

                <div class="stay-updated">
                    <div class="title bold">Stay Updated</div>
                    <p class="body-text">
                        Enter your email address to receive updates with the
                        latest news and events.
                    </p>
                    <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit()">
                        <input
                                class="input-email"
                                type="email"
                                placeholder="Email Address"
                                (focus)="emailOnFocus = true"
                                (focusout)="emailOnFocus = false"
                                formControlName="email"
                                (change)="subscribeSuccess = false"
                        />
                        <div
                                class="form-error"
                                *ngIf="
								!controls.email.pristine &&
								controls.email.hasError('required')
							"
                        >
                            Email is required
                        </div>
                        <div
                                class="form-error"
                                *ngIf="controls.email.hasError('pattern')"
                        >
                            Invalid email format
                        </div>
                        <ng-container
                                *ngIf="!subscribeSuccess; else subscribedText"
                        >
                            <button
                                    class="subscribe-button text-center bold"
                                    type="submit"
                                    [disabled]="subscribeForm.invalid"
                            >
                                SUBSCRIBE
                            </button>
                        </ng-container>
                        <ng-template #subscribedText>
                            <button class="subscribe-button text-center bold">
                                SUBSCRIBED SUCCESSFULLY
                            </button>
                        </ng-template>
                    </form>

                    <div class="follow-us">
                        <div class="follow-us-title">FOLLOW US</div>
                        <div class="icons">
                            <a
                                    target="_blank"
                                    href="https://www.facebook.com/TikkunOlamMakers/"
                            >
                                <img
                                        class="small-icon"
                                        src="./assets/images/facebook-logo.svg"
                                />
                            </a>
                            <a
                                    target="_blank"
                                    href="https://twitter.com/tikkunolammakrs"
                            >
                                <img
                                        class="small-icon"
                                        src="./assets/images/twitter.svg"
                                />
                            </a>
                            <a
                                    target="_blank"
                                    href="https://www.linkedin.com/company/tom---tikkun-olam-makers/"
                            >
                                <img
                                        class="small-icon"
                                        src="./assets/images/linkedin-icon.svg"
                                />
                            </a>
                            <a
                                    target="_blank"
                                    href="https://www.youtube.com/channel/UCSvUYvIx6HZuvcD3SvI2s8Q/videos"
                            >
                                <img
                                        class="small-icon"
                                        src="./assets/images/youtube-symbol.svg"
                                />
                            </a>
                            <a
                                    target="_blank"
                                    href="https://www.instagram.com/tikkunolammakers/"
                            >
                                <img
                                        class="small-icon"
                                        src="./assets/images/instagram.svg"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END Desktop View -->
