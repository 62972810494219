import {Component, Input, OnInit} from '@angular/core';
import {Maker} from 'src/app/models/maker';
import {Project} from 'src/app/models/project';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {MakerService} from 'src/app/services/maker.service';
import {MatDialog} from '@angular/material/dialog';
import {MakerPopupMailComponent} from '../maker-pop-mail/maker-popup-mail.component';

@Component({
	selector: 'app-maker-tab',
	templateUrl: './maker-tab.component.html',
	styleUrls: ['./maker-tab.component.scss']
})
export class MakerTabComponent implements OnInit {

	@Input() maker: Maker;
	@Input() project: Project;
	@Input() termsChecked: boolean;
	approved = true;
	isTabOpen = false;
	emailSent = false;
	emailValue = '';

	emailRegex = /^[\w\.-]+@[\w-]+\.[\w\.-]+$/;


	constructor(
		private makerService: MakerService,
		private analyticsService: AnalyticsService,
		private dialog: MatDialog,
	) {
	}

	ngOnInit() {

	}

	toggleMakerTab() {
		this.analyticsService.sendEvent('Click on makerspace drawer', 'CHOOSE MAKERSPACE FROM LIST');
		this.isTabOpen = !this.isTabOpen;
	}

	handleSubmit() {
		this.analyticsService.sendEvent('Clicked on button', 'GET QUOTE BUTTON');
		this.approved = false;
		this.checkApproval();
		if (this.emailValue.match(this.emailRegex) && this.approved) {
			this.onSubmitPopUp();

		}
	}

	onSubmitPopUp() {
		let to = [];
		let subject = '';
		let params;
		to = [this.emailValue, this.maker.email, 'admin@tomglobal.org'];
		subject = `Help Me Make This - ${this.project.projectName}`;
		params = {
			makerName: this.maker.name,
			productName: this.project.projectName,
			linkToProject: `tomglobal.org/project?id=${this.project._id}`
		};

		const dialogRef = this.dialog.open(MakerPopupMailComponent, {
			// maxWidth: '700px',
			// minWidth: '700px',
			data: {
				main: to,
				subject: subject,
				params: params,
				title: 'Write something to',
				width: 250,
				height: 250,
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.sentMakersLogic();
				this.sendEmail(res);
			}
		});

	}

	sendEmail(content: string) {
		// to, subject, htmlFileName, callback, params
		const to = [this.emailValue, this.maker.email, 'admin@tomglobal.org'];
		const subject = `Help Me Make This - ${this.project.projectName}`;
		const params = {
			content: content,
			makerName: this.maker.name,
			productName: this.project.projectName,
			linkToProject: `tomglobal.org/project?id=${this.project._id}`
		};

		this.makerService.sendEmailToMaker(to, subject, params).subscribe((resp) => {
			this.analyticsService.sendEvent('Success', 'QUOTE SENT');
		}, err => {
			this.analyticsService.sendEvent('Error', 'QUOTE SENT');
			console.error(err);
		});
	}

	sentMakersLogic() {
		const oldIds = localStorage.getItem('sent');
		localStorage.setItem('sent', `${oldIds},${this.maker._id}-${this.project._id}`);
		this.emailSent = true;
		setTimeout(() => {
			this.toggleMakerTab();
			this.maker.isSent = true;
		}, 1500);
	}

	checkApproval() {
		const isUser = localStorage.getItem('TOM-token');
		const isApproved = this.termsChecked;

		if (isApproved || isUser) {
			this.approved = true;
		}
	}
}
