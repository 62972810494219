import {Component, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Router} from '@angular/router';
import {UserService} from 'src/app/services/user.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectTypes} from '../../models/enums';
import {DataService} from '../../services/data.service';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	subscribeForm: FormGroup;
	projectTypes = ProjectTypes;
	emailOnFocus: boolean = false;
	subscribeSuccess: boolean = false;

	constructor(
		public globals: GlobalsService,
		private router: Router,
		private userService: UserService,
		private dataService: DataService,
		private formBuilder: FormBuilder,
		public analyticsService: AnalyticsService,
	) {
	}

	ngOnInit() {
		const emailRegex = /^[\w\.-]+@[\w-]+\.[\w\.-]+$/;
		this.subscribeForm = this.formBuilder.group({
			email: new FormControl('', Validators.compose([
				Validators.required,
				Validators.pattern(emailRegex)]))
		});
	}

	get controls() {
		return this.subscribeForm.controls;
	}

	navigateToUrl(url: string) {
		this.router.navigateByUrl(url);
	}

	navigateToExternalUrl(url: string) {
		window.open(url, '_blank');
	}

	onSubmit() {
		if (!this.subscribeForm.invalid) {
			// Normalizing input.
			this.analyticsService.sendEvent('email address_subscribe button', 'SUBSCRIBE');

			const email = this.subscribeForm.value.email.toLowerCase().trim();
			this.userService.subscribe(email).subscribe(resp => {
				this.subscribeSuccess = true;
				this.subscribeForm.reset();
				setTimeout(() => {
					this.subscribeSuccess = false;
				}, 9000);
			}, (error) => {
				console.error(error);
			});
		}
	}

	public moveToResultsPage(type?: ProjectTypes) {
		this.dataService.resultPageType = type;
		this.dataService.resultSearchText = '';
		this.router.navigateByUrl(`/results?types=${type}`);
	}
}
