import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import S3FileUpload from '../../helpers/file-upload/s3-file-upload';
import { GlobalsService } from '../../services/globals';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';

export class FileUploadOptions {
	isMultiple?: boolean;
	formats?: string[];
	maxSize?: number;
	index?: number;
	title?: string;
	fieldName?: string;
	required?: boolean;
}

@Component({
	selector: 'app-upload-file-field',
	templateUrl: './upload-file-field.component.html',
	styleUrls: ['./upload-file-field.component.scss']
})
export class UploadFileFieldComponent implements OnInit {

	@ViewChild('fileDropRef') fileDropRef: ElementRef;

	@Input() options: FileUploadOptions;
	@Input() existingFiles: string[] = [];

	@Output() FilesToUpload = new EventEmitter<string[]>();

	disabled = false;
	files: any[] = [];
	filesUrls: string[] = [];

	FileUpload: S3FileUpload;

	constructor(public globals: GlobalsService,
		private dialog: MatDialog) {
		this.FileUpload = new S3FileUpload();
	}

	ngOnInit(): void {
		if (!!this.existingFiles && this.existingFiles?.length > 0) {
			this.setFiles(this.existingFiles);
		}
	}

	get isDisableState() {
		return (!this.options.isMultiple && this.files.length === 1);
	}

	/**
	 * on file drop handler
	 */
	onFileDropped($event) {
		if (!this.isDisableState) { // allow dropping files if its not single option and file already exist.
			this.prepareFilesList($event);
		}
	}

	/**
	 * handle file from browsing
	 */
	fileBrowseHandler($event) {
		this.prepareFilesList($event.target.files);
		this.fileDropRef.nativeElement.value = '';
	}

	/**
	 * Edit file from files list
	 * @param index (File index)
	*/
	editFile(index: number) {
		const image = this.files[index];
		this.FileUpload.DownloadFileAsync(image.name, (res: any) => {
			if (res.success) {
        const type = `image/${image.type}`;
        const blob = new Blob([res.data.Body], { type });
        const file = new File([blob], image.name, { type });
				const dialogRef = this.dialog.open(ImageCropperComponent, {
					maxWidth: '95%',
					maxHeight: '90vh',
					data: {
						file,
						width: 350,
						height: 250
					},
				});
				dialogRef.afterClosed()
					.subscribe(file => {
						if (file) {
							this.files.splice(index, 1);
							this.filesUrls.splice(index, 1);
							this.prepareFilesList([file]);
						}
					})
			} else {
				console.error(res.err);
			}
		});
	}

	/**
	 * Delete file from files list
	 * @param index (File index)
	 */
	deleteFile(index: number) {
		this.files.splice(index, 1);
		this.filesUrls.splice(index, 1);
		this.notifyFilesChanges();
	}

	/**
	 * Simulate the upload process
	 */
	uploadFilesToS3Bucket(index: number) {
		if (index === this.files.length) {
			return;
		}

		if (this.files[index]?.progress === 100) {
			this.uploadFilesToS3Bucket(index + 1);
			return;
		}

		const creationDate = this.files[index]?.creationDate ?? null;
		this.FileUpload.UploadFileWithPromise(this.files[index], (loaded, total) => {
			if (!!this.files[index] && creationDate && this.files[index]?.creationDate === creationDate) { // validate if file hasn't removed.
				this.files[index].progress = Math.round(100 * loaded / total);
			}
		}).then(response => {
			if (!!this.files[index] && creationDate && this.files[index]?.creationDate === creationDate) { // in case file has been deleted in the middle of uploading
				this.setFiles([...this.filesUrls, decodeURIComponent(response?.Location)])
				this.notifyFilesChanges();
			}
			this.uploadFilesToS3Bucket(index + 1);
		}).catch(error => {
			console.log(error);
		});
	}

	/**
	 * Convert Files list to normal array list
	 * @param files (Files List)
	 */
	private prepareFilesList(files: Array<any>) {
		for (const item of files) {
			item.progress = 0;
			item.creationDate = Date.now();
			this.files.push(item);
		}
		this.uploadFilesToS3Bucket(0);
	}

	private setFiles(existingFiles: any[]): void {
		this.filesUrls = existingFiles;
		this.files = existingFiles.map(url => {
			const name = url?.split('/')?.pop();
			const type = name?.substring(name.lastIndexOf('.') + 1, name.length);
			return {
				progress: 100,
				name,
				type,
				url,
				creationDate: Date.now(),
			};
		});
	}

	private notifyFilesChanges() {
		this.FilesToUpload.emit(this.filesUrls);
	}
}
