import {Injectable} from '@angular/core';

declare var gtag: any;

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	sendEvent(action, label) {
		if (action === 'Challenge submission form ') {
			action += location.pathname;
		}
		var category = '';
		switch (location.pathname) {
			case '/':
				category = 'Landing Page';
				break;
			case '/about':
				category = 'About Page';
				break;
			case '/communities':
				category = 'Communities Page';
				break;
			case '/events':
				category = 'Events Page';
				break;
			case '/project':
				category = 'Project Page';
			case '/search':
				category = 'Projects search';
			case '/results':
				category =
					action === 'Search clicked'
						? 'Projects search'
						: 'Projects Page';
				break;
			case '/login':
				category = 'Join TOM';
				break;
			case '/supportTOM':
				category = 'Donate Page';
				break;
			case '/profile':
				category = 'Profile Page';
				break;
			case '/contact':
				category = 'Contact Us';
				break;
			case '/press':
				category = 'Press Page';
				break;
		}

		gtag('event', action, {
			event_category: category,
			event_label: label,
		});
	}
}
