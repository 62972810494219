import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DynamicFormModel } from '../../../models/dynamic-form';
import { FormGroup, Validators } from '@angular/forms';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { dimensionTypes } from '../../../models/documentation';

@Component({
	selector: 'app-specifications-section',
	templateUrl: './specifications-section.component.html',
	styleUrls: ['./specifications-section.component.scss']
})
export class SpecificationsSectionComponent implements OnInit {
	SpecificationsSectionDimensions: DynamicFormModel[];
	SpecificationsSectionCost: DynamicFormModel[];
	SpecificationsSectionTime: DynamicFormModel[];
	documentationCurrentValue = this.documentationService?.valueOfDocumentation;
	isFormValid: boolean = false;


	@Output() fieldChange?: EventEmitter<string> = new EventEmitter<string>();


	constructor(private documentationService: DocumentationSystemService) {
	}

	ngOnInit(): void {
		this.initForms();
	}

	initForms() {
		this.SpecificationsSectionDimensions = [
			{
				id: 'unit',
				question: 'What is the unit of your dimensions?',
				type: 'select',
				errorMessage: 'Mandatory Field',
				options: dimensionTypes.map(item => item?.name).slice(0, 5),
				validators: [
					Validators.required,
				],
				value: this.documentationCurrentValue?.unit ?? '',
			},
			{
				id: 'height',
				question: 'What is the height of your project?',
				type: 'number',
				errorMessage: 'Mandatory Field',
				validators: [
					Validators.required,
				],
				value: this.documentationCurrentValue?.height ?? '',
			},
			{
				id: 'width',
				question: 'What is the width of your project?',
				type: 'number',
				errorMessage: 'Mandatory Field',
				validators: [
					Validators.required,
				],
				value: this.documentationCurrentValue?.width ?? '',

			},
			{
				id: 'length',
				question: 'What is the length of your project?',
				type: 'number',
				errorMessage: 'Mandatory Field',
				validators: [
					Validators.required,
				],
				value: this.documentationCurrentValue?.length ?? '',
			},
		];
		this.SpecificationsSectionCost = [
			{
				id: 'cost',
				question: 'Estimated cost of materials',
				type: 'number',
				errorMessage: 'Mandatory Field',
				validators: [
					Validators.required,
				],
				value: this.documentationCurrentValue?.cost ?? '',
				hint: '$'
			},
		];
		this.SpecificationsSectionTime = [
			{
				id: 'timeAssembly',
				question: 'Estimated project assembly time',
				type: 'number',
				errorMessage: 'Mandatory Field',
				validators: [
					Validators.required,
				],
				value: this.documentationCurrentValue?.timeAssembly ?? '',
				hint: 'Hrs'
			},
			{
				id: 'timeMachine',
				question: 'Estimated machine time',
				type: 'number',
				errorMessage: 'Mandatory Field',
				validators: [
					Validators.required,
				],
				value: this.documentationCurrentValue?.timeMachine ?? '',
				hint: 'Hrs'
			},
		];
	}

	get isValid(): boolean {
		return this.isFormValid;
	}

	dynamicFormChange(form: FormGroup) {

		this.isFormValid = form.valid;

		const updatedDocumentation = this.documentationService?.valueOfDocumentation;
		Object.keys(form?.controls).forEach(key => {
			const formControl = form?.controls[key];
			if (updatedDocumentation?.hasOwnProperty(key)) {
				updatedDocumentation[key] = formControl?.value;
			}
		});
		this.documentationService.valueOfDocumentation = updatedDocumentation;
	}

	inputChange(fieldName: string) {
		this.fieldChange.emit(fieldName);
	}

}
