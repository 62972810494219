import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {GlobalsService} from 'src/app/services/globals';

@Component({
	selector: 'app-about-section',
	templateUrl: './about-section.component.html',
	styleUrls: ['./about-section.component.scss'],
})
export class AboutSectionComponent implements OnInit {
	constructor(
		public globals: GlobalsService,
		public analyticsService: AnalyticsService
	) {
	}

	ngOnInit() {
	}

	goToNewChallengeGoogleForm() {
		this.analyticsService.sendEvent(
			'Challenge submission form /landing page',
			'submitNewChallenge'
		);
		window.open(
			'https://docs.google.com/forms/d/e/1FAIpQLSeJO72bAB1-LwG1sHNcBrFOIZdwMN8xNr5RwjnKVYnn-B4ttw/viewform?gxids=7757',
			'_blank'
		);
	}
}
