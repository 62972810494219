import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { DynamicFormModel } from '../../../models/dynamic-form';
import { MediaInputElement } from '../../../models/documentation';
import { Subscription } from 'rxjs';
import { Project } from '../../../models/project';
import { ProjectService } from '../../../services/project.service';
import { Destroyable } from '../../../shared/destroyable';
import { takeUntil } from 'rxjs/operators';
import { PhotosVideosComponent } from '../photos-videos/photos-videos.component';


@Component({
	selector: 'app-concept-section',
	templateUrl: './concept-section.component.html',
	styleUrls: ['./concept-section.component.scss']
})
export class ConceptSectionComponent extends Destroyable implements OnInit {


	@Output() moveToStep = new EventEmitter();

	notSurePressed = false;
	finalTools = undefined;
	finalMaterials = undefined;
	isFormValid: boolean = false;

	documentationCurrentValue = this.documentationService?.valueOfDocumentation;
	projectCategoryFormControl = new FormControl([]);
	systemCategories = [];


	@ViewChild('photosVideosComponent') photosVideosComponent: PhotosVideosComponent;


	project: Project = null;
	projectDescriptionSection: DynamicFormModel[] = [
		{
			id: 'solutionOffering',
			question: 'What is the Solution you are offering?*',
			type: 'textarea',
			validators: [
				Validators.required,
			],
			errorMessage: 'Mandatory field',
			value: this.documentationCurrentValue?.solutionOffering ?? '',
		},
		{
			id: 'solutionAddress',
			question: 'How will this solution address the challenge?*',
			type: 'textarea',
			validators: [
				Validators.required,
			],
			errorMessage: 'Mandatory field',
			value: this.documentationCurrentValue?.solutionAddress ?? '',

		},

	];

	fileInput: MediaInputElement[] = [
		{
			title: 'Please add a photo of your project concept*',
			options: {
				fieldName: 'conceptPhoto',
				isMultiple: false,
				formats: ['image/*'],
				required: true
			},
			tooltip: 'A concept could be a description of the suggested solution, basic visualization, sketch, or rendering.',
		},
	];

	constructor(
		private documentationService: DocumentationSystemService,
		private projectService: ProjectService) {
		super();

	}

	get isValid(): boolean {
		return this.isFormValid && !!this.photosVideosComponent?.existingFiles?.conceptPhoto;
	}


	ngOnInit(): void {
		this.initForms();
		this.subscribeToFormGroups();
		this.subscribeToProjectChanges();
		this.getCategories();
	}


	initForms() {
		this.projectDescriptionSection.forEach(field => {
			field['value'] = this.documentationCurrentValue[field['id']];
		});
		this.projectCategoryFormControl.setValue(this.documentationService.valueOfProject.categories ?? []);

	}

	subscribeToFormGroups() {
		this.projectCategoryFormControl?.valueChanges?.pipe(takeUntil(this.destroy$))?.subscribe(value => {
			this.documentationService.valueOfProject.categories = value ?? [];
		});
	}

	getCategories() {
		this.projectService.getCategories().subscribe(
			(foundCategories) => {
				if (foundCategories && foundCategories.length) {
					this.systemCategories = foundCategories;
				}
			},
			(error) => {
				console.error(error);
			}
		);
	}

	subscribeToProjectChanges() {
		this.documentationService.projectAsObservable.subscribe(value => {
			this.project = value;
		});
	}

	dynamicFormChange(form: FormGroup) {

		this.isFormValid = form.valid;

		const documentation = this.documentationService.valueOfDocumentation;
		Object.keys(form?.controls).forEach(key => {
			const formControl = form?.controls[key];
			if (documentation?.hasOwnProperty(key)) {
				documentation[key] = formControl?.value;
			}
		});
		this.documentationService.valueOfDocumentation = documentation;
	}

	moveToDifferentStep(index: any) {
		// navigate to prototype - using final materials and tools
		if (index === 3) {
			this.documentationService.valueOfDocumentation.usingFinalMaterials = true;
			this.documentationService.valueOfDocumentation.usingFinalTools = true;
		}
		this.moveToStep.emit(index);
	}

	toggleNotSureState() {
		this.notSurePressed = !this.notSurePressed;
	}

	handleNextStepLogic(value) {
		this.finalTools = value;
		this.documentationService.valueOfDocumentation.usingFinalTools = value === 'true';
		this.finalMaterials = value;
		this.documentationService.valueOfDocumentation.usingFinalMaterials = value === 'true';
		if (this.finalTools === 'true' && this.finalMaterials === 'true') {
			this.moveToDifferentStep(3);
		} else {
			this.moveToDifferentStep(2);
		}
	}

	fieldChange(field: string) {
		this.documentationService.valueOfEditField = field;
	}

}
