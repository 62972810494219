<!-- Desktop Section -->
<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'">
  <div class="about">
    <div class="about-title bold text-center">
      <!-- <span *ngIf="globals.isMobile">Our </span>about -->
      Tikkun Olam Makers
    </div>
    <div class="body-text text-center">
      TOM - Tikkun Olam Makers - is an Israeli-turned-global venture inspired by
      a bold vision calling for improving the lives of 250,000,000 people.
      ’Tikkun Olam’ ("to repair the world" in Hebrew) is a Jewish idea that
      encourages every person to contribute toward improving themselves, their
      community and the world.
    </div>
    <div class="button-container" (click)="goToNewChallengeGoogleForm()">
      <button class="button-challenge" (click)="goToNewChallengeGoogleForm()">
        Submit New Challenge
      </button>
    </div>
  </div>
</div>
