import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {GlobalsService} from './services/globals';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AnalyticsService} from './services/analytics.service';

declare var Accessibility: any;

@Component({
	selector: 'app-root',
	template: `
        <app-header *ngIf="displayHeader"
                    #header
                    (headerFinishedLoading)="activateStickyHeader()"
        ></app-header>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <app-footer *ngIf="displayHeader"></app-footer>
	`,
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	headerHasLoaded: boolean = false;
	displayHeader: boolean = true;
	@ViewChild('header', {read: ElementRef, static: true}) header: ElementRef;

	onActivate(event: Event) {
		window.scrollTo(0, 0);
	}

	constructor(
		public globals: GlobalsService,
		private router: Router,
		private route: ActivatedRoute,
		public analyticsService: AnalyticsService
	) {
	}

	ngOnInit(): void {
		// tslint:disable-next-line:no-unused-expression
		new Accessibility({textPixelMode: true});
		const that = this;
		document
			.querySelector('._access-icon')
			.addEventListener('click', function () {
				that.analyticsService.sendEvent(
					'Accessibility button',
					'Accessibility button'
				);
			});


		this.router.events.forEach(e => {
			if (e instanceof NavigationEnd) {
				this.displayHeader = !(this.route?.root?.firstChild?.snapshot?.data && this.route?.root?.firstChild?.snapshot?.data?.disableDefaultHeader === true);
			}
		});
	}

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		const position = window.pageYOffset;
		if (
			!!this.router.url.includes('about')
			// !this.router.url.includes('community') &&
			// !this.router.url.includes('project') &&
			// !this.router.url.includes('login') &&
			// !this.router.url.includes('press') &&
			// !this.router.url.includes('terms') &&
			// !this.router.url.includes('privacy') &&
			// !this.router.url.includes('event') &&
			// !this.router.url.includes('contact') &&
			// !this.router.url.includes('profile') &&
			// !this.router.url.includes('results') &&
			// !this.router.url.includes('tom/')
		) {
			// if (position > this.header?.nativeElement?.offsetTop + 80) {
			// 	this.globals.setWhiteHeader(false);
			// } else if (
			// 	this.router.url !== '/communities' &&
			// 	this.router.url !== '/events' &&
			// 	this.router.url !== '/search'
			// ) {
			// 	this.globals.setWhiteHeader(true);
			// }
			if (position < this.header?.nativeElement?.offsetTop + 80) {
				this.globals.setWhiteHeader(true);
			} else {
				this.globals.setWhiteHeader(false);
			}
		}
	}

	activateStickyHeader() {
		this.headerHasLoaded = true;
	}
}
