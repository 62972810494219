<form [formGroup]="specialInstructionsForm">
    <span class="B2-dark-text">If there's a need please give specific instructions on the making of some parts, such as
        printing instructions or machine setup, please specify them here - add a photo of the part, explanation and
        mention which item number it belongs to.</span>
    <ng-container formArrayName="specialInstructions">
        <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
            <mat-expansion-panel cdkDrag *ngFor="let instructionGroup of instructions; let i = index;" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="title">
                            Instruction {{i + 1}}
                        </div>
                        <img src="./assets/images/delete-icon.svg" (click)="onIconClick($event, i)" />
                    </mat-panel-title>


                </mat-expansion-panel-header>
                <div [formGroup]="instructionGroup">
                    <!--                <h3>STEP #{{i + 1}}</h3>-->
                    <mat-form-field appearance="fill">
                        <mat-label>Description</mat-label>
                        <textarea class="text-area" #message maxlength="256" matInput cdkTextareaAutosize
                            [formControl]="instructionGroup?.controls['description']"></textarea>
                        <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                    </mat-form-field>
                    <app-upload-file-field (FilesToUpload)="onFileUploaded($event, i)"
                        [existingFiles]="getExistingFileFromInstruction(i)" [options]="optionsForUpload">
                    </app-upload-file-field>
                </div>


            </mat-expansion-panel>

        </mat-accordion>


    </ng-container>
    <button class="add-instruction-btn" (click)="addNewStep()">{{'+ Add Instruction'}}</button>
</form>