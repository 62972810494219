import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { ProjectMaterial } from '../../../models/projectMaterial';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { takeUntil } from 'rxjs/operators';
import { Destroyable } from '../../../shared/destroyable';
import { dimensionTypes } from '../../../models/documentation';
import { v4 as uuidv4 } from 'uuid';
import { FileUploadOptions } from '../../upload-image-field/upload-file-field.component';


@Component({
	selector: 'app-materials-section',
	templateUrl: './materials-section.component.html',
	styleUrls: ['./materials-section.component.scss']
})
export class MaterialsSectionComponent extends Destroyable implements OnInit {

	materialsFormArray: FormArray = this.fb.array([]);
	materialsGuideForm: FormGroup = this.fb.group({ 'materials': this.materialsFormArray });

	@Input() isAllFieldsRequired = false;
	@Output() fieldChange?: EventEmitter<string> = new EventEmitter<string>();

	valuesChangeSubscription: Subscription;
	existingFiles = [];
	optionsForUpload: FileUploadOptions = {
		formats: ['image/*'],
		isMultiple: false,
		title: 'Add Material Image'
	};

	formInitiated = false;
	dimensionTypes = dimensionTypes;

	constructor(private fb: FormBuilder, private documentationService: DocumentationSystemService) {
		super();
	}

	get materialsForm() {
		const formArray = this.materialsGuideForm?.controls['materials'] as FormArray;
		return formArray.controls as FormGroup[];
	}

	get materialsArray() {
		return this.materialsGuideForm?.controls['materials'] as FormArray;
	}

	onIconClick($event, index) {
		$event.stopPropagation();
		this.removeMaterial(index);
	}

	ngOnInit() {
		this.initForms();
		this.subscribeToFormGroups();
		if (this.documentationService?.valueOfDocumentation?.materials?.length === 0) {
			this.addMaterial();
		}
	}

	initForms() {
		if (!!this.documentationService?.valueOfDocumentation?.materials) {
			this.documentationService?.valueOfDocumentation?.materials?.forEach((material, index) => {
				// init table form group.
				const materialId = uuidv4();
				const rowGroup = this.fb.group({
					'itemDescription': [material.itemDescription ?? '', this.isAllFieldsRequired ? [Validators.required] : []],
					'material': [material.material ?? '', this.isAllFieldsRequired ? [Validators.required] : []],
					'manufacturing': [material.manufacturing ?? '', []],
					'dimensions': [material.dimensions ?? '', this.isAllFieldsRequired ? [Validators.required] : []],
					'unit': [material.unit ?? null, this.isAllFieldsRequired ? [Validators.required] : []],
					'qty': [material.qty ?? null, this.isAllFieldsRequired ? [Validators.required] : []],
					'purchaseLink': [material.purchaseLink ?? '', []],
					'imageUrl': [material.imageUrl ?? null],
					'index': materialId
				}, { validators: this.dimensionsUnitValidator });
				rowGroup.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(value => {
					const id = this.materialsArray.controls.findIndex(control => control.get('index').value === materialId);
					this.fieldChange.emit('material' + id.toString());
				});
				this.materialsArray?.push(rowGroup);
				this.existingFiles.push(material?.imageUrl ?? null);

			});
			this.formInitiated = true;
		}
	}

	subscribeToFormGroups() {
		this.valuesChangeSubscription = this.materialsGuideForm?.valueChanges?.subscribe(value => {
			this.documentationService.valueOfDocumentation = {
				...this.documentationService.valueOfDocumentation,
				materials: [...value?.materials]
			};
		});
	}


	addMaterial(newMaterial?: ProjectMaterial) {
		const materialId = uuidv4();
		const material = this.fb.group({
			'itemDescription': [newMaterial && newMaterial.itemDescription ? newMaterial.itemDescription : null, this.isAllFieldsRequired ? [Validators.required] : []],
			'material': [newMaterial && newMaterial.material ? newMaterial.material : null, this.isAllFieldsRequired ? [Validators.required] : []],
			'manufacturing': [newMaterial && newMaterial.manufacturing ? newMaterial.manufacturing : null, []],
			'dimensions': [newMaterial && newMaterial.dimensions ? newMaterial.dimensions : null, this.isAllFieldsRequired ? [Validators.required] : []],
			'unit': [newMaterial && newMaterial.unit ? newMaterial.unit : null, this.isAllFieldsRequired ? [Validators.required] : []],
			'qty': [newMaterial && newMaterial.qty ? newMaterial.qty : null, this.isAllFieldsRequired ? [Validators.required] : []],
			'purchaseLink': [newMaterial && newMaterial.purchaseLink ? newMaterial.purchaseLink : null, []],
			'imageUrl': [newMaterial && newMaterial.imageUrl ? newMaterial.imageUrl : null, []],
			'index': materialId
		}, { validators: this.dimensionsUnitValidator });

		material.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe(value => {
			const id = this.materialsArray.controls.findIndex(control => control.get('index').value === materialId);
			this.fieldChange.emit('material' + id?.toString());
		});
		this.materialsArray?.push(material);

	}

	removeMaterial(materialIndex: number) {
		this.materialsArray.removeAt(materialIndex);
	}

	compareUnits(unitA, unitB) {
		return unitA?.short === unitB?.short;
	}

	dimensionsUnitValidator(form: FormGroup) {
		const dimensions = form.get('dimensions').value;
		const unitControl = form.get('unit');

		if (dimensions && !unitControl.value) {
			unitControl.markAsTouched();
			unitControl.setErrors({ invalidUnit: true });
			return { invalidUnit: true };
		}

		unitControl.setErrors(null);
		return null;
	}

	onFileUploaded(filesUrlsArray: string[], i: number) {
		// delete
		if (Array.isArray(filesUrlsArray) && filesUrlsArray?.length > 0) {
			this.materialsFormArray.controls[i].get('imageUrl').setValue(filesUrlsArray[0]);
		} else {
			this.materialsFormArray.controls[i].get('imageUrl').setValue(null);
		}
	}

	getExistingFileFromInstruction(index: number) {
		if (index > this.existingFiles?.length + 1 || !this.existingFiles[index]) {
			return [];
		} else {
			return Array.isArray(this.existingFiles[index]) ? this.existingFiles[index] : [this.existingFiles[index]];
		}
	}

	drop(event: CdkDragDrop<AbstractControl[]>): void {
		const formArray = this.materialsArray;
		moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex);
		moveItemInArray(formArray.value, event.previousIndex, event.currentIndex);
		this.materialsArray.setValue(formArray.value);
	}
}
