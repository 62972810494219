import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { DocumentationFiles, MediaInputElement } from '../../../models/documentation';

enum ProjectType {
	PRODUCT = 'product',
	CONCEPT = 'concept',
	WORKING_MODEL = 'working_model',
	PROTOTYPE = 'prototype',
}

@Component({
	selector: 'app-photos-videos',
	templateUrl: './photos-videos.component.html',
	styleUrls: ['./photos-videos.component.scss']
})
export class PhotosVideosComponent implements OnInit {


	// default values for prototype
	mediaInputs: MediaInputElement[] = [
		{
			title: 'Cover photo: Please upload the most updated photo of your project.',
			options: {
				fieldName: 'prototypePhoto',
				isMultiple: false,
				formats: ['image/*'],
				title: 'ADD A PHOTO',
				required: true
			}
		},
		{
			title: 'Upload an exploded view of your prototype',
			options: {
				fieldName: 'explodedView',
				isMultiple: false,
				formats: ['image/*'],
				title: 'ADD A PHOTO',
			}
		},
		{
			title: 'Upload a photo of the prototype being used by the Need-Knower',
			options: {
				fieldName: 'beingUsed',
				isMultiple: false,
				formats: ['image/*'],
				title: 'ADD A PHOTO',
			}
		},
		{
			title: 'Upload a photo of the prototype in perspective view',
			options: {
				fieldName: 'perspectiveView',
				isMultiple: false,
				formats: ['image/*'],
				title: 'ADD A PHOTO',
			}
		},
		{
			title: 'Upload a photo of the prototype in front view',
			options: {
				fieldName: 'frontView',
				isMultiple: false,
				formats: ['image/*'],
				title: 'ADD A PHOTO',
			}
		},
		{
			title: 'Upload a photo of the prototype in side view',
			options: {
				fieldName: 'sideView',
				isMultiple: false,
				formats: ['image/*'],
				title: 'ADD A PHOTO',
			}
		},
		{
			title: 'Upload a photo of the prototype in the top view',
			options: {
				fieldName: 'topView',
				isMultiple: false,
				formats: ['image/*'],
				title: 'ADD A PHOTO',
			}
		},
		{
			title: 'Any other photos that you have of the prototype, the Need-Knower or your process?',
			options: {
				fieldName: 'otherPhotos',
				isMultiple: true,
				formats: ['image/*'],
				title: 'ADD PHOTOS',
			}
		},
		{
			title: 'Please add a video of the prototype being used by the Need-Knower.',
			options: {
				fieldName: 'beingUsedVideo',
				isMultiple: false,
				formats: ['video/mp4'],
				title: 'ADD A VIDEO',
			}
		},
		{
			title: 'Project Overview Video. Note: This is relevant for TOM Innovation Challenges.',
			options: {
				fieldName: 'projectOverviewVideo',
				isMultiple: false,
				formats: ['video/mp4'],
				title: 'ADD A VIDEO',
			}
		},
		{
			title: 'Please add any other videos that you have of the prototype, the Need-Knower or your process.',
			options: {
				fieldName: 'otherVideos',
				isMultiple: true,
				formats: ['video/mp4'],
				title: 'ADD VIDEOS',
			}
		},
	];
	files: DocumentationFiles;

	@Input() fileInput;
	@Input() isTOMGlobalChallengeEvent;
	@Output() fieldChange = new EventEmitter<string>();

	constructor(private documentationService: DocumentationSystemService) {

	}

	ngOnInit(): void {
		if (this.documentationService?.valueOfDocumentation?.documentationFiles) {
			this.files = this.documentationService?.valueOfDocumentation?.documentationFiles;
		}

		// this.tomGlobalChallengeCheck();
	}

	getExistingFiles(mediaInput: MediaInputElement): string[] {
		const currentValue = this.files[mediaInput?.options?.fieldName];
		if (mediaInput?.options?.isMultiple && Array.isArray(currentValue)) {
			return currentValue;
		} else {
			return !!currentValue ? [currentValue] : [];
		}
	}

	get existingFiles() {
		return this.files;
	}

	handleFilesToUpload(filesArray: string[], index: number, fieldName: string) {
		if (this.mediaInputs[index]?.options?.isMultiple) {
			this.handleArray(filesArray, fieldName);
		} else {
			this.handleSingleFile(filesArray, fieldName);
		}
		if (fieldName === 'conceptPhoto' || fieldName === 'workingModelPhoto' || fieldName === 'prototypePhoto') {
			this.fieldChange.emit('updatedPhoto');
		} else {
			this.fieldChange.emit(fieldName);
		}
	}

	private handleArray(filesArray: string[], fieldName: string) {
		this.files[fieldName] = filesArray;
		this.documentationService.valueOfDocumentation = {
			...this.documentationService?.valueOfDocumentation,
			documentationFiles: this.files
		};
	}

	private handleSingleFile(filesArray: string[], fieldName: string) {
		if (Array.isArray(filesArray) && filesArray?.length > 0) {
			this.files[fieldName] = filesArray[0];
		} else {
			this.files[fieldName] = null;
		}
		this.documentationService.valueOfDocumentation = {
			...this.documentationService?.valueOfDocumentation,
			documentationFiles: this.files
		};
	}

	tomGlobalChallengeCheck() {
		if (!!this.isTOMGlobalChallengeEvent) {
			this.mediaInputs = this.mediaInputs.map(mediaInput => {
				if (mediaInput?.options?.fieldName === 'projectOverviewVideo') {
					return {
						...mediaInput,
						options: {
							...mediaInput?.options,
							required: true,
						}
					};
				}
				return mediaInput;
			});
		}
	}
}
