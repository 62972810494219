<div class="maker-tab" (click)="toggleMakerTab()">
    <div class="logo-container">
        <img
                src="../../../assets/images/square-default.svg"
                class="logo round"
        />
        <div *ngIf="maker.isSent" class="img-sent tooltip">
            <span class="tooltiptext">Email Sent</span>
            <img src="../../../assets/images/circle_blue.png"/>
            <img class="v-mark" src="../../../assets/images/v_mark.png"/>
        </div>
    </div>
    <div class="info-box">
        <div class="maker-name text-blue">{{ maker.name }}</div>
        <div class="maker-location text-body">
            <div>
                {{
                maker.country.name === "usa"
                    ? (maker.country.name | uppercase)
                    : (maker.country.name | titlecase)
                }}
            </div>
            <div>, {{ maker.city.name | titlecase }}</div>
        </div>
    </div>
    <div [ngClass]="{ arrow: true, open: isTabOpen }"></div>
</div>
<div class="open-tab" *ngIf="isTabOpen">
    <div class="tab-text">
        Fill your email address to connect with the Makerspace and get quote
    </div>
    <input
            type="email"
            [(ngModel)]="emailValue"
            #email="ngModel"
            required
            [pattern]="emailRegex"
            placeholder="Your Email"
    />
    <div
            class="error"
            *ngIf="
			email.touched &&
			(email.errors?.required || email.errors?.pattern) &&
			!emailSent
		"
    >
        Invalid Email
    </div>
    <div class="error" *ngIf="!approved">
        Please agree to terms and conditions
    </div>
    <div class="bottom-text">
        Once you are confirming your email request an inquiry email will be sent
    </div>
    <button
            *ngIf="!emailSent"
            class="btn btn-text-blue"
            (click)="handleSubmit()"
    >
        EMAIL REQUEST
    </button>
    <button *ngIf="emailSent" class="btn btn-blue">
		<span>
			<img class="success" src="./assets/images/path-3.svg"/> </span
        >QUOTE SENT
    </button>
</div>
