import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {DocumentationPageComponent} from '../pages/documentation-page/documentation-page.component';
import {SharedModule} from './shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialsSectionComponent} from '../components/documentions-components/materials-section/materials-section.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {UploadFileFieldComponent} from '../components/upload-image-field/upload-file-field.component';
import {SpecificationsSectionComponent} from '../components/documentions-components/specifications-section/specifications-section.component';
import {AssemblyGuideComponent} from '../components/documentions-components/assembly-guide/assembly-guide.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {WorkingModelSectionComponent} from '../components/documentions-components/working-model-section/working-model-section.component';

import {
	DocumentationBeforeStartSectionComponent
} from '../components/documentions-components/documentation-before-start-section/documentation-before-start-section.component';
import {SpecialInstructionsComponent} from '../components/documentions-components/special-instructions/special-instructions.component';
import {MatDividerModule} from '@angular/material/divider';
import {PhotosVideosComponent} from '../components/documentions-components/photos-videos/photos-videos.component';
import {DocumentationSelectFormComponent} from '../components/documentions-components/documentation-select-form/documentation-select-form.component';
import {DocumentationPreviewComponent} from '../components/documentions-components/documentation-preview/documentation-preview.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ProjectComponentsModule} from './project-components.module';
import {
	ProductSectionComponent
} from '../components/documentions-components/product-section/product-section.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ProjectFilesComponent} from '../components/documentions-components/project-files/project-files.component';
import {BonusSectionComponent} from '../components/documentions-components/bonus-section/bonus-section.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ConceptSectionComponent} from '../components/documentions-components/concept-section/concept-section.component';
import {ChallengeSectionComponent} from '../components/documentions-components/challenge-section/challenge-section.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {WelcomePopupComponent} from '../components/documentions-components/welcome-popup/welcome-popup.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {PrototypeSectionComponent} from '../components/documentions-components/prototype-section/prototype-section.component';
import {ToolsSectionComponent} from '../components/documentions-components/tools-section/tools-section.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TippyModule} from '@ngneat/helipopper';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {
	DocumentationTomGlobalChallengeConditionComponent
} from '../components/documentions-components/documentation-tom-global-challenge-condition/documentation-tom-global-challenge-condition.component';
import { ImageCropperComponent } from '../components/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';


const routes: Routes = [
	{path: '', component: DocumentationPageComponent,},
];

@NgModule({
	declarations: [
		MaterialsSectionComponent,
		ChallengeSectionComponent,
		DocumentationPageComponent,
		UploadFileFieldComponent,
		ImageCropperComponent,
		DocumentationPreviewComponent,
		SpecificationsSectionComponent,
		AssemblyGuideComponent,
		WorkingModelSectionComponent,
		DocumentationSelectFormComponent,
		DocumentationTomGlobalChallengeConditionComponent,
		DocumentationBeforeStartSectionComponent,
		SpecialInstructionsComponent,
		PhotosVideosComponent,
		ProductSectionComponent,
		ProjectFilesComponent,
		BonusSectionComponent,
		ConceptSectionComponent,
		WelcomePopupComponent,
		PrototypeSectionComponent,
		ToolsSectionComponent
	],
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		SharedModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		FormsModule,
		MatInputModule,
		MatSelectModule,
		MatDividerModule,
		MatSlideToggleModule,
		ProjectComponentsModule,
		MatDialogModule,
		MatStepperModule,
		MatButtonModule,
		MatIconModule,
		MatProgressBarModule,
		MatTabsModule,
		MatExpansionModule,
		MatTooltipModule,
		TippyModule,
		MatAutocompleteModule,
		ImageCropperModule
	],
	exports: [
		ChallengeSectionComponent,
		DocumentationPageComponent,
		MaterialsSectionComponent,
		UploadFileFieldComponent,
		SpecificationsSectionComponent,
		DocumentationPreviewComponent,
		AssemblyGuideComponent,
		WorkingModelSectionComponent,
		DocumentationSelectFormComponent,
		DocumentationTomGlobalChallengeConditionComponent,
		DocumentationBeforeStartSectionComponent,
		PhotosVideosComponent,
		ProductSectionComponent,
		ProjectFilesComponent,
		BonusSectionComponent,
		WelcomePopupComponent,
		PrototypeSectionComponent,
		ToolsSectionComponent
	],
})
export class DocumentationModule {
}
