import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Pagination} from '../models/pagination';
import {Observable} from 'rxjs';
import {Project} from '../models/project';

@Injectable({
	providedIn: 'root'
})
export class ProjectService extends HttpService {


	public popular() {
		const url = '/project/popular';
		return this.get(url);
	}

	public allProjects(pagination: Pagination, countryId?: string, type?: number, communityId?: string) {
		const params = pagination.getPaginationAsParams();
		if (countryId) {
			params.countryId = countryId;
		}
		if (type) {
			params.type = type;
		}

		if (communityId) {
			params.communityId = communityId;
		}
		return this.get('/project', params);
	}

	public getAllProjectsByCountry(countryId): Observable<any> {
		const url = '/project/country';
		return this.get(url, {countryId});
	}

	public getProjectById(projectId: string, status?: string) {
		const params = {projectId};
		if (!!status) {
			params['status'] = status;
		}
		return this.get('/project/details', params);
	}

	public getProject(pagination: Pagination, userInput: string, selectedTypes: number[], selectedCategories: any[], tags: string[]) {
		const params = pagination.getPaginationAsParams();
		params.userInput = userInput;
		params.selectedTypes = selectedTypes;
		params.selectedCategories = selectedCategories;
		params.selectedTags = tags;
		return this.get('/project/search', params);
	}

	public getAllProjectsByType(categoryType): Observable<any> {
		const url = '/project/type';
		return this.get(url, {categoryType});
	}

	public createProject(project: Project): Observable<any> {
		const url = `/project`;
		return this.post(url, project);
	}

	public updateProject(project: Project): Observable<any> {
		const url = `/project`;
		return this.put(url, project);
	}

	public getUserProjects() {
		return this.get('/project/user', {});
	}

	public addComment(text: string, projectId: string, userId: string, img?: string) {
		return this.post('/project/comment', {text, projectId, userId, img});
	}

	public getCategories() {
		return this.get('/category/', {});
	}

	public getDownloadLink(projectId: string) {
		return this.get('/project/download', {projectId});
	}

	public getProjectTags() {
		return this.get('/tag/');
	}
}
