import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmptySearchComponent} from '../components/empty-search/empty-search.component';
import {PopupComponent} from '../components/popup/popup.component';
import {MemberInfoComponent} from '../components/member-info/member-info.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {LoaderComponent} from '../components/loader/loader.component';
import {ProjectComponent} from '../components/project/project.component';
import {EventComponent} from '../components/event/event.component';
import {ProjectsSectionComponent} from '../components/projects-section/projects-section.component';
import {EventsSectionComponent} from '../components/events-section/events-section.component';
import {CommunitiesSectionComponent} from '../components/communities-section/communities-section.component';
import {PartnersSectionComponent} from '../components/partners-section/partners-section.component';
import {NewChallengeComponent} from '../components/new-challenge/new-challenge.component';
import {ClickOutsideModule} from 'ng-click-outside';
import {NewChallengeSuggestionComponent} from '../components/new-challenge-suggestion/new-challenge-suggestion.component';
import {EditProfileFormComponent} from '../components/edit-profile-form/edit-profile-form.component';
import {PressComponent} from '../components/press/press.component';
import {TeamMembersComponent} from '../components/team-members/team-members.component';
import {ItemsSliderComponent} from '../components/items-slider/items-slider.component';
import {ItemComponent} from '../components/item/item.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {SafePipe} from '../models/youtubelink.pipe';
import {DynamicFormComponent} from '../components/dynamic-form/dynamic-form.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {UserAutocompleteCheckboxComponent} from '../components/user-autocomplete-checkbox/user-autocomplete-checkbox.component';
import {UserAutocompleteComponent} from '../components/user-autocomplete/user-autocomplete.component';
import {ProfileProjectCardComponent} from '../components/profile-project-card/profile-project-card.component';
import {PopupSliderComponent} from '../components/popup-slider/popup-slider.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DndDirective} from '../directives/drag-and-drop.directive';
import {MatButtonModule} from '@angular/material/button';
import {popperVariation, TippyModule, tooltipVariation} from '@ngneat/helipopper';
import { DragDropModule } from '@angular/cdk/drag-drop';


export function playerFactory() {
	return player;
}

@NgModule({
	declarations: [
		EmptySearchComponent,
		PopupComponent,
		ProjectComponent,
		MemberInfoComponent,
		LoaderComponent,
		EventComponent,
		ProjectsSectionComponent,
		EventsSectionComponent,
		CommunitiesSectionComponent,
		PartnersSectionComponent,
		NewChallengeComponent,
		NewChallengeSuggestionComponent,
		EditProfileFormComponent,
		PressComponent,
		TeamMembersComponent,
		ItemsSliderComponent,
		ItemComponent,
		SafePipe,
		DynamicFormComponent,
		UserAutocompleteCheckboxComponent,
		UserAutocompleteComponent,
		ProfileProjectCardComponent,
		PopupSliderComponent,
		DndDirective,
	],
	imports: [
		CommonModule,
		TippyModule.forRoot({
			defaultVariation: 'tooltip',
			variations: {
				tooltip: tooltipVariation,
				popper: popperVariation,
				documentation: {
					theme: 'documentation',
					arrow: true,
					animation: 'scale',
					trigger: 'mouseenter',
					offset: [0, 5]
				}
			}
		}),
		SlickCarouselModule,
		LazyLoadImageModule,
		LottieModule.forRoot({player: playerFactory}),
		ClickOutsideModule,
		MatInputModule,
		MatTooltipModule,
		MatFormFieldModule,
		MatSelectModule,
		ReactiveFormsModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatIconModule,
		MatDialogModule,
		MatButtonModule,
	],
	exports: [
		DragDropModule,
		ItemsSliderComponent,
		ItemComponent,
		EmptySearchComponent,
		EventComponent,
		PopupComponent,
		ProjectComponent,
		MemberInfoComponent,
		LazyLoadImageModule,
		SlickCarouselModule,
		LoaderComponent,
		ProjectsSectionComponent,
		EventsSectionComponent,
		CommunitiesSectionComponent,
		PartnersSectionComponent,
		NewChallengeComponent,
		NewChallengeSuggestionComponent,
		EditProfileFormComponent,
		PressComponent,
		TeamMembersComponent,
		DynamicFormComponent,
		UserAutocompleteCheckboxComponent,
		UserAutocompleteComponent,
		ProfileProjectCardComponent,
		PopupSliderComponent,
		DndDirective,
	],
})
export class SharedModule {
}
