import {Component, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {ProjectService} from 'src/app/services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {CountryService} from '../../services/country.service';
import {CommunityService} from '../../services/community.service';
import {EventService} from '../../services/event.service';
import {Country} from '../../models/country';
import {Pagination} from '../../models/pagination';
import {PressService} from '../../services/press.service';
import {Destroyable} from '../../shared/destroyable';
import {takeUntil} from 'rxjs/operators';

@Component({
	selector: 'app-country-page',
	templateUrl: './country-page.component.html',
	styleUrls: ['./country-page.component.scss'],
})
export class CountryPageComponent extends Destroyable implements OnInit {

	constructor(
		public globals: GlobalsService,
		private activateRoute: ActivatedRoute,
		private projectService: ProjectService,
		private eventService: EventService,
		private countryService: CountryService,
		private pressService: PressService,
		private router: Router,
		private communityService: CommunityService,
	) {
		super();
	}

	public countryId: string;
	public countryName: string;
	public country: Country;
	countryFlag: string = null;
	communitiesItems: any[];
	projectsItems: any[];
	eventsItems: any[];
	pressList: any[];
	queryParamSubscription: Subscription;


	ngOnInit() {
		// query param
		this.queryParamSubscription = this.activateRoute.queryParams.subscribe(param => {
			if (this.countryId !== param?.id) {
				this.countryId = param?.id;
				this.getIdFromQueryParam();
			}
		});
		// path param
		this.activateRoute.params.pipe(takeUntil(this.destroy$)).subscribe(param => {
			if (!!param?.countryName && param?.countryName !== this.countryName) {
				this.countryName = param?.countryName?.split('-').join(' ')?.toLowerCase();
				this.getCountryByName();
			}
		});
	}

		getIdFromQueryParam() {
		// this.projectId = this.activateRoute.snapshot.queryParams.id;
		if (!this.countryId || this.countryId === 'undefined') {
			this.router.navigateByUrl('/search');
		} else {
			this.getCountryById();
		}
	}

	private getCountryById() {
		this.countryService.getCountryBaseData(this.countryId).subscribe(
			(resp) => {
				if (!resp) {
					this.router?.navigateByUrl('/search');
					return;
				}
				this.country = resp;
				this.countryFlag = resp?.alpha2Code?.toLowerCase();
				this.getCountryProjects();
				this.getCountryCommunities();
				this.getCountryEvents();
				this.getCountryPress();
			},
			(err) => {
			}
		);
	}

	private getCountryByName() {
		this.countryService.getCountyByName(this.countryName).subscribe(
			(resp) => {
				if (!resp) {
					this.router?.navigateByUrl('/community');
					return;
				}
				this.countryId = resp?._id;
				this.country = resp;
				this.countryFlag = resp?.alpha2Code?.toLowerCase();
				this.getCountryProjects();
				this.getCountryCommunities();
				this.getCountryEvents();
				this.getCountryPress();
			},
			(err) => {
			}
		);
	}

	private getCountryPress() {
		const pressLimit: Pagination = new Pagination(4);
		this.pressService.getAllPressItems(pressLimit, this.country?._id, undefined, undefined).subscribe(resp => {
			this.pressList = [...resp?.items];
			console.log('press list', this.pressList);
		}, err => {
			console.log(err);
		});
	}

	public showAllCommunityPress() {
		const pressLimit: Pagination = new Pagination(10000);
		this.pressList = [];
		this.pressService.getAllPressItems(pressLimit, this.country?._id, undefined, undefined).subscribe(resp => {
			this.pressList = [...resp.items];
		}, err => {
			console.error(err);
		});
	}

	public showLessCommunityPress() {
		const pressLimit: Pagination = new Pagination(4);
		this.pressList = [];
		this.pressService.getAllPressItems(pressLimit, this.country?._id, undefined, undefined).subscribe(resp => {
			this.pressList = [...resp.items];
		}, err => {
			console.error(err);
		});
	}

	private getCountryCommunities() {
		this.communityService.getAllCommunitiesByCountry(this.country?._id).subscribe(resp => {
			this.communitiesItems = resp?.map(community => {
				const communityAsItem = {
					_id: community?._id,
					name: community?.name,
					image: community?.socialMediaInformation?.logoUrl,
					details: community?.socialMediaInformation?.aboutTheCommunity
				};
				return communityAsItem;
			});
		}, err => {
		});
	}

	private getCountryEvents() {
		this.eventService.getCountryEvents(this.country?._id).subscribe(resp => {
			this.eventsItems = resp?.map(event => {
				const eventAsItem = {
					_id: event?._id,
					name: event?.name,
					image: event?.imageUrl,
					details: event?.thumbnailText
				};
				return eventAsItem;
			});
		}, err => {
		});
	}

	private getCountryProjects() {
		this.projectService.getAllProjectsByCountry(this.country?._id).subscribe(resp => {
			this.projectsItems = resp?.map(project => {
				const projectAsItem = {
					_id: project?._id,
					projectName: project?.projectName,
					description: project?.description,
					type: project?.type,
					imagesUrls: null,
				};
				projectAsItem['imagesUrls'] = Array.isArray(project?.imagesUrls) && project?.imagesUrls?.length > 0 ? [project?.imagesUrls?.[0]] : [null];
				return projectAsItem;
			});
		}, err => {
		});
	}

	public itemClicked(item: any, type: string) {
		this.router.navigateByUrl(`/${type}?id=${item?._id}`);
	}


}
