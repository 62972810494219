import {AssemblyStep} from './step';
import {ProjectMaterial} from './projectMaterial';
import {Community} from './community';
import {User} from './user';
import {Project} from './project';
import {FileUploadOptions} from '../components/upload-image-field/upload-file-field.component';
import {EventModel} from './event';

export interface SpecialInstruction {
	description?: string;
	imageUrl?: string;
}


export class DocumentationFiles {
	updatedPhoto: string;
	conceptPhoto: string;
	workingModelPhoto: string;
	prototypePhoto: string;
	productPhoto: string;
	explodedView: string;
	beingUsed: string;
	perspectiveView: string;
	frontView: string;
	sideView: string;
	topView: string;
	otherPhotos: string[];
	beingUsedVideo: string;
	projectOverviewVideo: string;
	otherVideos: string[];
	needKnower: string;
}

export interface BonusFile {
	description?: string;
	fileUrl?: string | File;
}

export class Eligibility {
	replicable?: boolean;
	affordability?: boolean;
	customization?: boolean;
	userTesting?: boolean;
}

interface ToolGroup {
	name: string;
	tools: string[];
}

export interface BonusInformation {
	files: BonusFile[];
	notes: string;
}

export interface MediaInputElement {
	title: string;
	options: FileUploadOptions;
	tooltip?: string;
}

export const basicToolsGroup: ToolGroup[] = [
	{
		name: 'Protection Gear',
		tools: [
			'Heavy duty gloves',
			'Protective glasses',
			'Face shield',
			'Protective headphones',
		],
	},
	{
		name: 'Computer and software',
		tools: [
			'PC or MAC I7 +',
			'Fusion 360',
			'Prusa slicer',
		],
	},
	{
		name: 'Hand Toolkit',
		tools: [
			'Measurement tool',
			'Digital caliber',
			'Leveler',
			'Clamps',
			'Vise grip',
			'Screwdriver set',
			'Ring\Open key set',
			'Wooden chop set',
			'Allen keys set',
			'Ratchet + Boxes set',
			'Pliers',
			'Needle-nose pliers',
			'Cutter',
			'Hammer',
			'Rubber Hammar',
			'Metal scissors',
			'scissors',
			'Monkey wrench',
			'Staple gun',
			'Silicone gun',
			'Stanley knife',
			'Pull saw',
			'Leather punture',
			'Pvc pipe cutter',
			'Wood Filing Tool',
			'Nail Gun'
		],
	},
	{
		name: 'Textile',
		tools: [
			'sewing machine',
			'scissors',
			'set of needles',
		],
	},
	{
		name: 'Electric Toolkit',
		tools: [
			'Electric Srewdriver',
			'Agle Grinder',
			'Hot air blower',
			'Jigsaw',
			'Circular saw',
			'Dremel',
			'Hot glue gun'
		],
	},
	{
		name: '3D Printers',
		tools: [
			'FDM Printer'
		],
	},
	{
		name: 'Electronics',
		tools: [
			'F-Crimp',
			'Stand',
			'Soldering set',
			'Voltmeter',
			'Cable stripper',
			'Tester',
		]
	}
];
export const otherToolsGroup: ToolGroup[] = [
	{
		name: 'Photography Studio',
		tools: [
			'Flash Trigger',
			'Dslr Camera',
			'Flash',
			'Photography Softbox Lighting Kit',
			'Studio Backdrop',
			'studio backdrop stand'
		],
	},
	{
		name: 'Heavy Machinery',
		tools: [
			'CNC Machine',
			'Laser Cutter'
		],
	},
];

export interface DimensionType {
	name: string;
	short: string;
}


export const dimensionTypes: DimensionType[] = [
	{
		name: 'Centimeters',
		short: 'cm'
	},
	{
		name: 'Meters',
		short: 'm'
	},
	{
		name: 'Inches',
		short: 'in'
	},
	{
		name: 'Feet',
		short: 'ft'
	},
	{
		name: 'Millimeters',
		short: 'ml'
	},
	{
		name: 'Grams',
		short: 'gr'
	},
	{
		name: 'Kilograms',
		short: 'kg'
	}

];

export class Documentation {
	id: string = '';
	_id?: string = '';
	projectName?: string = '';
	event?: string | EventModel = null;
	project?: string | Project = null;
	community?: string | Community = null;
	needKnower?: any = null;
	needKnowerFreeText?: string = '';
	teamMembers?: any[] = [];
	teamMembersFreeText?: any[] = [];
	createdBy?: string | User = null;
	updatedBy?: string | User = null;
	yourNeedKnower?: string = '';
	projectNeeded?: string = '';
	solutionAddress?: string = '';
	solutionOffering?: string = '';
	height?: string = '';
	unit?: string = null;
	width?: string = '';
	length?: string = '';
	cost?: string = '';
	timeAssembly?: string = '';
	timeMachine?: string = '';
	assemblySteps?: AssemblyStep[] = [];
	specialInstructions?: SpecialInstruction[] = [];
	materials?: ProjectMaterial[] = [];
	tools?: string[] = [];
	documentationFiles?: DocumentationFiles = {
		updatedPhoto: null,
		conceptPhoto: null,
		workingModelPhoto: null,
		prototypePhoto: null,
		productPhoto: null,
		explodedView: null,
		needKnower: null,
		beingUsed: null,
		perspectiveView: null,
		frontView: null,
		sideView: null,
		topView: null,
		otherPhotos: [],
		beingUsedVideo: null,
		projectOverviewVideo: null,
		otherVideos: [],
	};
	eligibleToBeProduct?: boolean = false;
	requestedToBeProduct?: boolean = false;
	eligibility?: Eligibility = {
		replicable: null,
		affordability: null,
		customization: null,
		userTesting: null,
	};
	projectFiles?: (string | File)[] = [];
	bonusInformation?: BonusInformation = {
		files: [],
		notes: null
	};
	type?: number = 0; // 0 - before challenge!
	usingFinalTools?: boolean = false;
	usingFinalMaterials?: boolean = false;
}


export const documentationPdfStyles = `


thead {
    background-color: #e5e5e5;
}

table, th, td {
    border: 1px dashed #b5b5b5;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
}

th, td {
    padding: 10px 5px;
    font-weight: normal;
}

li::marker {
    font-size: 24px;
    line-height: 40px;
}


.doc-wrapper {
    width: 100%;
    height: 100%;

}


.pdf-page {
    width: 850px;
    height: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.flex-start {
    justify-content: flex-start;
}

.space-between {
    justify-content: space-between;
}

.img-adjust {
    margin-bottom: 3%;
    width: 125px;
}

.header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    margin-bottom: 3%;
    height: 10%;
    width: 100%;
}

.page-body {
    width: 85%;
    height: 86%;
    display: flex;
    flex-direction: column;
}

.sub-header {
    display: flex;
    text-align: center;
    font-size: 14px;
    justify-content: center;
    font-weight: 100;
}

.project-name-wrapper {
    height: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.divider-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 4%;
}

.divider {
    width: 90%;
    display: flex;
    border-width: 1px 0 0 0;
    border-color: #C9C9C9;
    border-style: dashed;
}

.names-wrapper {
    width: 100%;
    /*height: 8%;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.name-wrapper {
    width: 90%;
    height: 15%;
    display: flex;
    flex-direction: column;
}

.input-header {
    font-weight: 100;
    font-size: 14px;
}

.input-name {
    font-size: 14px;
    font-weight: 700;
}

.project-name {
    font-size: 24px;
    display: flex;
    height: 140%;
    align-items: flex-end;
    width: 90%;
    justify-content: flex-start;
    font-weight: 700;
}


.page-footer {
    width: 100%;
    height: 4%;
    background: #C9C9C9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-header {
    text-align: center;
    font-size: 14px;
    font-weight: 100;
}

.wwhw-main-wrapper {
    height: 100%;
}

.wwhw-wrapper {
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
}

.wwhw-inner-wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.w-q {
    font-weight: 700;
    font-size: 14px;
}

.w-a {
    width: 85%;
    font-weight: 100;
    line-break: auto;
    font-size: 16px;

}

.link {
    font-size: 24px;
    margin-bottom: 5%;
    text-align: center;
}

.sub-body-top-wrapper {
    height: 16%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    justify-content: space-evenly;
    margin-bottom: 2%;

}

.element-wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

.sub-body-top-wrapper-dim {
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
}

.content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    justify-content: space-evenly;
    margin-bottom: 2%;
    height: 70%;
}

.element-wrapper-dim {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.images {
    display: flex;
    width: 100%;
    gap: 20px;
    /*height: 45%;*/
    justify-content: center;
    align-items: center;
}

.img-view {
    /*width: 45%;*/
    height: 100%;
    border-radius: 5px;
    margin: 10px;
}

.img-front-view {
    /*width: 45%;*/
    /*height: 45%;*/
    border-radius: 5px;
    /*margin-top: 5%;*/
    /*margin-bottom: 5%;*/
    display: flex;
    margin: 0 auto;
    /*margin-left: 25%;*/
    justify-content: center;
    align-items: center;
}

.files {
    font-weight: bold;
    font-size: 22px;
}

.files-link {
    font-size: 22px;
    text-align: left;
    text-decoration-line: underline;
    color: blue;
    margin-top: 5%;
}

.tools-wrapper {
    padding: 5% 0 0 5%;
    background-color: #f0f0f0;
    height: 92%;
}

.list-wrapper {
    font-size: 24px;
    font-weight: 500;
}

.assembly-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 5%;
}

.assembly-button {
    width: 100%;
    font-size: 16px;
    background: transparent;
    text-decoration: underline;
    color: blue;
    height: 5%;
    text-align: end;
}

.assembly-wrapper {
    height: 83%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.assembly-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 48%;
}

.step-header {
    font-size: 22px;
    font-weight: 900;
}

.sub-header-divider-wrapper {
    margin-bottom: 8%;

}

.step-description {
    width: 40%;
    height: 100%;
}

.step-description-content {
    font-size: 14px;
    line-break: auto;
}


.red-title {
    color: red;
    font-size: 20px;
    font-weight: bold;
}

.step-img {
    max-width: 300px;
    max-height: 350px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-border {
    width: 300px;
    height: 350px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.views-border {
    width: 300px;
    height: 400px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.views-img {
    max-width: 300px;
    max-height: 400px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-border {
    width: 500px;
    height: 400px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-project {
    max-width: 500px;
    max-height: 400px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-exploded {
    max-width: 500px;
    max-height: 400px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.perspective-img {
    max-width: 350px;
    max-height: 250px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.perspective-border {
    width: 350px;
    height: 250px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.img-project-wrapper {
    max-width: 500px;
    max-height: 400px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin-top: 20%;*/
}

.make-parts-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 5%;
}

.make-parts-button {
    width: 100%;
    font-size: 16px;
    background: transparent;
    text-decoration: underline;
    color: blue;
    height: 5%;
    text-align: end;
}

.make-parts-wrapper {
    height: 45%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.make-parts-column {
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    width: 45%;
}

.page-body-make-parts {
    width: 85%;
    height: 100%;
    flex-direction: column;
}

.make-parts-img {
    max-width: 300px;
    max-height: 350px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.make-parts-img-border {
    width: 300px;
    height: 350px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border: 1px solid;
}

.make-parts-description {
    /*width: 50%;*/
    height: fit-content;
}

.make-parts-description-content {
    font-size: 14px;
    line-break: auto;
}

.text-and-img-wrapper {
    width: 100%;
    height: min-content;
    margin-top: 50px;
}

.img {
    width: 50px;
    height: 50px;
    float: left;
}

.text {
    margin-left: 10%;
    font-size: 25px;
    position: relative;
}

.wrapper-adjust {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.step-tools {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.tool {
    font-size: 12px;
}

.material-img {
    max-height: 100px;
}

`;

export const tom_logo_color_url = 'https://tom-prod-new.s3-eu-west-1.amazonaws.com/tom-logo-color.1670831268063.png';

export const tom_logo_color_mobile_url =
	'https://tom-prod-new.s3-eu-west-1.amazonaws.com/tom-logo-color%40mobile.1670831284745.png';

export const tom_circle_url =
	'https://tom-prod-new.s3-eu-west-1.amazonaws.com/tom-Circle.1670830680754.png';


export const tom_square_url =
	'https://tom-prod-new.s3-eu-west-1.amazonaws.com/tom-Square.1670830693300.png';

export const tom_triangular_url =
	'https://tom-prod-new.s3-eu-west-1.amazonaws.com/tom-triangular.1670830713125.png';


export const unique_project_preview_fields = ['community', 'needKnower'];
export const unique_pdf_preview_fields = ['material', 'step', 'beingUsed', 'instruction'];
