import {Component, Input, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {Router} from '@angular/router';


@Component({
	selector: 'app-other-projects',
	templateUrl: './related-projects.component.html',
	styleUrls: ['./related-projects.component.scss']
})
export class RelatedProjectsComponent implements OnInit {
	@Input() otherProject: any;
	logoUrl;

	constructor(public globals: GlobalsService, private router: Router) {
	}

	ngOnInit() {
		this.logoUrl = (this.otherProject.imagesUrls && this.otherProject.imagesUrls.length) > 0 ? this.otherProject.imagesUrls[0] : './assets/images/rectangle-default.svg';
	}

	navigateToProjectPage() {
		this.router.navigateByUrl(`/project?id=${this.otherProject._id}`);
	}
}
