<!---------- Mobile View ---------->
<div *ngIf="globals.isMobile" class="mobile">
    <ng-template #loader>
        <app-loader></app-loader>
    </ng-template>
    <div class="project-page" *ngIf="project; else loader">
        <!--		<img [src]="getTypeMobile()" class="project-page-nav" />-->
        <div class="project-page-status">
            <img
                    class="project-page-nav"
                    height="50px"
                    [src]="'../../../assets/images/' + headerName()"
            />
        </div>

        <div class="project-page-header">
            <!-- <div>Project</div> -->
            <div class="project-page-title bold">{{ project?.projectName }}</div>
            <div *ngIf="project?.relatedEventId?.startingDate" class="date text-black">
                {{ project.relatedEventId?.startingDate }}
            </div>
            <!-- <div class="progress">
                <app-project-progress
                    [step]="project.type - 1"
                ></app-project-progress>
            </div> -->
            <div
                    class="project-photo"
                    *ngIf="project.type !== projectTypes.challenge"
            >
                <app-photo-slider
                        [imagesUrls]="project.imagesUrls"
                ></app-photo-slider>
            </div>
            <div
                    *ngIf="project.type === projectTypes.challenge"
                    class="challenge-photo"
                    [style.background]="
					project.additionalInformation.challengeImage
						? 'url(' +
						  project.additionalInformation.challengeImage +
						  ')'
						: 'url(./assets/images/rectangle-default.svg)'
				"
                    [style.background-size]="'cover'"
            ></div>
        </div>
        <!-- <div *ngIf="project.type === projectTypes.challenge" class="challenge-photo"
            [style.background]="(project.additionalInformation.challengeImage) ? ('url(' + project.additionalInformation.challengeImage +')') : ('url(./assets/images/rectangle-default.svg)')"
            [style.background-size]="'cover'"></div> -->
        <!-- <div class="project-photo" *ngIf="project.type !== projectTypes.challenge">
            <app-photo-slider [imagesUrls]="project.imagesUrls"></app-photo-slider>
        </div> -->

        <div class="share">
            <app-share></app-share>
        </div>
        <!-- <div class="challenge-cta-section" *ngIf="isChallenge">

            <button class="btn-green" *ngIf="project.relatedEventId" (click)="gotoEvent()">GO TO EVENT</button>

        </div> -->
        <div class="project-info">
            <div class="community">
                <div
                        class="community-photo round"
                        [style.background]="
						project.communityId.socialMediaInformation.logoUrl
							? 'url(' +
							  project.communityId.socialMediaInformation
									.logoUrl +
							  ')'
							: 'url(./assets/images/rectangle-default.svg)'
					"
                        [style.background-size]="'cover'"
                >
					<span
                            class="flag round flag-icon + {{
							flagIcon
						}} flag-icon-squared"
                    ></span>
                </div>

                <div
                        class="community-name text-body-bold"
                        [routerLink]="['/community']"
                        [queryParams]="{ id: project.communityId._id }"
                >
                    {{ project.communityId.name }}
                </div>
            </div>
            <div class="sponsor" *ngIf="mainSponsor">
                <div
                        class="sponsor-photo round"
                        [style.background]="
						mainSponsor?.sponsorLogoUrl
							? 'url(' +
							  mainSponsor?.sponsorLogoUrl +
							  ')'
							: 'url(./assets/images/rectangle-default.svg)'
					"
                        [style.background-size]="'cover'"
                >
                </div>
                <div class="sponsor-details">
                    <div class="sponsor-title">
                        {{mainSponsor?.descriptionType === 1 ? "In honor of" : "Sponsored by"}}
                    </div>
                    <div
                            class="sponsor-name text-body-bold"
                            [routerLink]="['/sponsor']"
                            [queryParams]="{ id: mainSponsor._id }"
                    >
                        {{ mainSponsor?.name }}
                    </div>
                </div>

            </div>

            <div *ngIf="!isChallenge" class="project-info-title bold">
                Description
            </div>

            <div *ngIf="!isChallenge" class="description">
                {{ project.description }}
            </div>
            <!-- <div class="date" style="margin-top: 20px;color:#494949;">Created date: {{date}}</div> -->
            <button
                    *ngIf="!isChallenge && !isPreviewOfDocumentation"
                    (click)="downloadClicked()"
                    class="btn-blue bold row-center"
            >
                <!-- <img class="mr-5" src="./assets/images/download-arrow.svg" /> -->
                Download
            </button>
            <div *ngIf="!isChallenge" class="download-num text-center">
                {{ project.downloadCount }} downloads
            </div>
            <button
                    *ngIf="isProduct"
                    (click)="openMakersPopup()"
                    class="maker-button btn-blue bold row-center mt-4"
            >
                Choose A Makerspace
            </button>
            <!-- <button class="btn-blue bold flex-center ml-15" routerLink="/supportTOM">
                <img class="mr-5" src="./assets/images/heart-white.svg">
                DONATE
            </button> -->
            <div *ngIf="isProduct" class="download-num text-center">
                {{ project.ordersCount || 0 }} orders
            </div>
        </div>
        <div class="border"></div>

        <div class="section">
            <!-- <div class="section-title bold">
                The Challenge -->
            <!--<div class="minimize">-</div>-->
            <!-- </div> -->

            <!-- <div class="challenge-photo" [style.background]="(project.additionalInformation.challengeImage) ? ('url(' + project.additionalInformation.challengeImage +')') : ('url(./assets/images/rectangle-default.svg)')"
             [style.background-size]="'cover'"></div> -->

            <!-- <div class="team-name bold">
                Team {{project.additionalInformation.teamName}}
            </div> -->
            <!-- <div class="date bold text-gray">{{project.relatedEventId?.startingDate}}</div> -->

            <div class="team-info bold">
                About {{ project.additionalInformation.teamName }}
                <p>
                    {{ project.additionalInformation.disabledPersonDetails }}
                </p>
            </div>

            <div class="team-info bold">
                The Challenge
                <p>
                    {{ project.additionalInformation.challengeDetails }}
                </p>
            </div>

            <div class="team-info bold">
                Team Requirements
                <p>
                    {{ project.additionalInformation.teamRequirements }}
                </p>
            </div>

            <button
                    class="btn-blue opposite"
                    *ngIf="project.relatedEventId"
                    (click)="gotoEvent()">
                Go To Event
            </button>
        </div>
        <div class="border"></div>

        <div class="section">
            <div class="section-title bold no-margin">
                Contributing teams
                <!--<div class="minimize">-</div>-->
            </div>
            <div
                    class="team"
                    *ngFor="let relatedCommunityMembers of relatedMembers"
            >
                <app-team-members
                        [communityWithUsers]="relatedCommunityMembers"
                ></app-team-members>
                <div class="border"></div>
            </div>
        </div>
        <div class="section" *ngIf="relatedProjects?.length > 0">
            <div class="section-title bold no-margin">
                Other project versions
                <!--<div class="minimize">-</div>-->
            </div>
            <div
                    class="team"
                    *ngFor="let relatedProject of relatedProjects"
            >
                <app-other-projects
                        [otherProject]="relatedProject"
                ></app-other-projects>
                <div class="border"></div>
            </div>
        </div>

        <ng-container *ngIf="!isChallenge">
            <div class="section">
                <div class="section-title bold">
                    About
                    <!--<div class="minimize">-</div>-->
                </div>

                <div [innerHTML]="renderedHtmlResource" class="resource"></div>

            </div>
            <div class="team-info bold">
                <div *ngIf="project.technicalRequirements">
                    Technical Requirements
                </div>
                <div *ngFor="let tech of project.technicalRequirements">
                    <p>
                        {{ tech | titlecase }}
                    </p>
                </div>
            </div>
            <div class="border"></div>

            <!--  Comments -->
            <div class="section" *ngIf="!isPreviewOfDocumentation">
                <div class="section-title bold">
                    Comments • {{ project.comments?.length }}
                    <!--<div class="minimize">-</div>-->
                </div>
                <app-comments
                        (commentSucceed)="commentAdded($event)"
                        [projectId]="projectId"
                        [comments]="project?.comments"
                ></app-comments>
            </div>
        </ng-container>
    </div>
</div>

<!---------------------------------------------- Desktop View  ---------------------------------------------------->
<div *ngIf="globals.isDesktop" class="desktop">
    <ng-template #loader>
        <app-loader></app-loader>
    </ng-template>
    <div class="limit-width">
        <div class="project-page" *ngIf="project; else loader">
            <!-- <div class="project-page-header">
                {{
                    project.type !== projectTypes.challenge
                        ? "Project"
                        : "Challenge"
                }}
            </div> -->
            <div class="nav-container" *ngIf="!isPreviewOfDocumentation">
                <img [src]="getType()" class="project-nav"/>
            </div>
            <div class="page-body">
                <div class="left">
                    <div
                            class="project-photo"
                            *ngIf="project.type !== projectTypes.challenge"
                    >
                        <app-photo-slider
                                [imagesUrls]="project.imagesUrls"
                        ></app-photo-slider>
                    </div>

                    <div class="selectors" *ngIf="!isChallenge">
                        <div
                                *ngFor="let item of items; let i = index"
                                class="selector"
                                [ngStyle]="{width: isPreviewOfDocumentation? 'calc(100% / 2)' : 'calc(100% / 3)'}"
                                [ngClass]="item.selected ? 'selected' : ''"
                                (click)="selectItem(item)"
                        >
                            {{ item.text }}
                        </div>
                    </div>

                    <div class="section" [hidden]="!items[0]?.selected">
                        <div class="section-title bold" [hidden]="isChallenge">
                            About
                        </div>
                        <div [innerHTML]="renderedHtmlResource" class="resource"></div>

                        <div class="team-info bold mt-5">
                            <div *ngIf="project?.technicalRequirements">
                                Technical Requirements
                            </div>
                            <div
                                    *ngFor="
									let tech of project.technicalRequirements
								"
                            >
                                <ul class="tech">
                                    <li>{{ tech | titlecase }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="section" [hidden]="!isChallenge">
                        <div class="team-name bold">
                            {{ project.projectName }}
                        </div>
                        <!-- <div class="date bold text-black">
                            {{ project.relatedEventId?.startingDate }}
                        </div> -->
                        <div
                                class="challenge-photo"
                                [style.background]="
								project?.additionalInformation?.challengeImage
									? 'url(' +
									  project.additionalInformation
											.challengeImage +
									  ')'
									: 'url(./assets/images/rectangle-default.svg)'
							"
                                [style.background-size]="'cover'"
                        ></div>
                        <div class="team-info bold">
                            Potential Users
                            <p>
                                {{
                                project?.additionalInformation?.disabledPersonDetails
                                }}
                            </p>
                        </div>
                        <div class="team-info bold">
                            About {{ project?.additionalInformation?.teamName }}
                            <p>
                                {{
                                project?.additionalInformation?.challengeDetails
                                }}
                            </p>
                        </div>
                    </div>

                    <div class="section" [hidden]="!items[1]?.selected">
                        <div class="team-name bold">
                            {{ project?.projectName }}
                        </div>
                        <!-- <div class="date bold text-black">
                            {{ project.relatedEventId?.startingDate }}
                        </div> -->
                        <div
                                class="challenge-photo"
                                [style.background]="
								project?.additionalInformation?.challengeImage
									? 'url(' +
									  project.additionalInformation
											.challengeImage +
									  ')'
									: 'url(./assets/images/rectangle-default.svg)'
							"
                                [style.background-size]="'cover'"
                        ></div>
                        <div class="team-info bold">
                            Potential Users
                            <p>
                                {{
                                project?.additionalInformation?.disabledPersonDetails
                                }}
                            </p>
                        </div>
                        <div class="team-info bold">
                            About {{ project?.additionalInformation?.teamName }}
                            <p>
                                {{
                                project?.additionalInformation?.challengeDetails
                                }}
                            </p>
                        </div>

                        <div class="button-container">
                            <button
                                    *ngIf="project.relatedEventId"
                                    class="button-go-to-event"
                                    (click)="gotoEvent()"
                            >
                                Go To Event
                            </button>
                        </div>
                    </div>

                    <!--  Comments -->
                    <div *ngIf="!isPreviewOfDocumentation" class="section" [hidden]="!items[2]?.selected">
                        <div class="section-title bold">Comments</div>
                        <app-comments
                                (commentSucceed)="commentAdded($event)"
                                [projectId]="projectId"
                                [comments]="project.comments"
                        ></app-comments>
                    </div>
                </div>
                <div class="right">
                    <div class="sponsor" *ngIf="mainSponsor">
                        <div class="sponsor-title">
                            {{mainSponsor?.descriptionType === 1 ? "In honor of" : "Sponsored by"}}
                        </div>
                        <div class="sponsor-details">
                            <div class="sponsor-name bold pointer"
                                 [routerLink]="['/sponsor']"
                                 [queryParams]="{ id: mainSponsor?._id }"
                            >
                                {{mainSponsor?.name}}
                                <span class="sponsor-link">Learn More</span>
                            </div>
                        </div>
                    </div>
                    <div class="community">
                        <div
                                style="
								height: 100%;
								width: 70px;
								margin-right: 18px;
							"
                        >
                            <div
                                    class="community-photo round"
                                    [style.background]="
									project?.communityId?.socialMediaInformation?.logoUrl
										? 'url(' +
										  project.communityId
												.socialMediaInformation
												.logoUrl +
										  ')'
										: 'url(./assets/images/rectangle-default.svg)'
								"
                                    [style.background-size]="'cover'"
                            >
								<span
                                        class="flag round flag-icon + {{
										flagIcon
									}} flag-icon-squared"
                                ></span>
                            </div>
                        </div>
                        <div class="community-details">
                            <div
                                    class="community-name bold pointer"
                                    [routerLink]="['/community']"
                                    [queryParams]="{ id: project?.communityId?._id }"
                            >
                                {{ project?.communityId?.name }}
                                <span class="team-type">Main team</span>
                            </div>
                            <div class="project-name bold">
                                {{ project.projectName }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="progress">
                        <app-project-progress
                            [step]="project.type - 1"
                        ></app-project-progress>
                    </div> -->
                    <div class="project-info" *ngIf="!isChallenge">
                        <!-- <div class="project-info-title bold">Description</div> -->
                        <div class="description">
                            {{ project.description || "..." }}
                        </div>
                        <!-- <div class="date" style="margin-top: 20px;color:#494949;">Created date: {{date}}</div> -->
                        <div class="button-flex" *ngIf="!isPreviewOfDocumentation">
                            <div>
                                <div class="btn-container">
                                    <button
                                            [disabled]="!project.downloadLink"
                                            (click)="downloadClicked()"
                                            [ngClass]="{
											'change-green': project.downloadLink
										}"
                                            class="btn-project-download"
                                    >
                                        <!-- <svg
                                            class="mr-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="18"
                                            viewBox="0 0 14 18"
                                        >
                                            <g fill="#FFF" fill-rule="nonzero">
                                                <path
                                                    d="M12.52 5.842l-4.478 4.243V.118H5.958v9.967L1.48 5.842.02 7.224 7 13.836l6.98-6.612zM1.27 15.908H12.73v1.974H1.27z"
                                                />
                                            </g>
                                        </svg> -->
                                        Download
                                    </button>
                                </div>
                                <div class="download-num text-center">
                                    {{ project?.downloadCount }} downloads
                                </div>
                            </div>
                            <div *ngIf="isProduct">
                                <div class="btn-container">
                                    <button
                                            (click)="openMakersPopup()"
                                            class="btn-project-makerspace"
                                    >
                                        Choose A Makerspace
                                    </button>
                                </div>
                                <div class="download-num text-center">
                                    {{ project?.ordersCount || 0 }} orders
                                </div>
                            </div>
                        </div>
                        <!-- <button class="btn-blue bold flex-center ml-15" routerLink="/supportTOM">
                    <img class="mr-5" src="./assets/images/heart-white.svg">
                    DONATE
                </button> -->
                        <div class="row"></div>
                    </div>

                    <!-- event date for challenge -->
                    <!--<ng-container *ngIf="isChallenge">-->
                    <!--<div class="date bold text-gray">Date</div>-->
                    <!--<div class="big-date bold text-gray">{{eventDateRange}}</div>-->
                    <!--</ng-container>-->

                    <!-- join event button for challenges -->
                    <div
                            class="challenge-cta-section"
                            *ngIf="isChallenge"
                            style="margin-bottom: 20px"
                    >
                        <button
                                *ngIf="project?.relatedEventId"
                                class="btn-blue"
                                (click)="gotoEvent()"
                        >
                            Go To Event
                        </button>
                    </div>

                    <div class="share" *ngIf="!isPreviewOfDocumentation">
                        <app-share></app-share>
                    </div>
                    <div
                            class="section"
                            *ngIf="!isChallenge && relatedMembers.length > 0"
                    >
                        <div class="team-title bold no-margin">Contributing teams</div>
                        <div
                                class="team"
                                *ngFor="
								let relatedCommunityMembers of relatedMembers
							"
                        >
                            <app-team-members
                                    [communityWithUsers]="relatedCommunityMembers"
                            ></app-team-members>
                        </div>
                    </div>
                    <div
                            class="section"
                            *ngIf="relatedProjects?.length > 0"
                    >
                        <div class="team-title bold no-margin">Other Project Versions</div>
                        <div
                                class="team"
                                *ngFor="
								let relatedProject of  relatedProjects
							"
                        >
                            <app-other-projects
                                    [otherProject]="relatedProject"
                            ></app-other-projects>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
