import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DocumentationSystemService } from '../../../services/documentation-system.service';
import { basicToolsGroup, otherToolsGroup } from '../../../models/documentation';
import { PhotosVideosComponent } from '../photos-videos/photos-videos.component';


@Component({
	selector: 'app-tools-section',
	templateUrl: './tools-section.component.html',
	styleUrls: ['./tools-section.component.scss']
})
export class ToolsSectionComponent implements OnInit, OnDestroy {
	valuesChangeSubscription: Subscription;
	documentationCurrentValue = this.documentationService?.valueOfDocumentation;
	documentation$ = this.documentationService?.documentationAsObservable;

	// materials section
	// basicSquareTools = new FormControl([]);
	// otherTools = new FormControl([]);
	basicToolsFormGroup = new FormGroup({});
	otherToolsFormGroup = new FormGroup({});
	basicToolsGroup = basicToolsGroup;
	otherToolsGroup = otherToolsGroup;
	toolsFormGroup = new FormGroup({ basicTools: this.basicToolsFormGroup, otherTools: this.otherToolsFormGroup });
	@Output() fieldChange?: EventEmitter<string> = new EventEmitter<string>();

	constructor(private fb: FormBuilder, private documentationService: DocumentationSystemService) {
	}


	ngOnInit() {
		this.initFormGroup();
		this.subscribeToFormGroups();
	}


	initFormGroup() {
		this.basicToolsGroup.forEach((toolGroup, index) => {
			this.basicToolsFormGroup.addControl(index.toString(), new FormControl([]));
		});
		this.otherToolsGroup.forEach((toolGroup, index) => {
			this.otherToolsFormGroup.addControl(index.toString(), new FormControl([]));
		});

		this.documentationCurrentValue?.tools?.forEach(tool => {
			this.basicToolsGroup.forEach((basicToolGroup, index) => {
				if (basicToolGroup?.tools?.includes(tool)) {
					this.basicToolsFormGroup.controls[index?.toString()].setValue([...this.basicToolsFormGroup?.controls[index.toString()].value, tool]);
				}
			});
			this.otherToolsGroup.forEach((otherToolGroup, index) => {
				if (otherToolGroup?.tools?.includes(tool)) {
					this.otherToolsFormGroup.controls[index?.toString()].setValue([...this.otherToolsFormGroup?.controls[index.toString()].value, tool]);
				}
			});
		});
	}

	subscribeToFormGroups() {
		this.valuesChangeSubscription = this.toolsFormGroup?.valueChanges?.subscribe(value => {
			this.fieldChange.emit();
			let tools = [];
			Object.keys(value?.basicTools).forEach(key => {
				tools = [...tools, ...value.basicTools[key]];
			});
			Object.keys(value?.otherTools).forEach(key => {
				tools = [...tools, ...value.otherTools[key]];
			});
			this.documentationService.valueOfDocumentation = {
				...this.documentationService.valueOfDocumentation,
				tools: tools
			};
		});
	}

	ngOnDestroy(): void {
		this.valuesChangeSubscription.unsubscribe();
	}

	get isAtLeastOneTool(): boolean {
		let isAtLeastOneTool: boolean = true;
		this.documentation$?.subscribe((documentation) => { isAtLeastOneTool = documentation?.tools?.length > 0 })
		return isAtLeastOneTool;
	}


}
