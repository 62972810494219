<!-- Desktop Section  -->
<div *ngIf="globals.isDesktop" class="desktop">
    <div class="welcome">
        <div class="limit-text">
            <div class="welcome-content">
                <p class="welcome-title bold">
                    <span class="changes">Change</span> 250,000,000 Lives
                </p>
                <div class="welcome-video">
                    <img
                            (click)="onVideoClicked()"
                            class="pointer"
                            src="./assets/images/group.svg"
                            alt="placeholder image for a play"
                    />
                    <p (click)="onVideoClicked()" class="pointer">
                        Watch Video
                    </p>
                </div>
                <div class="welcome-cta-buttons">
                    <div class="join-container" [routerLink]="['/login']">
                        <button
                                *ngIf="dataService && !dataService.isUserLoggedIn"
                                class="join-tom-button bold"
                        >
                            Join Tom
                        </button>
                    </div>
                    <div
                            class="learn-more-container"
                            (click)="
							analyticsService.sendEvent(
								'Learn More',
								'Learn More'
							)
						"
                            [routerLink]="['/about']"
                    >
                        <button class="learn-more-button bold">
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="inherit-height hero-image fade" [@fadeAnimation]="state" (@fadeAnimation.done)="dissolveNextImage()"-->
        <!--[ngStyle]="{'background-image': 'url(' + currentImage + ')'}">-->
        <!--</div>-->

        <div id="cf" *ngIf="globals.isPreloadFinished; else smallSizeImg">
            <img
                    class="first"
                    style="height: 836px"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-1.png' : 'tomnewbg/tomnew1.png'
				}}"
            />
            <img
                    class="sec"
                    style="height: 836px"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-2.png' : 'tomnewbg/tomnew2.jpg'
				}}"
            />
            <img
                    class="thrd"
                    style="height: 836px"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-3.png' : 'tomnewbg/tomnew3.jpg'
				}}"
            />
            <img
                    class="mobile-small"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-1.png' : 'welcome-m-1.png'
				}}"
            />
            <img
                    class="mobile-small"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-2.png' : 'welcome-m-2.png'
				}}"
            />
            <img
                    class="mobile-small"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-3.png' : 'welcome-m-3.png'
				}}"
            />
        </div>
        <ng-template #smallSizeImg>
            <img
                    class="low-size-image"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-1.jpg' : 'welcome-m-1.jpg'
				}}"
            />
        </ng-template>
    </div>
</div>

<!-- Mobile Section -->
<div *ngIf="globals.isMobile" class="mobile">
    <div class="welcome">
        <div class="limit-text">
            <div class="welcome-content">
                <p class="welcome-title bold">
                    <span class="changes">Change</span> 250,000,000 Lives
                </p>
                <div class="welcome-video">
                    <img
                            (click)="onVideoClicked()"
                            class="pointer"
                            src="./assets/images/group.svg"
                            alt="placeholder image for a play"
                    />
                    <p (click)="onVideoClicked()" class="pointer">
                        Watch Video
                    </p>
                </div>
                <div class="welcome-cta-buttons">
                    <button
                            *ngIf="dataService && !dataService.isUserLoggedIn"
                            class="join-tom-button bold"
                            [routerLink]="['/login']"
                    >
                        Join Tom
                    </button>
                    <button
                            class="learn-more-button bold"
                            (click)="
							analyticsService.sendEvent(
								'Learn More',
								'Learn More'
							)
						"
                            [routerLink]="['/about']"
                    >
                        Learn More
                    </button>
                </div>
            </div>
        </div>
        <!--<div class="inherit-height hero-image fade" [@fadeAnimation]="state" (@fadeAnimation.done)="dissolveNextImage()"-->
        <!--[ngStyle]="{'background-image': 'url(' + currentImage + ')'}">-->
        <!--</div>-->

        <div id="cf" *ngIf="globals.isPreloadFinished; else smallSizeImg">
            <img
                    class="first"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-1.png' : 'tomnewbg/tomnew1.png'
				}}"
            />
            <img
                    class="sec"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-2.png' : 'tomnewbg/tomnew2.jpg'
				}}"
            />
            <img
                    class="thrd"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-3.png' : 'tomnewbg/tomnew3.jpg'
				}}"
            />
            <img
                    class="mobile-small"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-1.png' : 'welcome-m-1.png'
				}}"
            />
            <img
                    class="mobile-small"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-2.png' : 'welcome-m-2.png'
				}}"
            />
            <img
                    class="mobile-small"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-3.png' : 'welcome-m-3.png'
				}}"
            />
        </div>
        <ng-template #smallSizeImg>
            <img
                    class="low-size-image"
                    src="./assets/images/{{
					globals.isDesktop ? 'welcome-1.jpg' : 'welcome-m-1.jpg'
				}}"
            />
        </ng-template>
    </div>
</div>
