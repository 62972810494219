<ng-container [formGroup]="myForm">
    <form class="dynamic-form">
        <ng-container *ngFor="let field of formFields; let i = index;">
            <mat-label class="B2-dark-text" *ngIf="field?.title">
                {{field.title}}
            </mat-label>
            <ng-container [ngSwitch]="field?.type">
<!--                text field-->
                    <mat-form-field class="check-red" *ngSwitchCase="'text'" appearance="fill" hideRequiredMarker="true" >
                        <mat-label class="centered">
                            {{field?.question}}
                            <img alt="" *ngIf="field?.tooltip" src="./assets/images/info-icon/info-icon-grey.png" class="info-icon"
                                 tippy="{{field?.tooltip}}" variation="documentation"/>
                        </mat-label>
                        <input matInput formControlName="{{field?.id}}" [value]="field?.value" >
                        <mat-hint class="B4-text">{{field?.hint ?? ''}}</mat-hint>
                        <mat-error *ngIf="myForm?.get(field?.id)?.invalid">{{field?.errorMessage}}</mat-error>
                    </mat-form-field>

<!--                select field -->
                <mat-form-field appearance="fill" *ngSwitchCase="'select'">
                    <mat-label>{{field?.question}}</mat-label>
                    <mat-select formControlName="{{field?.id}}">
                        <mat-option *ngFor="let option of field?.options" [value]="option" class="form-text">
                            {{option}}
                        </mat-option>
                    </mat-select>
                    <mat-hint class="B4-text">{{field?.hint ?? ''}}</mat-hint>
                    <mat-error *ngIf="myForm?.get(field?.id)?.invalid">{{field?.errorMessage}}</mat-error>
                </mat-form-field>

<!--                text area field -->
                    <mat-form-field appearance="fill" hideRequiredMarker="true" *ngSwitchCase="'textarea'">
                        <mat-label >{{field?.question}}</mat-label>
                        <textarea #message maxlength="256" matInput formControlName="{{field?.id}}"
                                  [value]="field?.value" cdkTextareaAutosize></textarea>
                        <mat-hint class="B4-text" align="end">{{message.value.length}} / 256</mat-hint>
                        <mat-error *ngIf="myForm?.get(field?.id)?.invalid">{{field?.errorMessage}}</mat-error>
                    </mat-form-field>

<!--                number field-->
                <mat-form-field *ngSwitchCase="'number'" appearance="fill" class="form-text">
                    <mat-label>{{field?.question}}
                        <img alt="" *ngIf="field?.tooltip" src="./assets/images/info-icon/info-icon-grey.png" class="info-icon" tippy="{{field?.tooltip}}"
                             variation="documentation"/>
                    </mat-label>
                    <input type="number" matInput min="0" formControlName="{{field?.id}}" [value]="field?.value">
                    <mat-hint class="B4-text">{{field?.hint ?? ''}}</mat-hint>

                    <mat-error *ngIf="myForm?.get(field?.id)?.invalid">{{field?.errorMessage}}</mat-error>
                </mat-form-field>

<!--                auto complete with checkbox-->
                <app-user-autocomplete-checkbox *ngSwitchCase="'multi-select'" [startList]="existingValues[field?.id]"
                                                [required]="true"
                                                placeHolder="{{field?.question}}"
                                                hint="{{field?.hint}}"
                                                [errorText]="field?.errorMessage"
                                                [projectName]="(documentationService?.projectAsObservable | async)?.projectName"
                                                (fieldChange)="inputChange(field?.id)"
                                                [control]="myForm?.controls[field?.id]">
                </app-user-autocomplete-checkbox>

<!--                auto complete single-->
                <app-user-autocomplete *ngSwitchCase="'autocomplete'" [startText]="existingValues[field?.id]"
                                       placeHolder="{{field?.question}}"
                                       hint="{{field?.hint}}"
                                       tooltip="{{field?.tooltip}}"
                                       [projectName]="(documentationService?.projectAsObservable | async)?.projectName"
                                       (fieldChange)="inputChange(field?.id)"
                                       [formControl]="myForm?.controls[field?.id]" ngDefaultControl>
                </app-user-autocomplete>

                <div *ngIf="field?.id === 'needKnower'" class="email-section">
                    <mat-label class="B2-dark-text" *ngIf="field?.title">
                        {{"You can invite your Need Knower to open a TOM profile."}}
                    </mat-label>
                    <div [formGroup]="userForm" class="email-form-control">
                        <mat-form-field appearance="fill" hideRequiredMarker="true">
                            <mat-label>Add {{userForm.controls['userName']?.value ?? 'Team Member'}} email to invite them to join</mat-label>
                            <input matInput formControlName="email" [value]="userForm.get('email')?.value">
                        </mat-form-field>
                        <button class="email-button"
                                [ngClass]="{'blue' : !emailSent, 'green': emailSent}"
                                (click)="sendEmail(userForm)" type="button"
                                *ngIf="userForm?.get('email')?.valid && userForm?.get('email')?.touched">{{emailSent ? 'Sent!' : 'Send'}}
                        </button>
                    </div>
                </div>
            </ng-container>
        </ng-container>

    </form>
</ng-container>
