import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GlobalsService} from '../../../services/globals';
import {Validators} from '@angular/forms';
import {DocumentationSystemService} from '../../../services/documentation-system.service';
import {MediaInputElement} from '../../../models/documentation';


@Component({
	selector: 'app-product-section',
	templateUrl: './product-section.component.html',
	styleUrls: ['./product-section.component.scss']
})
export class ProductSectionComponent implements OnInit {
	// subscriptions
	documentationCurrentValue = this.documentationService?.valueOfDocumentation;

	// emitters
	@Output() saveChanges = new EventEmitter();


	// component variables
	productEligibilitySection = [
		{
			id: 'replicable',
			question: 'Would you consider this project easily replicable?*',
			value: this.documentationCurrentValue?.eligibility?.replicable,
			validators: [Validators.required],
			errorMessage: 'NOT VALID',
		},
		{
			id: 'affordability',
			question: 'Would you consider this project relatively affordable?*',
			value: this.documentationCurrentValue?.eligibility?.affordability,
			validators: [Validators.required],
			errorMessage: 'NOT VALID',
		},
		{
			id: 'customization',
			question: 'Would you consider your project to be easily customizable?*',
			value: this.documentationCurrentValue?.eligibility?.customization,
			validators: [Validators.required],
			errorMessage: 'NOT VALID',
		},
		{
			id: 'userTesting',
			question: 'Has this project been tested on more than one Need-Knower (i.e. has this been tested on someone that is not your original Need-Knower)?*',
			value: this.documentationCurrentValue?.eligibility?.userTesting,
			validators: [Validators.required],
			errorMessage: 'NOT VALID',
		},
	];
	isEligibleToProduct: boolean = false;
	fileInput: MediaInputElement[] = [
		{
			title: 'Upload a photo of your product*',
			options: {
				fieldName: 'productPhoto',
				isMultiple: false,
				formats: ['image/*'],
				required: true
			}
		},
	];


	constructor(
		public globals: GlobalsService,
		private documentationService: DocumentationSystemService,
	) {
	}

	applyForProduct() {
		this.documentationService.valueOfDocumentation.requestedToBeProduct = true;
		this.documentationService.askedForProduct = true;
		this.saveChanges.emit();
	}

	ngOnInit(): void {
		this.documentationService.documentationAsObservable.subscribe(documentation => {
			const eligibility = documentation.eligibility;
			this.isEligibleToProduct = (
				!!eligibility.customization &&
				!!eligibility.affordability &&
				!!eligibility.replicable &&
				!!eligibility.userTesting);
		});
	}

	fieldChange(field: string) {
		this.documentationService.valueOfEditField = field;
	}

}
