<div #docWrapper id="docWrapper" class="doc-wrapper">
    <div id="htmlData" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <img class="img-adjust" src="{{tomLogoColor}}">
        </div>
        <div class="page-body space-between" style="align-items: center">
            <span class="sub-header">{{'A Step-by-step production and assembly guide for'}}</span>
            <div id="projectName" class="project-name-wrapper">
                <span class="project-name">{{documentation?.projectName}}</span>
                <div class="divider-wrapper">
                    <div class="divider"></div>
                </div>
            </div>

            <div class="names-wrapper">
                <div id="needKnower" class="name-wrapper">
                    <span class="input-header">{{'Designed with'}}</span>
                    <span class="input-name">{{needKnowerName}}</span>
                </div>
                <div id="teamMembers" class="name-wrapper">
                    <span class="input-header">{{'Designed by'}}</span>
                    <span class="input-name">{{teamMembers?.join(', ')}}</span>
                </div>
            </div>
            <div id="beingUsed" class="img-project-wrapper">
                <img [src]="files?.updatedPhoto" class="img-project" *ngIf="files?.updatedPhoto">
                <div class="img-border" *ngIf="!files?.updatedPhoto" style="border: 1px solid;">
                    <span>{{'Project Image'}}</span>
                </div>
            </div>
            <div class="divider-wrapper">
                <div class="divider"></div>
            </div>
        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>

    </div>
    <br />

    <div #docPdf id="htmlDefinitions" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <div class="header-and-img">
                <div class="wrapper-adjust">
                    <img class="img-adjust" src="{{tomLogoMobile}}">
                    <span class="project-name">{{documentation?.projectName}}</span>
                </div>
            </div>
        </div>
        <div class="page-body space-between">
            <div>
                <span class="sub-header">{{'PRODUCT DEFINITIONS'}}</span>
                <div class="divider-wrapper">
                    <div class="divider"></div>
                </div>
            </div>
            <div class="wwhw-main-wrapper">
                <div id="yourNeedKnower" class="wwhw-wrapper">
                    <div class="wwhw-inner-wrapper">
                        <div>
                            <span class="w-q">{{'WHO?'}}</span>
                            <div class="divider-wrapper">
                                <div class="divider"></div>
                            </div>
                        </div>
                        <span class="w-a">{{documentation?.yourNeedKnower}}</span>
                    </div>
                </div>
                <div id="projectNeeded" class="wwhw-wrapper">
                    <div class="wwhw-inner-wrapper">
                        <div>
                            <span class="w-q">{{'WHY?'}}</span>
                            <div class="divider-wrapper">
                                <div class="divider"></div>
                            </div>
                        </div>
                        <span class="w-a">{{documentation?.projectNeeded}}</span>
                    </div>
                </div>
                <div id="solutionAddress" class="wwhw-wrapper">
                    <div class="wwhw-inner-wrapper">
                        <div>
                            <span class="w-q">{{'HOW?'}}</span>
                            <div class="divider-wrapper">
                                <div class="divider"></div>
                            </div>
                        </div>
                        <span class="w-a">{{documentation?.solutionAddress}}</span>
                    </div>
                </div>
                <div id="solutionOffering" class="wwhw-wrapper">
                    <div class="wwhw-inner-wrapper">
                        <div>
                            <span class="w-q">{{'WHAT? '}}</span>
                            <div class="divider-wrapper">
                                <div class="divider"></div>
                            </div>
                        </div>
                        <span class="w-a">{{documentation?.solutionOffering}}</span>
                    </div>
                </div>
            </div>

            <a class="link" *ngIf="!!documentation?.documentationFiles?.beingUsedVideo"
                href="{{documentation?.documentationFiles?.beingUsedVideo}}">{{'link - watch video'}}</a>
        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>
    </div>
    <br />

    <div #docPdf id="htmlPricing" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <div class="header-and-img">
                <div class="wrapper-adjust">
                    <img class="img-adjust" src="{{tomLogoMobile}}">
                    <span>{{documentation?.projectName}}</span>
                </div>
            </div>
        </div>
        <div class="page-body space-between">
            <div class="header-divider-wrapper">
                <span class="sub-header">{{'PRICING + SPECIFICATIONS'}}</span>
                <div class="divider-wrapper">
                    <div class="divider"></div>
                </div>
            </div>

            <span class="header">{{'ESTIMATED COST'}}</span>
            <div class="sub-body-top-wrapper">
                <div id="cost" class="element-wrapper">
                    <span class="sub-header">{{'Materials'}}</span>
                    <div>
                        <span>{{!!documentation?.cost && documentation?.cost !== '' ? (documentation.cost + '$') :
                            documentation?.cost}}</span>
                        <div class="divider-wrapper">
                            <div class="divider"></div>
                        </div>
                    </div>
                </div>
                <div id="timeMachine" class="element-wrapper">
                    <span class="sub-header">{{'Machine Time'}}</span>
                    <div>
                        <span>{{!!documentation?.timeMachine && documentation?.timeMachine !== '' ?
                            (documentation?.timeMachine + ' Hrs') : documentation?.timeMachine}}</span>
                        <div class="divider-wrapper">
                            <div class="divider"></div>
                        </div>
                    </div>
                </div>
                <div id="timeAssembly" class="element-wrapper">
                    <span class="sub-header">{{'Assembly Time'}}</span>
                    <div>
                        <span>{{!!documentation?.timeAssembly && documentation?.timeAssembly !== '' ?
                            (documentation?.timeAssembly + ' Hrs') : documentation?.timeAssembly}}</span>
                        <div class="divider-wrapper">
                            <div class="divider"></div>
                        </div>
                    </div>
                </div>


            </div>
            <span class="header">{{'DIMENSIONS'}}</span>
            <div class="sub-body-top-wrapper-dim">
                <div class="content">
                    <div id="height" class="element-wrapper-dim">
                        <span class="sub-header">{{'Height'}}</span>
                        <div>
                            <span>{{documentation?.height}}{{documentation?.unit}}</span>
                            <div class="divider-wrapper">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                    <div id="width" class="element-wrapper-dim">
                        <span class="sub-header">{{'Width'}}</span>
                        <div>
                            <span>{{documentation?.width}}{{documentation?.unit}}</span>
                            <div class="divider-wrapper">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                    <div id="length" class="element-wrapper-dim">
                        <span class="sub-header">{{'Length'}}</span>
                        <div>
                            <span>{{documentation?.length}}{{documentation?.unit}}</span>
                            <div class="divider-wrapper">
                                <div class="divider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="perspectiveView" class="img-project-wrapper">
                    <img [src]="files?.perspectiveView" class="perspective-img" *ngIf="files?.perspectiveView">
                    <div class="perspective-border" *ngIf="!files?.perspectiveView" style="border: 1px solid;">
                        <span>{{'product perspective view image'}}</span>
                    </div>
                </div>


            </div>
        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>
    </div>
    <br />

    <div *ngIf="files?.productPhoto" #docPdf id="htmlProduct" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <div class="header-and-img">
                <div class="wrapper-adjust">
                    <img class="img-adjust" src="{{tomLogoMobile}}">
                    <span>{{documentation?.projectName}}</span>
                </div>
            </div>
        </div>
        <div class="page-body flex-start" style="align-items: center">
            <div class="header-divider-wrapper">
                <span class="sub-header">{{'Product Image'}}</span>
                <div class="divider-wrapper">
                    <div class="divider"></div>
                </div>
            </div>
            <div id="productPhoto" class="img-project-wrapper">
                <img [src]="files?.productPhoto" class="img-exploded" *ngIf="files?.productPhoto">
                <div class="img-border" *ngIf="!files?.productPhoto" style="border: 1px solid;">
                    <span>{{'Product Image'}}</span>
                </div>
            </div>

        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>
    </div>
    <br *ngIf="files?.productPhoto" />

    <div #docPdf id="htmlViews" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <div class="header-and-img">
                <div class="wrapper-adjust">
                    <img class="img-adjust" src="{{tomLogoMobile}}">
                    <span>{{documentation?.projectName}}</span>
                </div>
            </div>
        </div>
        <div class="page-body">
            <div class="header-divider-wrapper">
                <span class="sub-header">{{'PRODUCT VIEWS'}}</span>
                <div class="divider-wrapper">
                    <div class="divider"></div>
                </div>
            </div>

            <div class="images">
                <div id="topView" class="img-view">
                    <img [src]="files?.topView" class="views-img" *ngIf="files?.topView">
                    <div class="views-border" *ngIf="!files?.topView" style="border: 1px solid;">
                        <span>{{'product top view Image'}}</span>
                    </div>
                </div>
                <div id="sideView" class="img-view">
                    <img [src]="files?.sideView" class="views-border" *ngIf="files?.sideView">
                    <div class="views-border" *ngIf="!files?.sideView" style="border: 1px solid;">
                        <span>{{'product side view Image'}}</span>
                    </div>

                </div>

            </div>
            <div id="frontView" class="img-front-view">
                <img [src]="files?.frontView" class="views-border" *ngIf="files?.frontView">
                <div class="views-border" *ngIf="!files?.frontView" style="border: 1px solid;">
                    <span>{{'product front view Image'}}</span>
                </div>
            </div>


        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>
    </div>
    <br />

    <div #docPdf id="htmlBeforeStart" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <div class="header-and-img">
                <div class="wrapper-adjust">
                    <img class="img-adjust" src="{{tomLogoMobile}}">
                    <span>{{documentation?.projectName}}</span>
                </div>
            </div>
        </div>
        <div class="page-body ">
            <div class="header-divider-wrapper">
                <span class="sub-header">{{'BEFORE YOU START'}}</span>
                <div class="divider-wrapper">
                    <div class="divider"></div>
                </div>
            </div>
            <div class="text-and-img-wrapper">
                <img class="img" src="{{triangular}}">
                <p class="text">{{'Carefully read the entire product file to ensure that you have all the required
                    tools, materials and correct printer settings BEFORE you start making the product.'}}</p>
            </div>

            <div class="text-and-img-wrapper">
                <img class="img" src="{{square}}">
                <p class="text">{{'Always wear protective gear when working with power tools.'}}</p>
            </div>

            <div class="text-and-img-wrapper">
                <img class="img" src="{{circle}}">
                <p class="text">Send us feedback if you are having difficulty building this product -
                    <u>hello@tomglobal.org</u>
                </p>
            </div>

            <div class="text-and-img-wrapper">
                <img class="img" src="{{triangular}}">
                <p class="text"><b>Have fun and give yourself a pat on the back for making the world a better place!</b>
                </p>
            </div>

        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>
    </div>
    <br />

    <div #docPdf id="htmlTools" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <div class="header-and-img">
                <div class="wrapper-adjust">
                    <img class="img-adjust" src="{{tomLogoMobile}}">
                    <span>{{documentation?.projectName}}</span>
                </div>
            </div>
        </div>
        <div class="page-body space-between">
            <div class="tools-wrapper">
                <div id="tools" class="header-divider-wrapper">
                    <span class="sub-header">{{'TOOLS NEEDED'}}</span>
                    <div class="divider-wrapper">
                        <div class="divider"></div>
                    </div>
                </div>
                <div class="list-wrapper">
                    <ul>
                        <li *ngFor="let tool of documentation?.tools">{{tool}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>
    </div>
    <br />

    <div *ngFor="let materialsPage of materialsSize;let tableIndex = index">
        <div #docPdf id="htmlMaterials" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
            <div class="header-wrapper">
                <div class="header-and-img">
                    <div class="wrapper-adjust">
                        <img class="img-adjust" src="{{tomLogoMobile}}">
                        <span>{{documentation?.projectName}}</span>
                    </div>
                </div>
            </div>
            <div class="page-body flex-start">
                <div class="header-divider-wrapper">
                    <span class="sub-header">{{'Bill Of Materials'}}</span>
                    <div class="divider-wrapper">
                        <div class="divider"></div>
                    </div>
                </div>
                <a class="link" href="https://www.rapidtables.com/convert/length/cm-to-inch.html" target="_blank">
                    Centimeters to Inches Converter</a>
                <div class="table-wrapper">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="table-header">{{'Item #'}}</th>
                                <th class="table-header">{{'Image'}}</th>
                                <th class="table-header">{{'Description'}}</th>
                                <th class="table-header">{{'Material'}}</th>
                                <th class="table-header">{{'Manufacturing instruction'}}
                                    <span>{{'(Technology, printing infill etc.)'}}</span>
                                </th>
                                <th class="table-header">{{'Dimensions'}}</th>
                                <th class="table-header">{{'QTY'}}</th>
                                <th class="table-header">{{'Purchase Link/Supplier'}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr id="material{{(tableIndex * 5) + i}}"
                                *ngFor="let material of documentation?.materials |  slice:(tableIndex * 5):(tableIndex * 5 + 5) ;  let i = index">
                                <td class="table-data">{{tableIndex * 5 + i + 1}}</td>
                                <td class="table-data-img"><img class="material-img" [src]="material?.imageUrl"
                                    *ngIf="material?.imageUrl"></td>
                                <td class="table-data">{{material?.itemDescription}}</td>
                                <td class="table-data">{{material?.material}}</td>
                                <td class="table-data">{{material?.manufacturing}}</td>
                                <td class="table-data">{{getDimensionValueToDisplay(material)}}</td>
                                <td class="table-data">{{material?.qty}}</td>
                                <td class="table-data"><a *ngIf="material?.purchaseLink; else empty_link"
                                        href="{{material?.purchaseLink ? material?.purchaseLink : '#'}}">{{'Purchase
                                        Link'}}</a>
                                    <ng-template #empty_link>{{''}}</ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="page-footer">
                <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
            </div>
        </div>
        <br />
    </div>

    <div #docPdf id="htmlExploded" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <div class="header-and-img">
                <div class="wrapper-adjust">
                    <img class="img-adjust" src="{{tomLogoMobile}}">
                    <span>{{documentation?.projectName}}</span>
                </div>
            </div>
        </div>
        <div class="page-body flex-start" style="align-items: center">
            <div class="header-divider-wrapper">
                <span class="sub-header">{{'EXPLODED VIEW'}}</span>
                <div class="divider-wrapper">
                    <div class="divider"></div>
                </div>
            </div>
            <div id="explodedView" class="img-project-wrapper">
                <img [src]="files?.explodedView" class="img-exploded" *ngIf="files?.explodedView">
                <div class="img-border" *ngIf="!files?.explodedView" style="border: 1px solid;">
                    <span>{{'product exploded view Image'}}</span>
                </div>
            </div>

        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>
    </div>
    <br />

    <div *ngFor="let specialInstruction of specialInstructionsArray;let i = index">
        <div class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
            <div class="header-wrapper">
                <div class="header-and-img">
                    <div class="wrapper-adjust">
                        <img class="img-adjust" src="{{tomLogoMobile}}">
                        <span>{{documentation?.projectName}}</span>
                    </div>
                </div>
            </div>
            <div class="page-body-make-parts space-between">
                <div class="header-divider-wrapper">
                    <div class="headers-wrapper">
                        <span class="sub-header">{{' MAKE THE PARTS'}}&nbsp;</span>
                    </div>
                    <div class="divider-wrapper">
                        <div class="divider"></div>
                    </div>
                </div>

                <div class="make-parts-wrapper">
                    <div id="instruction{{i*4}}" class="make-parts-column">
                        <img [src]="(documentation?.specialInstructions[i * 4]?.imageUrl)" class="make-parts-img"
                            *ngIf="documentation?.specialInstructions[i * 4]?.imageUrl">
                        <div class="make-parts-img-border" *ngIf="!documentation?.specialInstructions[i * 4]?.imageUrl">
                            <span>{{'Make part image'}}</span>
                        </div>

                        <div class="make-parts-description">
                            <span class="make-parts-description-content">{{documentation?.specialInstructions[i *
                                4]?.description}}</span>
                        </div>
                    </div>


                    <div *ngIf="documentation?.specialInstructions[i * 4 + 1]">
                        <div id="instruction{{i*4+1}}" class="make-parts-column" style="display: inline;">
                            <img [src]="(documentation?.specialInstructions[i * 4+1]?.imageUrl)" class="make-parts-img"
                                *ngIf="documentation?.specialInstructions[i * 4+1]?.imageUrl">
                            <div class="make-parts-img-border"
                                *ngIf="!documentation?.specialInstructions[i * 4+1]?.imageUrl">
                                <span>{{'Make part image'}}</span>
                            </div>
                            <div class="make-parts-description">

                                <span class="make-parts-description-content">{{documentation?.specialInstructions[i * 4
                                    + 1]?.description}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="make-parts-wrapper" *ngIf="documentation?.specialInstructions[i * 4 + 2]">
                    <div id="instruction{{i*4+2}}" class="make-parts-column">
                        <img [src]="(documentation?.specialInstructions[i * 4+2]?.imageUrl)" class="make-parts-img"
                            *ngIf="documentation?.specialInstructions[i * 4+2]?.imageUrl">
                        <div class="make-parts-img-border"
                            *ngIf="!documentation?.specialInstructions[i * 4+2]?.imageUrl">
                            <span>{{'Make part image'}}</span>
                        </div>
                        <div class="make-parts-description">

                            <span class="make-parts-description-content">{{documentation?.specialInstructions[i * 4 +
                                2]?.description}}</span>
                        </div>
                    </div>


                    <div *ngIf="documentation?.specialInstructions[i * 4 + 3]">
                        <div id="instruction{{i*4+3}}" class="make-parts-column" style="display: inline;">
                            <img [src]="(documentation?.specialInstructions[i * 4+3]?.imageUrl)" class="make-parts-img"
                                *ngIf="documentation?.specialInstructions[i * 4+3]?.imageUrl">
                            <div class="make-parts-img-border"
                                *ngIf="!documentation?.specialInstructions[i * 4+3]?.imageUrl">
                                <span>{{'Make part image'}}</span>
                            </div>
                            <div class="make-parts-description">

                                <span class="make-parts-description-content">{{documentation?.specialInstructions[i * 4
                                    + 3]?.description}}</span>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="page-footer">
                <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
            </div>
        </div>
        <br />
    </div>

    <div *ngFor="let stepPage of stepsSize;let i = index">
        <div class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
            <div class="header-wrapper">
                <div class="header-and-img">
                    <div class="wrapper-adjust">
                        <img class="img-adjust" src="{{tomLogoMobile}}">
                        <span>{{documentation?.projectName}}</span>
                    </div>
                </div>
            </div>
            <div class="page-body space-between">
                <div class="header-divider-wrapper">
                    <div class="headers-wrapper">
                        <span class="sub-header">{{'ASSEMBLY GUIDE'}}&nbsp;</span>
                        <span class="sub-header">{{'/ page '}}{{i + 1}}</span>
                    </div>
                    <div class="divider-wrapper">
                        <div class="divider"></div>
                    </div>
                </div>
                <!--<div *ngIf="i === 0" class="assembly-button-wrapper">
                    <button class="assembly-button">{{'Watch assembly video'}}</button>
                </div>-->
                <div class="assembly-wrapper">
                    <div class="assembly-row">
                        <div class="step-description">
                            <div class="sub-header-divider-wrapper">
                                <span id="step{{i*2}}" class="step-header">{{'STEP #'}}{{i * 2 + 1}}</span>
                                <div class="divider-wrapper">
                                    <div class="divider"></div>
                                </div>
                            </div>
                            <span class="step-description-content">{{documentation?.assemblySteps[i *
                                2]?.description}}</span>
                            <br>
                            <br>
                            <div class="step-tip" *ngIf="documentation?.assemblySteps[i * 2]?.tip">
                                <span class="red-title">{{'Tip: '}}</span>
                                <span class="step-description-content">
                                    {{documentation?.assemblySteps[i * 2]?.tip}}
                                </span>
                            </div>
                            <div class="step-tools" *ngIf="documentation?.assemblySteps[i * 2]?.tools">
                                <span>{{'Tools:'}}</span>
                                <div class="tool" *ngFor="let tool of documentation?.assemblySteps[i * 2]?.tools">
                                    <span>{{tool}}</span>
                                </div>

                            </div>

                        </div>
                        <div class="step-img">
                            <img [src]="(documentation?.assemblySteps[i*2]?.imageUrl)" class="step-img"
                                *ngIf="documentation?.assemblySteps[i*2]?.imageUrl">
                            <div class="step-border" *ngIf="!documentation?.assemblySteps[i*2]?.imageUrl"
                                style="border: 1px solid;">
                                <span>{{'Assembly step image'}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="documentation?.assemblySteps[i * 2 + 1]" class="assembly-row">
                        <div class="step-img">
                            <img [src]="(documentation?.assemblySteps[i * 2 + 1]?.imageUrl)" class="step-img"
                                *ngIf="documentation?.assemblySteps[i*2+1]?.imageUrl">
                            <div class="step-border" *ngIf="!documentation?.assemblySteps[i*2+1]?.imageUrl"
                                style="border: 1px solid;">
                                <span>{{'Assembly step image'}}</span>
                            </div>
                        </div>
                        <div class="step-description">
                            <div class="sub-header-divider-wrapper">
                                <span id="step{{i*2 +1}}" class="step-header">{{'STEP #'}}{{i * 2 + 2}}</span>
                                <div class="divider-wrapper">
                                    <div class="divider"></div>
                                </div>
                            </div>
                            <span class="step-description-content">{{documentation?.assemblySteps[i * 2 +
                                1]?.description}}</span>
                            <br>
                            <br>
                            <div class="step-tip" *ngIf="documentation?.assemblySteps[i * 2 + 1]?.tip">
                                <span class="red-title">{{'Tip: '}}</span>
                                <span class="step-description-content">
                                    {{documentation?.assemblySteps[i * 2 + 1]?.tip}}
                                </span>
                            </div>
                            <div class="step-tools" *ngIf="documentation?.assemblySteps[i * 2 + 1]?.tools">
                                <span>{{'Tools:'}}</span>
                                <div class="tool" *ngFor="let tool of documentation?.assemblySteps[i * 2]?.tools">
                                    <span>{{tool}}</span>
                                    <br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="page-footer">
                <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
            </div>
        </div>
        <br />
    </div>

    <div id="htmlThank" class="pdf-page" [ngStyle]="{'zoom': docPdfZoom }">
        <div class="header-wrapper">
            <div class="header-and-img">
                <div class="wrapper-adjust">
                    <img class="img-adjust" src="{{tomLogoMobile}}">
                    <span>{{documentation?.projectName}}</span>
                </div>
            </div>
        </div>
        <div class="page-body flex-start">
            <div class="header-divider-wrapper">
                <p class="sub-header">THANK YOU FOR<br>MAKING WITH US <br> {{documentation?.projectName}}!</p>
                <div class="divider-wrapper">
                    <div class="divider"></div>
                </div>
            </div>
            <p class="text">Feel free to send us your feedback <br> to us at <u>hello@tomglobal.org</u></p>
        </div>
        <div class="page-footer">
            <span class="footer-header">{{'© 2021 - All right reserved to Tikkun Olam Makers (TOM)'}}</span>
        </div>
    </div>
</div>

<button (click)="generatePdf(true)">{{'Download Pdf'}}</button>