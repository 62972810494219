import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import {PressModel} from 'src/app/models/press';
import {GlobalsService} from 'src/app/services/globals';
import {AnalyticsService} from 'src/app/services/analytics.service';

@Component({
	selector: 'app-press',
	templateUrl: './press.component.html',
	styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit, AfterContentInit {

	@Input() press: PressModel;
	shortDescription: string;
	isPC: boolean = false;
	video_id: string;

	constructor(public globals: GlobalsService, public analyticsService: AnalyticsService) {
	}

	ngOnInit() {
		// tslint:disable-next-line:max-line-length
		this.shortDescription = this.press.description && this.press.description.length > 200 ? this.press.description.substring(0, 200) + '...' : this.press.description;
		if (this.press.logoUrl === './assets/images/forbes-logo.png') {
			this.press.logoUrl = './assets/images/support-tom-bg@desktop.jpg';
		}
		this.checkVideoLink();
	}

	ngAfterContentInit(): void {
		if (navigator.appVersion.indexOf('Win') !== -1) {
			this.isPC = true;
		}
	}

	public openArticle() {
		window.open(this.press.linkUrl, '_blank');
		this.analyticsService.sendEvent('Read article', this.press.linkUrl);
	}

	checkVideoLink() {
		const video = this.press.linkUrl;
		if (video.includes('youtube.com')) {
			this.video_id = video.split('v=')[1];
			const ampersandPosition = this.video_id.indexOf('&');
			if (ampersandPosition !== -1) {
				this.video_id = this.video_id.substring(0, ampersandPosition);
			}
			this.video_id = 'https://www.youtube.com/embed/' + this.video_id;
		}
	}

}
