<div class="select-form">
    <label class="B2-dark-text">{{label}}</label>

    <div class="buttons">
        <button class="regular-button" (click)="selectTOMGlobalChallenge(true)" (mouseover)="mouseover_yes = true"
                (mouseout)="mouseover_yes = false"
                [ngClass]="{'pressed-button' : associatedValue === true, 'hover-edit-button': mouseover_yes}">Yes
        </button>
        <button class="regular-button" (click)="selectTOMGlobalChallenge(false)" (mouseover)="mouseover_no = true"
                (mouseout)="mouseover_no = false"
                [ngClass]="{ 'pressed-button' : associatedValue === false, 'hover-edit-button': mouseover_no}">No
        </button>
    </div>

</div>
