import {City} from './city';
import {Country} from './country';

export class Maker {
	country: Country;
	city: City;
	logo: string = '';
	name: string = '';
	_id: string = '';
	isSent?: boolean;
	email: string;
	website: string;
	social: {
		facebook: string,
		linkedin: string,
		twitter: string,
		youtube: string,
		instagram: string,
	};
}
