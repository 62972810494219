import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {City} from 'src/app/models/city';
import {Country} from 'src/app/models/country';
import {Maker} from 'src/app/models/maker';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {CityService} from 'src/app/services/city.service';
import {CountryService} from 'src/app/services/country.service';
import {GlobalsService} from 'src/app/services/globals';
import {MakerService} from 'src/app/services/maker.service';

@Component({
	selector: 'app-makers-popup',
	templateUrl: './makers-popup.component.html',
	styleUrls: ['./makers-popup.component.scss'],
})
export class MakersPopupComponent implements OnInit {
	project = this.data.project;
	filterOpen = false;
	countriesList: Country[] = [];
	citiesList: City[] = [];
	makersList: City[] = [];
	makersForm: FormGroup;
	termsChecked = false;
	isUser = null;
	sentMakersArray = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<any>,
		public globals: GlobalsService,
		private analyticsService: AnalyticsService,
		private router: Router,
		public countryService: CountryService,
		public cityService: CityService,
		public makerService: MakerService,
		private formBuilder: FormBuilder
	) {
		this.makersForm = this.formBuilder.group({
			country: [''],
			city: [''],
		});
	}

	ngOnInit() {
		this.getListOfSentMakers();
		this.getCountries();
		this.getMakers();
		this.checkIfTermsAgreed();
	}

	public checkIfTermsAgreed() {
		this.isUser = localStorage.getItem('TOM-token');
	}

	public closePopup() {
		this.analyticsService.sendEvent(
			'Click on the \'X\' button',
			'CLOSE MAKERSPACE WINDOW'
		);
		this.dialogRef.close();
	}

	public toggleFilter() {
		!this.filterOpen &&
		this.analyticsService.sendEvent(
			'Click on open filter',
			'OPEN MAKERSPACE FILTER'
		);
		this.filterOpen &&
		this.analyticsService.sendEvent(
			'Click on close filter',
			'CLOSE MAKERSPACE FILTER'
		);
		this.filterOpen = !this.filterOpen;
		// this.getMakers();
	}

	public toggleTermCheck() {
		this.termsChecked = !this.termsChecked;
		this.termsChecked &&
		this.analyticsService.sendEvent(
			'Check the checkbox',
			'Agreement Checkbox'
		);
	}

	private getListOfSentMakers() {
		const sentMakersSTR = localStorage.getItem('sent'); // ex: maker1-project1,maker2-project2,maker1-project2
		if (sentMakersSTR) {
			sentMakersSTR
				?.split(',')?.forEach(makerProjectPair => {
				const [maker, projectId] = makerProjectPair?.split('-');
				if (projectId === this.project?._id) {
					this.sentMakersArray.push(maker);
				}
			});
		}
	}

	private handleCountryChange() {
		this.analyticsService.sendEvent('Click on country', 'CHOOSE COUNTRY');
		const countryId = this.makersForm.controls['country'].value;
		this.citiesList = [];
		if (countryId) {
			this.getCitiesByCountry(countryId);
			this.getMakersByCountry(countryId);
		} else {
			this.getMakers();
			this.getCountries();
		}
	}

	private handleCityChange() {
		this.analyticsService.sendEvent('Click on city', 'CHOOSE CITY');
		const cityId = this.makersForm.controls['city'].value;
		if (cityId) {
			this.getMakersByCity(cityId);
		} else {
			const countryId = this.makersForm.controls['country'].value;
			countryId && this.getMakersByCountry(countryId);
		}
	}

	private getCountries() {
		this.countryService.getCommunityCountries().subscribe((resp) => {
			this.countriesList = resp.map((item) => {
				const country = Object.assign(new Country(), item);
				return country;
			});
		});
	}

	private getCitiesByCountry(countryId) {
		this.cityService.getCitiesByCountry(countryId).subscribe((resp) => {
			this.citiesList = resp.map((item) => {
				const city = Object.assign(new City(), item);
				return city;
			});
		});
	}

	private getMakers() {
		const projectId = this.project._id;
		this.makerService.getMakersByProject(projectId).subscribe((resp) => {
			this.makersList = resp.map((item) => {
				const maker = Object.assign(new Maker(), item);
				// add isSent value from saved data in localStorage
				if (this.sentMakersArray.includes(maker._id)) {
					maker.isSent = true;
				}
				return maker;
			});
		});
	}

	private getMakersByCountry(countryId) {
		const projectId = this.project._id;
		this.makerService
			.getMakersByCountry(countryId, projectId)
			.subscribe((resp) => {
				this.makersList = resp.map((item) => {
					const maker = Object.assign(new Maker(), item);
					return maker;
				});
			});
	}

	private getMakersByCity(cityId) {
		const projectId = this.project._id;
		this.makerService
			.getMakersByCity(cityId, projectId)
			.subscribe((resp) => {
				this.makersList = resp.map((item) => {
					const maker = Object.assign(new Maker(), item);
					return maker;
				});
			});
	}

	public openNewTab(path) {
		// console.log(path)
		window.open(path, '_blank');
		// this.router.navigateByUrl(path);
		// this.closePopup();
	}
}
