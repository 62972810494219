<!-- Desktop Section -->
<div *ngIf="globals.isDesktop" class="desktop">
  <div class="communities">
    <div class="communities-title text-center center">
      The TOM Global Movement
    </div>
    <!-- <div class="communities-line center"></div> -->

    <div class="body-text text-center center">
      Since 2014, TOM grew from one community in Israel to dozens of locations
      around the world.
    </div>
    <img
      *ngIf="!globals.isDesktop"
      class="mobile-world"
      src="../../../assets/images/home-communities-background.svg"
    />
    <div
      class="learn-more-container"
      (click)="
        analyticsService.sendEvent('Explore Communities', 'Explore Communities')
      "
    >
      <a href="/communities" class="learn-more-button text-center center">
        Learn More
      </a>
    </div>
  </div>

  <!-- Mobile Section -->
  <div *ngIf="globals.isMobile" class="mobile">
    <div class="communities">
      <div class="communities-title bold text-center">TOM Communities</div>
      <div class="communities-line center"></div>
      <div class="body-text text-center center">
        Since 2014, TOM grew from one community in Israel to dozens of locations
        around the world.
      </div>
      <img
        *ngIf="!globals.isDesktop"
        class="mobile-world"
        src="../../../assets/images/home-communities-background.svg"
      />
      <div
        class="explore-communities bold text-center center"
        (click)="
          analyticsService.sendEvent(
            'Explore Communities',
            'Explore Communities'
          )
        "
        [routerLink]="['/communities']"
      >
        EXPLORE COMMUNITIES
      </div>
    </div>
  </div>
</div>
