import {Injectable} from '@angular/core';
import {Pagination} from '../models/pagination';
import {HttpService} from './http.service';

@Injectable({
	providedIn: 'root'
})
export class MakerService extends HttpService {

	public getAllMakers(countryId, pagination: Pagination) {
		const url = '/maker';
		const params = pagination.getPaginationAsParams();
		if (countryId) {
			params.countryId = countryId;
		}
		return this.get(url, params);
	}

	public getMakersByProject(projectId) {
		return this.get('/maker/project', {project: projectId});
	}

	public getMakersByCountry(countryId, projectId) {
		return this.get(`/maker/filter`, {country: countryId, project: projectId});
	}

	public getMakersByCity(cityId, projectId) {
		return this.get(`/maker/filter`, {city: cityId, project: projectId});
	}

	public sendEmailToMaker(to, subject, params) {
		return this.post(`/maker/email`, {to, subject, params});
	}

}
