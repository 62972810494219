import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalsService} from '../../../services/globals';
import {UserService} from '../../../services/user.service';
import {DataService} from '../../../services/data.service';
import {ProjectService} from '../../../services/project.service';
import {Comment} from '../../../models/project';
import {User} from '../../../models/user';
import {IFileUpload, S3FileUpload} from '../../../helpers/file-upload/s3-file-upload';
import {FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';


@Component({
	selector: 'app-new-comment',
	templateUrl: './new-comment.component.html',
	styleUrls: ['./new-comment.component.scss']
})
export class NewCommentComponent implements OnInit {

	@Input() projectId: string;
	@Output() success = new EventEmitter();
	FileUpload: IFileUpload;
	img: string;
	uploadingImage: boolean = false;
	textOrImageRequired: boolean = false;
	public user: User;
	public commentFormControl: FormControl;
	public addClicked: boolean = false;


	constructor(public globals: GlobalsService,
				private router: Router,
				private dataService: DataService,
				private projectService: ProjectService,
				private userService: UserService
	) {
		this.FileUpload = new S3FileUpload();

	}


	ngOnInit() {
		if (this.dataService.isUserLoggedIn) {
			this.userService.getUserData().subscribe(user => {
				this.user = Object.assign(new User(), user.data);
			});
			this.commentFormControl = new FormControl('', {
				validators: [Validators.required]
			});
		}
	}

	public validateComment() {
		this.addClicked = true;
		this.commentFormControl.markAsTouched();
		if (!this.img && !this.commentFormControl.value) {
			this.textOrImageRequired = true;
		} else {
			this.addComment();
		}
	}

	public addComment() {
		if (this.user && this.user._id) {
			this.projectService.addComment(this.commentFormControl.value, this.projectId, this.user._id, this.img).subscribe((comment: Comment) => {
				this.success.emit(comment);
				this.commentFormControl.setValue('');
				this.img = undefined;
				this.commentFormControl.reset();
				this.commentFormControl.setValidators(Validators.required);
			});
		}
	}

	public uploadPhoto(event) {
		if (event.target.files.length > 0) {
			this.textOrImageRequired = false;
			this.uploadImage(event.target.files[0]);
		}
	}

	uploadImage(file: File) {
		this.uploadingImage = true;
		this.FileUpload.UploadFileAsync(file, (res: any) => {
			if (res.success) {
				this.commentFormControl.clearValidators();
				this.commentFormControl.reset(this.commentFormControl.value);
				this.img = res.url;
			} else {
				console.error(res.err);
			}
			this.uploadingImage = false;
		});
	}

	removeImage() {
		this.img = undefined;
		this.commentFormControl.setValidators(Validators.required);
	}

	public logintToComment() {
		this.dataService.routeAfterLogin = `project?id=${this.projectId}`;
		this.router.navigateByUrl('/login');
	}
}
