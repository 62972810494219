<div [ngClass]="(globals.isDesktop)?'desktop':'mobile'">
    <div [ngClass]="style">
        <div class="popup">
            <div class="close-x pointer">
                <img src="./assets/images/close-x.svg" (click)="closeDialog()">
            </div>
            <!-- <div class="popup-image">
                <img src="./assets/images/{{image}}">
            </div> -->
            <div class="popup-title">
                {{title}}
            </div>
            <div class="popup-text">
                {{text}}
            </div>
            <div class="popup-confirm">
                <button (click)="confirmDialog()" class="btn-confirm">
                    <img class="button-icon" *ngIf="buttonIcon" src="./assets/images/{{buttonIcon}}">
                    {{button}}
                </button>
            </div>
        </div>
    </div>
</div>
