import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {
	Documentation,
	DocumentationFiles,
	documentationPdfStyles,
	tom_circle_url,
	tom_logo_color_mobile_url,
	tom_logo_color_url,
	tom_square_url,
	tom_triangular_url
} from '../../../models/documentation';
import {GlobalsService} from '../../../services/globals';
import {DocumentationSystemService} from '../../../services/documentation-system.service';
import {takeUntil} from 'rxjs/operators';
import {UserService} from '../../../services/user.service';
import {Destroyable} from '../../../shared/destroyable';
import {ProjectMaterial} from '../../../models/projectMaterial';

@Component({
	selector: 'app-documentation-preview',
	templateUrl: './documentation-preview.component.html',
	styleUrls: ['./documentation-preview.component.css'],
})
export class DocumentationPreviewComponent extends Destroyable implements OnInit, AfterViewInit {
	@ViewChild('docWrapper') docWrapper: ElementRef;
	documentation: Documentation;


	// dynamic length arrays
	stepsSize: any[] | null = null;
	materialsSize: any[] | null = null;
	specialInstructionsArray: any[] | null = null;

	files: DocumentationFiles = new DocumentationFiles();
	needKnowerName: string = '';
	teamMembers: string[] = [];
	docPdfZoom = 1;


	// images sources urls
	tomLogoColor = tom_logo_color_url;
	tomLogoMobile = tom_logo_color_mobile_url;
	circle = tom_circle_url;
	square = tom_square_url;
	triangular = tom_triangular_url;


	@Output() updateDocumentationEvent = new EventEmitter<any>();


	constructor(
		public globals: GlobalsService,
		private documentationService: DocumentationSystemService,
		private userService: UserService,
		private changeDetectorRef: ChangeDetectorRef,
	) {
		super();
	}

	ngOnInit() {
		this.documentation = this.documentationService?.valueOfDocumentation;
		this.subscribeToDocumentation();
		this.subscribeToSavingDocumentation();
	}

	// any value changes in documentation form.
	subscribeToDocumentation() {
		this.documentationService?.documentationAsObservable?.pipe(takeUntil(this.destroy$)).subscribe((updatedDocumentation) => {
			if (!!updatedDocumentation) {

				const allTeamMembers = [...new Set([...updatedDocumentation?.teamMembers, ...updatedDocumentation?.teamMembersFreeText])];
				this.teamMembers = allTeamMembers?.map(member => `${member.basicUserInformation?.firstName ?? member}
								${member.basicUserInformation?.lastName ?? ''}`);

				if ((this.documentation?.assemblySteps?.length !== updatedDocumentation?.assemblySteps?.length) || this.stepsSize === null) {
					this.stepsSize = Array(Math.floor(updatedDocumentation?.assemblySteps?.length / 2) + (updatedDocumentation?.assemblySteps?.length % 2));
				}
				if ((this.documentation?.materials?.length !== updatedDocumentation?.materials?.length) || this.materialsSize === null) {
					this.materialsSize = Array(Math.ceil(updatedDocumentation?.materials?.length / 5));
				}
				if ((this.documentation?.specialInstructions?.length !== updatedDocumentation?.specialInstructions?.length) || this.specialInstructionsArray === null) {
					this.specialInstructionsArray = Array(Math.ceil(updatedDocumentation?.specialInstructions?.length / 4));
				}

				this.documentation = {...updatedDocumentation};

				this.needKnowerName = this.documentation?.needKnower ? (this?.documentation?.needKnower.basicUserInformation ? `${this.documentation?.needKnower?.basicUserInformation?.firstName}
								${this.documentation?.needKnower?.basicUserInformation?.lastName}` : '') :  this.documentation?.needKnowerFreeText;
				for (const [key, value] of Object.entries(updatedDocumentation?.documentationFiles)) {
					if (!!value) {
						this.files[key] = value;
					}
				}
			}
		});
	}

	// user pressed "Save"
	subscribeToSavingDocumentation() {
		this.documentationService.uploadingDocumentationAsObservable?.subscribe(value => {
			if (value === true) {
				this.generatePdf(false).then(res => {
				});
			}
		});
	}

	ngAfterViewInit(): void {
		// listen to doc wrapper width change
		this.listenToDocWrapperWidthChange();
	}


	listenToDocWrapperWidthChange() {
		const resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				const contentRect = entry.contentRect;
				this.docPdfZoom = contentRect.width / 826;
				this.changeDetectorRef.detectChanges();
			}
		});

		resizeObserver.observe(this.docWrapper.nativeElement);
	}


	async generatePdf(isDownload: boolean = false) {
		const pdfAsHtml = this.generatePdfHtmlElement();
		this.documentationService.generatePdf(pdfAsHtml.innerHTML, documentationPdfStyles).toPromise().then(result => {
			const blobPDF = new Blob([result], {type: 'application/pdf'});
			if (!!isDownload) {
				const url = window.URL.createObjectURL(blobPDF);
				const downloadPdf = window.open(url);
			} else {
				const pdfFile = new File([blobPDF], `${this.documentationService.valueOfDocumentation?.projectName}.pdf`, {
					lastModified: new Date().getTime(),
					type: blobPDF?.type
				});
				this.updateDocumentationEvent.emit(pdfFile);
			}
		});
	}

	generatePdfHtmlElement() {
		const pEl = document.getElementById('docWrapper');
		const pdfWithoutStyles = document.createElement('div');
		pdfWithoutStyles.innerHTML = pEl.innerHTML;

		// loop through ALL DOM elements inside the divElement
		const elements = pdfWithoutStyles.getElementsByTagName('*');
		for (let i = 0; i < elements.length; i++) {
			if ((elements[i].getAttribute('style') || '').includes('zoom')) {
				elements[i].removeAttribute('style');
			}
		}
		return pdfWithoutStyles;
	}

	getDimensionValueToDisplay(material: ProjectMaterial) {
		if (material.dimensions && material.unit) {

			return material.dimensions + ' ' + material.unit?.short;
		}
	}


}
