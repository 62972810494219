<!-- Mobile Slider -->
<div *ngIf="globals.isMobile" class="mobile">
    <div class="photo-slider">
        <ng-container *ngIf="checkFileType(mainPhoto)===1; else videoContent">
            <img [src]="mainPhoto" class="main-photo">
        </ng-container>
        <ng-template #videoContent>
            <vg-player>
                <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
                    <source [src]="mainPhoto" type="video/mp4">
                </video>
            </vg-player>
        </ng-template>
        <div class="selectors" *ngIf="photos.length!=1">
            <div (click)="slideImage(i)" *ngFor="let photo of photos; let i = index" class="dot"
                 [ngClass]="photo.selected? 'dot-fill' : ''"></div>
        </div>
    </div>
</div>

<!-- Desktop Slider -->

<div *ngIf="globals.isDesktop" class="desktop">
    <div class="photo-slider">
        <div class="main-photo">
            <ng-container *ngIf="checkFileType(mainPhoto)===1; else videoContent">
                <img [src]="mainPhoto">
            </ng-container>
            <ng-template #videoContent>
                <vg-player>
                    <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
                        <source [src]="mainPhoto" type="video/mp4">
                    </video>
                </vg-player>
            </ng-template>

        </div>
        <div style="overflow: overlay" *ngIf="_imagesUrls.length > 1">
            <div class="selectors">
                <div *ngFor="let photo of photos; let i = index" class="option">
                    <ng-container *ngIf="checkFileType(photo.src)===1;else placeholder">
                        <img [src]="photo.src" (click)="selectImage(photo.src)" class="pointer">
                    </ng-container>
                    <ng-template #placeholder>
                        <img class="img-placeholder" src="./assets/images/play-button.svg">
                        <video width="137" height="98" (click)="selectImage(photo.src)">
                            <source [src]="photo.src+'#t=0.1'" type="video/mp4"/>
                        </video>
                    </ng-template>


                </div>
            </div>
        </div>
    </div>
</div>
