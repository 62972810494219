<div [ngClass]="(globals.isDesktop)?'desktop':'mobile'">
    <div class="comment-item flex-row">
        <ng-container *ngIf="comment.user.imageUrl; else defaultUser">

            <div class="comment-item-profile-image mr-4"
                 [ngStyle]="{'background-image':'url('+comment?.user?.imageUrl+')'}">
                <ng-container *ngIf="comment.user.basicUserInformation.countryId.alpha2Code;else defaultFlag">
					<span
                            class="profile-image-flag round flag-icon + flag-icon-{{ comment.user.basicUserInformation.countryId.alpha2Code | lowercase}} flag-icon-squared"></span>
                </ng-container>
                <ng-template #defaultFlag>
                    <img class="profile-image-flag" src="./assets/images/square-default.svg" alt="profile image">
                </ng-template>
            </div>

        </ng-container>

        <ng-template #defaultUser>

            <div class="comment-item-profile-image mr-4"
                 [ngStyle]="{'background-image':'url(./assets/images/square-default.svg)'}">
                <ng-container *ngIf="comment.user.basicUserInformation.countryId.alpha2Code;else defaultFlag">
					<span
                            class="profile-image-flag round flag-icon + flag-icon-{{ comment.user.basicUserInformation.countryId.alpha2Code | lowercase}} flag-icon-squared"></span>
                </ng-container>
                <ng-template #defaultFlag>
                    <img class="profile-image-flag" src="./assets/images/square-default.svg" alt="profile image">
                </ng-template>
            </div>

        </ng-template>
        <div class="comment-item-content flex-col">
            <h6 class="comment-item-content-title" heavy>
                {{comment?.user?.basicUserInformation?.firstName}}
                {{comment?.user?.basicUserInformation?.lastName}}
            </h6>
            <p class="comment-item-content-message">
                {{comment.text}}<br>
            </p>
            <div style="color:grey; font-size: 14px;margin-top: 5px;">{{date}}</div>
            <img class="comment-item-comment-img" *ngIf="comment.image" [src]="comment.image">
        </div>
    </div>
</div>
