<div class="tools">
    <span class="B2-dark-text">Select Basic Square Tools:</span>
    <mat-form-field *ngFor="let tools of basicToolsGroup; let i = index" appearance="fill">
        <mat-label>{{tools?.name }}</mat-label>
        <mat-select [formControl]="basicToolsFormGroup.controls[i]" multiple disableOptionCentering>
            <mat-select-trigger>
                {{basicToolsFormGroup.controls[i].value?.[0] || ''}}
                <!--            {{basicSquareTools.value?.[0] || ''}}-->
                <span *ngIf="(basicToolsFormGroup.controls[i]?.value?.length || 0) > 1"
                      class="example-additional-selection">
                    (+{{(basicToolsFormGroup.controls[i]?.value?.length || 0) - 1}} {{basicToolsFormGroup.controls[i]?.value?.length === 2 ? 'other' : 'others'}}
                    )
                        </span>
            </mat-select-trigger>
            <mat-option *ngFor="let tool of tools.tools" [value]="tool">{{tool}}</mat-option>
        </mat-select>
    </mat-form-field>

    <span class="B2-dark-text">Additional Tools:</span>
    <mat-form-field *ngFor="let tools of otherToolsGroup; let i = index" appearance="fill">
        <mat-label>{{tools?.name }}</mat-label>
        <mat-select [formControl]="otherToolsFormGroup.controls[i]" multiple disableOptionCentering>
            <mat-select-trigger>
                {{otherToolsFormGroup.controls[i].value?.[0] || ''}}
                <span *ngIf="(otherToolsFormGroup.controls[i]?.value?.length || 0) > 1"
                      class="example-additional-selection">
                    (+{{(otherToolsFormGroup.controls[i]?.value?.length || 0) - 1}} {{otherToolsFormGroup.controls[i]?.value?.length === 2 ? 'other' : 'others'}}
                    )
            </span>
            </mat-select-trigger>
            <mat-option *ngFor="let tool of tools.tools" [value]="tool">{{tool}}</mat-option>
        </mat-select>
    </mat-form-field>
    <span class="warning" *ngIf="!isAtLeastOneTool">
            {{'Please choose at least one tool'}}
        </span>
</div>
