<div [ngClass]="(globals.isDesktop)?'desktop':'mobile'">
    <div class="project" (click)="goToNewChallengeGoogleForm()">
        <div class="project-image" [ngStyle]="{'background-size':'cover' }">
            <img src="/assets/images/plus.svg" class="plus"/>
        </div>
        <div class="project-details">
            <div class="project-description" fxLayoutAlign="center center">
                Submit New Challenge
            </div>
        </div>
    </div>
</div>
