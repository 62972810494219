<div class="specification">
    <!--<span class="dimension-text">Please add the dimensions of your prototype</span>-->
    <span class="B2-dark-text">Dimensions:</span>
<!--    <div class="buttons-select">
            <button class="regular-button" (click)="setDimensions('Centimeters')"
                    (mouseover)="mouseover1 = true" (mouseout)="mouseover1 = false"
                    [ngClass]="{'pressed-button': dimensionType === 'Centimeters', 'hover-edit-button': mouseover1}">{{ 'Centimeters' }}</button>
            <button class="regular-button" (click)="setDimensions('Meters')"
                    (mouseover)="mouseover2 = true"
                    (mouseout)="mouseover2 = false"
                    [ngClass]="{'pressed-button': dimensionType === 'Meters', 'hover-edit-button': mouseover2}">{{ 'Meters' }}</button>
            <button class="regular-button" (click)="setDimensions('Inches')"
                    (mouseover)="mouseover3 = true"
                    (mouseout)="mouseover3 = false"
                    [ngClass]="{'pressed-button': dimensionType === 'Inches', 'hover-edit-button': mouseover3}">{{ 'Inches' }}</button>
            <button class="regular-button" (click)="setDimensions('Feet')"
                    (mouseover)="mouseover4 = true"
                    (mouseout)="mouseover4 = false"
                    [ngClass]="{'pressed-button': dimensionType === 'Feet', 'hover-edit-button': mouseover4}">{{ 'Feet' }}</button>
    </div>-->
    <app-dynamic-form [formFields]="SpecificationsSectionDimensions"
                      (fieldChange)="inputChange($event)"
                      (formChange)="dynamicFormChange($event)"></app-dynamic-form>
    <!--<span class="dimension-text">What is the estimated time and cost to make your prototype?</span>-->
    <span class="B2-dark-text">Cost estimation:</span>
    <app-dynamic-form [formFields]="SpecificationsSectionCost"
                      (fieldChange)="inputChange($event)"
                      (formChange)="dynamicFormChange($event)"></app-dynamic-form>
    <span class="B2-dark-text">Time estimation:</span>
    <app-dynamic-form [formFields]="SpecificationsSectionTime"
                      (fieldChange)="inputChange($event)"
                      (formChange)="dynamicFormChange($event)"></app-dynamic-form>
</div>
