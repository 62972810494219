declare var AWS: any;
import {environment} from '../../../environments/environment';

export interface IFileUpload {
	UploadFileAsync(fileToUpload: File, cb: Function): any;

	UploadFileAsyncWithoutTimeStamp(fileToUpload: File, cb: Function): any;

	DownloadFileAsync(fileKey: string, cb: Function): any;

	DeleteFileAsync(fileKey: string, cb: Function): any;


}

export class S3FileUpload implements IFileUpload {
	private s3: any;
	region = environment.s3BucketRegion;
	identityID = environment.s3IdentityId;
	bucketName = environment.s3BucketName;
	apiVersion = environment.apiVersion;

	constructor() {
		AWS.config.region = this.region;
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: this.region + ':' + this.identityID,
		});

		this.s3 = new AWS.S3({
			apiVersion: this.apiVersion,
			params: {Bucket: this.bucketName}
		});
	}

	async UploadFileAsync(fileToUpload: File, cb: Function) {
		let fileName = fileToUpload.name;
		const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
		fileName = fileName.replace(fileType, '');
		const photoKey = fileName + Date.now() + '.' + fileType;
		await this.s3.upload({
				Key: photoKey,
				Body: fileToUpload,
				ACL: 'public-read'
			},
			(err: any, data: any) => {
				if (err) {
					const ret = {success: false, error: err};
					cb(ret);
				} else {
					const ret = {success: true, url: data.Location};
					cb(ret);
				}
			});
	}

	async UploadFileWithPromise(fileToUpload: File, progressBarCallBack?: Function ) {
		let fileName = fileToUpload?.name;
		const fileType = fileName?.substring(fileName.lastIndexOf('.') + 1, fileName.length);
		fileName = fileName.replace(fileType, '');
		const photoKey = fileName + Date.now() + '.' + fileType;
		return this.s3.upload({
				Key: photoKey,
				Body: fileToUpload,
				ACL: 'public-read'
			}
		).on('httpUploadProgress', (evt) => {
			if (progressBarCallBack) {
				const {loaded, total} = evt;
				progressBarCallBack(loaded, total);
			}
		}).promise();
	}


	async UploadFileAsyncWithoutTimeStamp(fileToUpload: File, cb: Function) {
		await this.s3.upload({
				Key: fileToUpload.name,
				Body: fileToUpload,
				ACL: 'public-read'
			},
			(err: any, data: any) => {
				if (err) {
					const ret = {success: false, error: err};
					cb(ret);
				} else {
					const ret = {success: true, url: data.Location};
					cb(ret);
				}
			});
	}

	async DownloadFileAsync(fileKey: string, cb: Function) {
		await this.s3.getObject({
				Key: decodeURIComponent(fileKey),
			},
			(error: any, data: any) => {
				if (error) {
					cb({ success: false, error });
				} else {
					cb({ success: true, data });
				}
			});
	}

	async DeleteFileAsync(fileKey: string, cb: Function) {
		await this.s3.deleteObject({
				Key: decodeURIComponent(fileKey),
			},
			(err: any, data: any) => {
				if (err) {
					const ret = {success: false, error: err};
					cb(ret);
				} else {
					const ret = {success: true, url: data.Location};
					cb(ret);
				}
			});
	}


}

export default S3FileUpload;
