import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CountryService extends HttpService {

	public getCountries() {
		return this.get('/country', {});
	}

	public getCountyByName(countryName: string) {
		return this.get(`/country/${countryName}`);
	}

	public getCommunityCountries() {
		return this.get('/country/community');
	}

	public getCategoryTypes() {
		return this.get('/categoriesType');
	}

	public getCountryBaseData(id: string) {
		const url = `/country/details?countryId=${id}`;
		return this.get(url);
	}


}

