import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GlobalsService} from '../../services/globals';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

	@ViewChild('svgAnimationItem_1', {read: ElementRef, static: true}) svgAnimationItem_1: ElementRef;
	public lottieConfig: any;

	constructor(public globals: GlobalsService) {
	}

	ngOnInit() {
		this.lottieConfig = {
			path: './assets/animations/Loader.json',
			renderer: 'canvas',
			autoplay: true,
			loop: true
		};
	}

}
