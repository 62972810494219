<span *ngIf="documentationCurrentValue?.type > 3" class="text">Based on your input so far, you may qualify for product eligibility on the TOM Global website! You indicated that your project is a prototype that only uses Basic Square Tools and Materials. This means that your prototype could be considered a TOM product. We have a few more questions to ask you to see if you qualify! TOM’s Research & Development Team will review your project file below to see if you are eligible.</span>
<mat-divider class="divider"></mat-divider>
<app-photos-videos [fileInput]="fileInput" (fieldChange)="fieldChange($event)"></app-photos-videos>
<mat-divider class="divider"></mat-divider>
<div *ngFor="let field of productEligibilitySection; let i = index"
     [ngStyle]="{'padding-bottom': i !== (productEligibilitySection?.length -1) ? '26px' : '0px'}">
    <app-documentation-select-form [isValueExists]="field?.value"
                                   [field]="field"

                                   [label]="field?.question"></app-documentation-select-form>
</div>
<mat-divider class="divider"></mat-divider>
<div class="footer">
    <div class="buttons">
        <button class="publish-btn" [disabled]="!isEligibleToProduct" (click)="applyForProduct()">
            <img src="./assets/images/medal.svg">
            {{'Apply for Product'}}
        </button>
    </div>
</div>

