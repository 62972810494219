import {Component, OnInit} from '@angular/core';
import {DocumentationSystemService} from '../../../services/documentation-system.service';


@Component({
	selector: 'app-project-files',
	templateUrl: './project-files.component.html',
	styleUrls: ['./project-files.component.scss']
})
export class ProjectFilesComponent implements OnInit {

	options = {
		isMultiple: true,
		formats: ['*'],
		required: true,
	};

	projectFiles;


	constructor(private documentationService: DocumentationSystemService) {

	}

	ngOnInit(): void {
		this.projectFiles = this.documentationService?.valueOfDocumentation?.projectFiles ?? [];

	}


	handleFilesToUpload(filesArray: string[]) {
		this.projectFiles = filesArray;
		this.documentationService.valueOfDocumentation = {
			...this.documentationService?.valueOfDocumentation,
			projectFiles: this.projectFiles
		};
	}


}
