<form [formGroup]="assemblyGuideForm" class="assembly">
    <label class="B2-dark-text">Please add a basic assembly guide for your project. Include everything you know so
        far.</label>

    <ng-container formArrayName="assemblySteps">
        <mat-accordion cdkDropList (cdkDropListDropped)="drop($event)">
            <mat-expansion-panel cdkDrag *ngFor="let stepGroup of steps; let i = index;" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="add-step">
                            <img alt="" class="icon" src="./assets/images/assembly-icon.png"
                                (click)="onIconClick($event, i)" />
                            <span class="title">
                                Step {{i + 1}}
                            </span>
                        </div>
                        <img alt="" src="./assets/images/delete-icon.svg" (click)="onIconClick($event, i)" />
                    </mat-panel-title>


                </mat-expansion-panel-header>
                <div [formGroup]="stepGroup">
                    <mat-form-field appearance="fill">
                        <mat-label>Assembly instruction</mat-label>
                        <textarea #message maxlength="25" matInput cdkTextareaAutosize
                            [formControl]="stepGroup.controls['description']"></textarea>
                        <mat-hint align="end">{{message.value.length}} / 25</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Tip</mat-label>
                        <input matInput [formControl]="stepGroup.controls['tip']">
                    </mat-form-field>

                    <span class="B2-dark-text">Select Basic Square Tools:</span>
                    <mat-form-field appearance="fill">
                        <mat-label>Tools</mat-label>
                        <mat-select [formControl]="stepGroup.controls['tools']" multiple>
                            <mat-option *ngFor="let tool of chosenTools" [value]="tool">
                                {{tool}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-upload-file-field [existingFiles]="getExistingFileFromStep(i)"
                        [options]="{ isMultiple: false, fieldName: 'step', formats:['image/*'], title: 'Add step image'}"
                        (FilesToUpload)="onFileUploaded($event, i)">
                    </app-upload-file-field>
                </div>


            </mat-expansion-panel>

        </mat-accordion>


    </ng-container>
    <button class="add-step-button" (click)="addNewStep()">{{'+ Add Step'}}</button>
</form>