import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import S3FileUpload, { IFileUpload } from 'src/app/helpers/file-upload/s3-file-upload';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageCropperComponent implements OnInit {

	FileUpload: IFileUpload = new S3FileUpload();

  files: any[];
  croppedImage: any;
	imageChangedEvent: any = null;

  canvasRotation = 0;
	rotation = 0;
	scale = 1;
	containWithinAspectRatio = false;
  previewWidth: number = 0;
	previewHeight: number = 0;

	transform: ImageTransform = {};

  constructor(
    private dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.previewHeight = this.data.height;
		this.previewWidth = this.data.width;
    this.imageChangedEvent = { target: { files: [this.data.file] } };
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
		const originalFile = this.data.file;
    const fileAfterChange = new File([base64ToFile(this.croppedImage)], originalFile.name, {
			type: originalFile.type
		});
    this.dialogRef.close(fileAfterChange);
  }

  async loadImage(): Promise<void> {
		this.croppedImage = this.imageChangedEvent.base64;
  }

  handleImageCropped(event: ImageCroppedEvent) {
    const reader = new FileReader();

    reader.onload = () => {
      this.croppedImage = reader.result;
    };

    reader.readAsDataURL(event.blob);
	}

  rotateLeft() {
		this.canvasRotation--;
	}

	rotateRight() {
		this.canvasRotation++;
	}

	flipHorizontal() {
		this.transform = {
			...this.transform,
			flipH: !this.transform.flipH,
		};
	}

	flipVertical() {
		this.transform = {
			...this.transform,
			flipV: !this.transform.flipV,
		};
	}

	zoomOut() {
		this.scale -= 0.1;
		this.transform = {
			...this.transform,
			scale: this.scale,
		};
	}

	zoomIn() {
		this.scale += 0.1;
		this.transform = {
			...this.transform,
			scale: this.scale,
		};
	}

	resetImage() {
		this.scale = 1;
		this.rotation = 0;
		this.canvasRotation = 0;
		this.transform = {};
	}
}
