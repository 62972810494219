<div class="concept">

    <app-dynamic-form class="dynamic-form-project" *ngIf="projectDescriptionSection"
        [formFields]="projectDescriptionSection" (formChange)="dynamicFormChange($event)"
        (fieldChange)="fieldChange($event)"></app-dynamic-form>
    <span class="B2-dark-text content">Tag your project category</span>
    <mat-form-field appearance="fill">
        <mat-label>Categories</mat-label>
        <mat-select [formControl]="projectCategoryFormControl" multiple disableOptionCentering required>
            <mat-option *ngFor="let option of systemCategories" [value]="option?._id" class="form-text">
                {{option?.name}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="!projectCategoryFormControl?.valid && projectCategoryFormControl?.touched">{{'Please choose at
            least one category'}}</mat-error>
    </mat-form-field>

    <app-photos-videos #photosVideosComponent [fileInput]="fileInput"
        (fieldChange)="fieldChange($event)"></app-photos-videos>


    <div class="question-box" *ngIf="project?.type > 1 && !notSurePressed">
        <span class="text">
            Congratulations on completing the Concept stage!
            You can now save & publish your project.
            <br>

            If you are ready, upgrade your Concept to a Working Model
            <img src="./assets/images/info-icon/info-icon-white.png" class="info-icon"
                tippy='A Working Model is a model that proves the basic assumptions or features of the proposed solution. It can be made of any material but must include sketches, photos or CAD files that can help replicate that model. It should also include feedback from a Need Knower after at least one user test.'
                variation="documentation" />
            or a Prototype
            <img src="./assets/images/info-icon/info-icon-white.png" class="info-icon"
                tippy='A Prototype is a solution used regularly by at least one Need Knower. This stage must include additional considerations such as functionality, durability, comfort, ergonomics, ease of use, aesthetics, and other usability scenarios.'
                variation="documentation" />
            .
        </span>
        <div class="buttons">
            <button (click)="moveToDifferentStep(2)" class="btn">Working model</button>
            <button (click)="moveToDifferentStep(3)" class="btn">Prototype</button>
            <button (click)="toggleNotSureState()" class="btn">Not sure</button>
        </div>
    </div>

    <div class="question-box" *ngIf="notSurePressed">
        <span class="text">
            Have you finalized the tools and materials needed for your solution?
        </span>
        <div class="buttons row">
            <button class="btn" [class.pressed]="finalTools === 'true'" (click)="handleNextStepLogic('true')">
                Yes!
            </button>
            <button class="btn" [class.pressed]="finalTools === 'false'" (click)="handleNextStepLogic('false')">Not Yet!
            </button>
        </div>
    </div>

</div>