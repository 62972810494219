import {Injectable} from '@angular/core';
import {HttpService} from './http.service';

@Injectable({
	providedIn: 'root',
})
export class CityService extends HttpService {
	public getCities() {
		return this.get('/city', {});
	}

	public getCitiesByCountry(id) {
		return this.get(`/city/${id}`, {});
	}

	public getCommunityCities() {
		return this.get('/city/community');
	}
}
