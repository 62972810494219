<div class="menu-container">
    <div class="menu">
        <div class="menu-header">
            <img
                    src="./assets/images/tom-logo-color@mobile.svg"
                    (click)="navigateTo('/home')"
            />
        </div>
        <div class="menu-body">
            <div
                    class="menu-item"
                    *ngFor="let item of menuItems"
                    [ngClass]="item.isOpen ? 'item-open' : ''"
            >
                <div class="menu-item-title">
                    <div class="w-90" (click)="navigateTo(item.link)">
                        {{ item.title }}
                    </div>
                    <ng-container *ngIf="item.items">
                        <div
                                class="w-10"
                                *ngIf="!item.isOpen"
                                (click)="toggleItem(item)"
                        >
                            +
                        </div>
                        <div
                                class="w-10"
                                *ngIf="item.isOpen"
                                (click)="toggleItem(item)"
                        >
                            -
                        </div>
                    </ng-container>
                </div>
                <div class="extended-container">
                    <div
                            class="extended-link"
                            *ngFor="let exLink of item.items"
                    >
						<span
                                (click)="navigateTo(exLink.link, exLink.fragment)"
                        >{{ exLink.title }}</span
                        >
                    </div>
                </div>
            </div>
            <button
                    class="donate-button text-center"
                    (click)="navigateTo('/supportTOM')"
            >
                <img src="./assets/images/heart-white.svg"/>
                <span class="bold mt-3">Donate</span>
            </button>
        </div>
        <div class="menu-footer">
            <div class="follow-us bold">Follow Us</div>
            <div class="icons">
                <a
                        target="_blank"
                        href="https://www.facebook.com/TikkunOlamMakers/"
                ><img
                        class="small-icon"
                        src="./assets/images/facebook-gray.svg"
                /></a>
                <a target="_blank" href="https://twitter.com/TOMakeathon"
                ><img
                        class="small-icon"
                        src="./assets/images/twitter-gray.svg"
                /></a>
                <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCSvUYvIx6HZuvcD3SvI2s8Q/videos"
                ><img
                        class="small-icon"
                        src="./assets/images/youtube-gray.svg"
                /></a>
                <a
                        target="_blank"
                        href="https://www.instagram.com/tikkunolammakers/"
                ><img
                        class="small-icon"
                        src="./assets/images/instagram-gray.svg"
                /></a>
            </div>
        </div>
    </div>
    <div class="close text-center">
		<span (click)="close()"
        ><img src="../../../assets/images/close.svg"
        /></span>
    </div>
</div>
