<div class="photos-videos-container">
    <div class="upload-wrapper">
        <span class="B2-dark-text">Please add any production files that you have of your project.</span>
        <app-upload-file-field
                (FilesToUpload)="handleFilesToUpload($event)"
                [existingFiles]="projectFiles"
                [options]="options">
        </app-upload-file-field>
        <br>
    </div>
</div>
