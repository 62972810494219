<div [ngClass]="globals.isMobile ? 'mobile' : 'desktop'">
    <div class="share">
        <div class="share-title bold">Share</div>
        <br>
        <div class="row">
            <!-- <share-buttons [theme]="'default'" [include]="['facebook','twitter','email']" [show]="3" [size]="-4"></share-buttons> -->
            <share-buttons
                    [theme]="'material-dark'"
                    [include]="['facebook', 'twitter', 'email']"
                    [exclude]="['google']"
                    [show]="3"
                    [url]=""
                    [autoSetMeta]="false"
            ></share-buttons>
        </div>
        <!-- <div class="row">
            <div class="icon-container">
                <img class="pointer" src="./assets/images/share-facebook.svg">
            </div>

            <div class="icon-container">
                <img class="pointer" src="./assets/images/share-twitter.svg">
            </div>


            <div class="icon-container">
                <img class="pointer" src="./assets/images/share-pinterest.svg">
            </div>


            <div class="icon-container">
                <img class="pointer" src="./assets/images/share-mail.svg">
            </div>
        </div> -->
    </div>
</div>
