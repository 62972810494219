import {Component, OnInit} from '@angular/core';
import {GlobalsService} from '../../services/globals';
import {AnalyticsService} from '../../services/analytics.service';

@Component({
	selector: 'app-new-challenge',
	templateUrl: './new-challenge.component.html',
	styleUrls: ['./new-challenge.component.scss']
})
export class NewChallengeComponent implements OnInit {

	constructor(
		public globals: GlobalsService,
		public analyticsService: AnalyticsService
	) {
	}

	ngOnInit() {
	}

	goToNewChallengeGoogleForm() {
		this.analyticsService.sendEvent('Challenge submission form /landing page', 'submitNewChallenge');
		window.open('https://docs.google.com/forms/d/e/1FAIpQLSeJO72bAB1-LwG1sHNcBrFOIZdwMN8xNr5RwjnKVYnn-B4ttw/viewform?gxids=7757', '_blank');
	}

}
