import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CanActivateNotLoggedUser} from './shared/CanActivateNotLoggedUser';
import {CanActivateLoggedUser} from './shared/CanActivateLoggedUser';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {CountryPageComponent} from './pages/country-page/country-page.component';

export const routes: Routes = [
	// {
	// 	path: '',
	// 	loadChildren: () => import('./modules/home.module').then(m => m.HomeModule)
	// },
	{path: '', component: HomePageComponent},
	{path: 'home', component: HomePageComponent},
	{
		path: 'about',
		loadChildren: () => import('./modules/about.module').then(m => m.AboutModule)
	},
	{
		path: 'documentation',
		loadChildren: () => import('./modules/documentation.module').then(m => m.DocumentationModule),
		canActivate: [CanActivateLoggedUser],
		data: {disableDefaultHeader: true}
	},
	{
		path: 'communities',
		loadChildren: () => import('./modules/communities.module').then(m => m.CommunitiesModule)
	},
	{
		path: 'events',
		loadChildren: () => import('./modules/events.module').then(m => m.EventsModule)
	},
	{
		path: 'search',
		loadChildren: () => import('./modules/search.module').then(m => m.SearchModule)
	},
	{
		path: 'tom',
		loadChildren: () => import('./modules/type.module').then(m => m.TypeModule)
	},
	{
		path: 'country',
		loadChildren: () => import('./modules/country.module').then(m => m.CountryModule)
	},
	{
		path: 'results',
		loadChildren: () => import('./modules/results.module').then(m => m.ResultsModule)
	},
	{
		path: 'press',
		loadChildren: () => import('./modules/press.module').then(m => m.PressModule)
	},
	{
		path: 'event',
		loadChildren: () => import('./modules/event.module').then(m => m.EventModule)
	},
	{
		path: 'contact',
		loadChildren: () => import('./modules/contact.module').then(m => m.ContactModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./modules/login.module').then(m => m.LoginModule),
		canActivate: [CanActivateNotLoggedUser]
	},
	{
		path: 'profile',
		loadChildren: () => import('./modules/profile.module').then(m => m.ProfileModule),
		canActivate: [CanActivateLoggedUser]
	},
	{
		path: 'public/profile',
		loadChildren: () => import('./modules/public-profile.module').then(m => m.PublicProfileModule)
	},
	{
		path: 'supportTOM',
		loadChildren: () => import('./modules/support-tom.module').then(m => m.SupportTomModule)
	},
	{
		path: 'community',
		loadChildren: () => import('./modules/community.module').then(m => m.CommunityModule)
	},
	{
		path: 'project',
		loadChildren: () => import('./modules/project.module').then(m => m.ProjectModule)
	},
	{
		path: 'sponsor',
		loadChildren: () => import('./modules/sponsor.module').then(m => m.SponsorModule)
	},
	{
		path: 'terms',
		loadChildren: () => import('./modules/terms.module').then(m => m.TermsModule)
	},
	{
		path: 'privacy',
		loadChildren: () => import('./modules/privacy.module').then(m => m.PrivacyModule)
	},
	{
		path: 'participant-agreement',
		loadChildren: () => import('./modules/participant-agreement.module').then(m => m.ParticipantAgreementModule)
	},
	{path: ':countryName', component: CountryPageComponent},
	{path: '**', redirectTo: '/', pathMatch: 'full'},

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			relativeLinkResolution: 'legacy'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}

