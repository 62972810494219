import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {GlobalsService} from '../../services/globals';

@Component({
	selector: 'app-maker-pop-mail',
	templateUrl: './maker-popup-mail.component.html',
	styleUrls: ['./maker-popup-mail.component.scss']
})
export class MakerPopupMailComponent implements OnInit {

	mailPopUpContentFormControl!: FormControl;
	mailPopUpForm!: FormGroup;
	content: string;

	// ratio
	public previewWidth: number = 0;
	public previewHeight: number = 0;

	constructor(
		public dialogRef: MatDialogRef<MakerPopupMailComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
		private formBuilder: FormBuilder,
		public globals: GlobalsService,
	) {
		this.previewHeight = data.height;
		this.previewWidth = data.width;

	}


	ngOnInit() {
		this.content =
			`Hi ${this.data.params.makerName},\n` +
			`\n` +
			`I found you via tomglobal.org and would like to receive a price quote for the following:\n` +
			`\n` +
			`${this.data.params.productName} -\n` +
			`\n` +
			`${this.data.params.linkToProject}\n` +
			`\n` +
			`If you have any questions or need to speak with me directly about quantities and customization options before giving me a quote, please contact me.\n` +
			`\n` +
			`Thank you.`;
		this.initMailPopUpForm();
	}

	close(): void {
		this.dialogRef.close();
	}

	// // init mailPopUp form
	initMailPopUpForm() {
		// init mailPopUp form controls
		this.mailPopUpContentFormControl = this.formBuilder.control(this.content, []);
		// init mailPopUp form
		this.mailPopUpForm = this.formBuilder?.group({
			mailPopUp: this.mailPopUpContentFormControl
		});
	}

	// submitMailPopUp() {
	// 	if (this.mailPopUpForm?.valid) {
	//
	// 	}
	// }
}
