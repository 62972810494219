<div class="container">
    <div class="row">
        <div *ngIf="globals.isDesktop" class="close-btn" (click)="closePopup()">
            x
        </div>
        <div
                *ngIf="globals.isMobile"
                class="close-btn row"
                (click)="closePopup()"
        >
            <img src="../../../assets/images/back_arrow.png"/> Back
        </div>
    </div>
    <div class="title text-black">{{ project.projectName }}</div>
    <hr/>
    <div *ngIf="!isUser" class="terms-box">
        <div class="checkbox-area">
            <input
                    class="checkbox"
                    type="checkbox"
                    (click)="toggleTermCheck()"
            />
            <div class="terms-text text-body">
                By checking this box you represent, warrant, and agree that You
                have read, understood and accepted all of the terms of
                <a (click)="openNewTab('/participant-agreement')"
                >TOM's Participant and User Agreement</a
                >, <a (click)="openNewTab('/terms')">Terms of Service</a> and
                <a (click)="openNewTab('/privacy')">Privacy Policy</a>.
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="text-black">CHOOSE A MAKERSPACE</div>
        <div class="filter-btn text-blue" (click)="toggleFilter()">
            {{ this.filterOpen ? "Close" : "Filter" }}
        </div>
    </div>
    <div class="filter-row mt-4" *ngIf="filterOpen">
        <select
                class="btn btn-text-blue"
                [formControl]="makersForm.controls['country']"
                (change)="handleCountryChange()"
        >
            <option value="">Country</option>
            <option *ngFor="let country of countriesList" [value]="country._id">
                {{
                country.name === "usa"
                    ? (country.name | uppercase)
                    : (country.name | titlecase)
                }}
            </option>
        </select>
        <select
                class="btn btn-text-blue"
                [formControl]="makersForm.controls['city']"
                (change)="handleCityChange()"
        >
            <option value="">City</option>
            <option *ngFor="let city of citiesList" [value]="city._id">
                {{ city.name | titlecase }}
            </option>
        </select>
    </div>
    <div class="makers-container">
        <div class="no-makers" *ngIf="!makersList || makersList.length == 0">
            No Makerspaces found...
        </div>
        <div *ngFor="let maker of makersList">
            <app-maker-tab
                    [maker]="maker"
                    [project]="project"
                    [termsChecked]="termsChecked"
            ></app-maker-tab>
            <hr/>
        </div>
    </div>
</div>
