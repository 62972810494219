<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'">
    <div class="item" (click)="itemClick()">
        <div class="item-image">
            <img [defaultImage]="defaultImage"
                 [lazyLoad]="item?.image"
                 class="item-image__img">
            <!-- <div
                class="item-type bold"
                [ngStyle]="{ background: backColor }"
            >
                <img class="type-icon" [src]="assetPath" />
                <span class="type-name">{{ typeString }}</span>
            </div> -->
            <img *ngIf="showType" class="type-icon" alt="" [src]="assetPath"/>
        </div>
        <div class="item-details">
            <div class="item-title bold">
                {{ (item?.name.split(':').length !== 2) ? (item.name | titlecase) : (item?.name) }}
            </div>
            <div class="item-description">
                {{ details | titlecase }}
            </div>
        </div>
    </div>
</div>
