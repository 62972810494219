<div [ngClass]="globals.isDesktop ? 'desktop' : 'mobile'">
    <div class="popup">
        <div class="close-x pointer">
            <img src="../../../assets/images/close-x.svg" (click)="close()"/>
        </div>
        <div class="popup-title">
            {{ popData.mainText }}
        </div>
        <div class="popup-confirm">
            <button
                    (click)="openLink(popData.btnFirstLink)"
                    class="btn-confirm"
            >
                {{ popData.btnFirstText }}
            </button>
            <button
                    (click)="openLink(popData.btnSecondLink)"
                    class="btn-confirm green"
            >
                {{ popData.btnSecondText }}
            </button>
        </div>
        <div class="popup-text">
            <span>{{popData.bottomText}}</span>
            <span (click)="openLink(popData.bottomTextLink)" *ngIf="popData.bottomTextLink">, click
					<span style="margin-right:5px;color: blue; text-decoration: underline;cursor: pointer;">here</span>
				</span>
            <span *ngIf="popData.bottomTextSecond">{{popData.bottomTextSecond}} </span>
        </div>
    </div>
</div>
