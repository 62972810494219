import {Injectable} from '@angular/core';
import {HttpService} from './http.service';

@Injectable({
	providedIn: 'root'
})

export class HomepagePopService extends HttpService {


	public getHomepagePopData() {
		const url = `/homepagepop`;
		return this.get(url);
	}

}
