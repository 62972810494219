import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GlobalsService} from 'src/app/services/globals';

@Component({
	selector: 'app-homepage-popup',
	templateUrl: './homepage-popup.component.html',
	styleUrls: ['./homepage-popup.component.scss']
})

export class HomepagePopupComponent implements OnInit {

	constructor(
		public globals: GlobalsService,
		public dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	close(): void {
		this.dialogRef.close();
	}

	popData: any;

	ngOnInit() {
		this.popData = this.data;
	}

	openLink(link) {
		window.open(link, '_blank');
	}


}
