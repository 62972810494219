<!-- Mobile View -->
<div *ngIf="globals.isMobile" class="mobile">
    <div
            class="header-container"
            [ngClass]="assets.white ? 'transparent' : 'bottom-border'"
    >
        <img [src]="assets.menu" (click)="toggleMenu()"/>
        <img
                class="m-logo"
                [src]="assets.logo"
                (click)="analyticsService.sendEvent('Logo Click', 'Logo')"
                routerLink="/home"
        />
        <div
                class="join-header"
                *ngIf="!this.dataService.isUserLoggedIn"
                routerLink="/login"
                [ngClass]="{ black: !assets.white }"
        >
            Join Us
        </div>
        <img
                [src]="assets.person"
                *ngIf="this.dataService.isUserLoggedIn && !image"
                (click)="
				moveToProfile();
				analyticsService.sendEvent('Profile Icon', 'Profile Icon')
			"
        />
        <img
                [src]="image"
                class="user-img"
                *ngIf="this.dataService.isUserLoggedIn && image"
                (click)="moveToProfile()"
        />
    </div>
    <app-menu [@slideInOut]="menuState" (closeMenu)="toggleMenu()"></app-menu>
</div>
<!-- END Mobile View -->

<!-- Desktop View -->
<div *ngIf="globals.isDesktop" class="desktop">
    <div
            class="header-container"
            [ngClass]="assets.white ? 'transparent' : 'color'"
    >
        <div class="limit">
            <div class="tom-logo-container">
                <img
                        routerLink="/home"
                        (click)="analyticsService.sendEvent('Logo Click', 'Logo')"
                        [src]="
						assets.white
							? '/assets/images/tom-logo.svg'
							: '/assets/images/tom-logo-color.svg'
					"
                        class="pointer"
                        alt="tom logo"
                />
            </div>
            <ul>
                <li
                        [ngClass]="{ active: active == '/search' }"
                        routerLink="/search"
                >
                    Projects
                </li>
                <li
                        [ngClass]="{ active: active == '/communities' }"
                        routerLink="/communities"
                >
                    Communities
                </li>
                <li
                        [ngClass]="{ active: active == '/events' }"
                        routerLink="/events"
                >
                    Events
                </li>
                <li
                        [ngClass]="{ active: active == '/about' }"
                        routerLink="/about"
                >
                    About
                </li>
                <li
                        [ngClass]="{ active: active == '/press' }"
                        routerLink="/press"
                >
                    Press
                </li>
                <li
                        [ngClass]="{ active: active == '/login' }"
                        *ngIf="!this.dataService.isUserLoggedIn"
                        [ngClass]="{ green: assets.white }"
                        routerLink="/login"
                >
                    Join Us
                </li>
                <!-- <div class="folding-menu">
                    <div *ngFor="let item of aboutItems" (click)="navigateTo(item.link, item.fragment)">
                        {{item.title}}
                    </div>
                </div> -->

                <!-- <div class="folding-menu">
                            <div *ngFor="let item of projectsItems" (click)="navigateTo(item.link, item.fragment)">
                                {{item.title}}
                            </div>
                        </div> -->
                <!-- </li> -->
                <!-- <li [ngClass]="{'active':active=='/contact'}" routerLink="/contact">Contact</li> -->
                <li
                        [ngClass]="{ active: active == '/profile' }"
                        *ngIf="this.dataService.isUserLoggedIn"
                        class="green"
                        (click)="moveToProfile()"
                >
                    <img
                            *ngIf="!image"
                            [src]="
							!assets.white
								? './assets/images/person-color.svg'
								: './assets/images/person.svg'
						"
                    />
                    <img class="user-img" *ngIf="image" [src]="image"/>
                </li>
                <li>
                    <button
                            class="donate-button"
                            (click)="
							analyticsService.sendEvent(
								'Header Donate button',
								'Donate'
							)
						"
                            routerLink="/supportTOM"
                    >
                        <div
                                class="row-center"
                                [ngClass]="{ 'row-center-ie': isPC }"
                        >
                            <div class="mr-5" style="display: flex">
                                <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="15"
                                        viewBox="0 0 17 15"
                                >
                                    <path
                                            fill="#FFF"
                                            fill-rule="nonzero"
                                            d="M17 4.761a4.72 4.72 0 0 0-1.507-3.452A4.86 4.86 0 0 0 11.915.014a4.847 4.847 0 0 0-3.412 1.674A4.845 4.845 0 0 0 5 .003a4.857 4.857 0 0 0-3.622 1.425 4.72 4.72 0 0 0-1.369 3.6 4.733 4.733 0 0 0 1.778 3.424L8.436 15l6.867-6.618A4.718 4.718 0 0 0 17 4.762z"
                                    />
                                </svg>
                            </div>
                            <div
                                    style="
									height: 17px;
									font-size: 17px;
									font-weight: 600;
									line-height: 22px;
									letter-spacing: 0.04em;
								"
                            >
                                Donate
                            </div>
                        </div>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- END Desktop View -->
