<div [ngClass]="(globals.isDesktop)?'desktop':'mobile'">
    <div class="team-members">

        <div class="row">
            <div class="team-photo round"
                 [ngStyle]="{'background-image': 'url(' + communityWithUsers?.socialMediaInformation?.logoUrl + ')'}">
				<span
                        class="flag round flag-icon + flag-icon-{{communityWithUsers?.country?.alpha2Code?.toLowerCase()}} flag-icon-squared"></span>
            </div>
            <div style="max-width: 80%;">
                <div class="team-name bold text-blue">{{communityWithUsers?.name}}</div>
                <div class="row mt" *ngIf="globals.isDesktop">
                    <img class="member-photo round" [src]="member?.imageUrl"
                         [matTooltip]="member?.basicUserInformation?.firstName + ' ' + member?.basicUserInformation?.lastName"
                         *ngFor="let member of communityWithUsers?.members" (click)="navigateToUserProfile(member?._id)">
                </div>
            </div>
        </div>
        <div class="row mt" *ngIf="globals.isMobile">
            <img class="member-photo round" [src]="member?.imageUrl" *ngFor="let member of communityWithUsers?.members"
                 (click)="navigateToUserProfile(member?._id)">
        </div>
    </div>
</div>
